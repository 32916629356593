import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {combineLatest, concat, Observable, of, Subject} from 'rxjs';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastService} from '../../services/toast.service';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  switchMap,
  takeUntil,
  tap
} from 'rxjs/operators';
import {TakeUntilDestroy} from '../../services/take-until-destroy.decorator';
import {
  FeatureAdminService, FeaturePagination,
  Meet, MeetAdminService,
  Plan, PlanAdminService,
  PlanClaim,
} from '../../swagger-codegen';
import {FormGroupConfig} from '../../services/functions';
import * as _ from 'lodash';

@TakeUntilDestroy
@Component({
  selector: 'app-plan-edit',
  templateUrl: './plan-edit.component.html',
  styleUrls: ['./plan-edit.component.scss']
})
export class PlanEditComponent implements OnInit {
  @BlockUI()
  blockUI: NgBlockUI;
  componentDestroy: () => Observable<boolean>;
  @Input()
  editPlan: Plan;
  @Output()
  editFinished: EventEmitter<boolean> = new EventEmitter<boolean>();
  planForm: FormGroup;
  meets$: Observable<Meet[]>;
  meetsLoading = false;
  meetsInput$ = new Subject<string>();
  planTypes: string[];
  planClaimTypes: string[];
  features: string[];

  constructor(private planService: PlanAdminService,
              private featureService: FeatureAdminService,
              private meetService: MeetAdminService,
              private toast: ToastService,
              private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.planForm = (this.editPlan?.id)
      ? new FormGroup({
        id: new FormControl(this.editPlan?.id),
        code: new FormControl(this.editPlan?.code, [Validators.required]),
        name: new FormControl(this.editPlan?.name, [Validators.required]),
        type: new FormControl(this.editPlan?.type, [Validators.required]),
        description: new FormControl(this.editPlan?.description, [Validators.required]),
        amount: new FormControl(this.editPlan?.amount),
        isDeleted: new FormControl(this.editPlan?.isDeleted),
        daysBeforeRenewalOrExpiration: new FormControl(this.editPlan?.daysBeforeRenewalOrExpiration),
        shouldAutoRenew: new FormControl(this.editPlan?.shouldAutoRenew),
        canSell: new FormControl(this.editPlan?.canSell),
        meetId: new FormControl(this.editPlan?.meetId),
        claims: new FormArray([])
      })
      : new FormGroup({
        id: new FormControl(''),
        code: new FormControl('', Validators.required),
        name: new FormControl('', Validators.required),
        type: new FormControl('', Validators.required),
        description: new FormControl('', Validators.required),
        amount: new FormControl('', [Validators.required, Validators.min(0), Validators.pattern(/^(\d+\.?\d{0,9}|\.\d{1,9})$/)]),
        daysBeforeRenewalOrExpiration: new FormControl(''),
        isDeleted: new FormControl(false),
        shouldAutoRenew: new FormControl(true),
        canSell: new FormControl(true),
        meetId: new FormControl(''),
        claims: new FormArray([])
      });
    if (this.editPlan?.id) {
      const claims = this.planForm.controls.claims as FormArray;
      this.editPlan?.claims.map(pc => {
        claims.push(this.addPlanClaimGroup(pc));
      });
    }
    this.loadMeets();

    combineLatest([
      this.planService.apiAdminPlanTypesGet(),
      this.planService.apiAdminPlanClaimTypesGet(),
      this.featureService.apiAdminFeatureGet()])
      .pipe(
      finalize(() => {
        this.blockUI.stop();
      }),
      takeUntil(this.componentDestroy()))
      .subscribe(([planTypes, planClaimTypes, features]) => {
        this.planTypes = planTypes;
        this.planClaimTypes = planClaimTypes;
        this.features = features.result.map(f => f.normalizedName);
      });
  }

  get planClaims(): FormArray {
    return this.planForm.get('claims') as FormArray;
  }

  getClaimType(i: number): string {
    const claims = this.planForm.get('claims') as FormArray;
    return claims.controls[i].get('claimType').value;
  }

  private addPlanClaimGroup(item?: PlanClaim): FormGroup {
    const config: FormGroupConfig<PlanClaim> = (!item)
      ? {
          id: [0],
          claimType: ['', Validators.required],
          claimValue: ['', Validators.required],
          displayName: [''],
          price: [0, [Validators.min(0), Validators.pattern(/^(\d+\.?\d{0,9}|\.\d{1,9})$/)]],
          claimInt: [0],
          claimDecimal: [0],
          claimBool: [null]
        }
      : {
          id: [item?.id],
          claimType: [item?.claimType],
          claimValue: [item?.claimValue],
          displayName: [item?.displayName],
          price: [item?.price],
          claimInt: [item?.claimInt],
          claimDecimal: [item?.claimDecimal],
          claimBool: [item?.claimBool]
        };
    return this.fb.group(config);
  }

  addPlanClaim(): void {
    this.planClaims.push(this.addPlanClaimGroup());
  }

  removePlanClaim(index: number): void {
    this.planClaims.removeAt(index);
  }

    cancel(): void {
      this.editFinished.emit(true);
    }

    save(): void {
      if (this.planForm.valid) {
      this.blockUI.start();
      // @ts-ignore
      const newPlan: Feature = {
        id: this.planForm.value.id,
        code: this.planForm.value.code,
        name: this.planForm.value.name,
        description: this.planForm.value.description,
        amount: this.planForm.value.amount,
        price: this.planForm.value.price,
        type: this.planForm.value.type,
        daysBeforeRenewalOrExpiration: this.planForm.value.daysBeforeRenewalOrExpiration,
        shouldAutoRenew: this.planForm.value.shouldAutoRenew,
        canSell: this.planForm.value.canSell,
        meetId: this.planForm.value.meetId,
        isDeleted: this.planForm.value.isDeleted,
        claims: this.planForm.value.claims
      };
      const apiCall = (newPlan.id === 0)
        ? this.planService.apiAdminPlanPost(newPlan)
        : this.planService.apiAdminPlanPut(newPlan);
      apiCall.pipe(
        finalize(() => {
          this.blockUI.stop();
          this.editFinished.emit(true);
        }),
        takeUntil(this.componentDestroy()))
        .subscribe(data => {
          this.blockUI.stop();
        });
    }
  else
    {
      this.toast.error('Please, set all fields');
    }
  }

  setMeet(v: Meet): void {
    this.planForm.controls.meetId.patchValue(v?.id);
  }

  meetByFn(item: Meet): any {
    return item.id;
  }

  private loadMeets(): void {
    // @ts-ignore
    this.meets$ = concat(
      of(_.chain(this.editPlan?.meet).filter(x => x !== undefined).value()), // default items
      this.meetsInput$.pipe(
        map(x => _.trim(x)),
        distinctUntilChanged(),
        filter(x => x && x.length > 2),
        debounceTime(1000),
        tap(() => this.meetsLoading = true),
        switchMap(term => {
            return this.meetService.apiAdminMeetSearchGet(term)
              .pipe(
              catchError(() => of([])), // empty list on error
              tap(() => this.meetsLoading = false)
            );
          }
        ),
        takeUntil(this.componentDestroy())
      ));
  }
}
