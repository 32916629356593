<ng-container *ngIf="this.events === null">Loading...</ng-container>
<ng-container *ngIf="this.events !== null">
  <h1 class="text-center"> Tote Events</h1>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-8">
        <h3>Events</h3>
        <div *ngFor="let event of events" class="row" style="margin-top: 5px;">
          <div class="col-lg-6 text-right">
            <label>{{event.eventCode}} - {{event.eventName}} - {{event.totelinkTrackName}}</label>
          </div>
          <div class="col-lg-6">
            <ng-select [items]="tracks"
                       [selectOnTab]="true"
                       bindLabel="trackName"
                       bindValue="trackId"
                       (change)="updateMappedEvents()"
                       [(ngModel)]="event.equinEdgeTrackId"
                       placeholder="Select track"
                       class="form-control p-0">
            </ng-select>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <h3>Tracks</h3>
        <ng-container *ngFor="let track of tracks">
          <div *ngIf="track.trackName.indexOf('*** NOT MAPPED TOTE TRACK ***') < 0" class="track-choice">
            {{track.trackName}}<br>
            <small>
              Mapped events:
              <span class="mapped-event" *ngFor="let map of track.mappedEvents">
            <b class="dot">-</b>
                {{map}}
            </span>
              <span *ngIf="track.mappedEvents.length === 0" class="not-mapped">
              NONE
            </span>
            </small>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="row" style="position: fixed; bottom: 0; right: 0;">
      <div class="col-sm-12">
        <button class="btn btn-block btn-primary" (click)="save()">
          Save
        </button>
      </div>
    </div>
  </div>
</ng-container>
