<ng-container *ngIf="!selected">
  <table class="table table-hover" sortable-table (sorted)="onSorted($event)">
    <thead>
    <tr>
      <th colspan="4">
        <button class="btn btn-success" (click)="editItem({})">Add New</button>
      </th>
      <th colspan="99">
        <h2>PromoCodes table</h2>
      </th>
    </tr>
    <tr>
      <th scope="col" sortable-column="Id" class="pointer">Id</th>
      <th scope="col" sortable-column="Name" class="pointer">
        name
      </th>
      <th scope="col" sortable-column="IsDeleted" class="pointer">
        isDeleted
      </th>
      <th scope="col" sortable-column="Code" class="pointer">
        code
      </th>
      <th scope="col" sortable-column="Description" class="pointer">
        description
      </th>
      <th scope="col" sortable-column="MaxRedemptions" class="pointer">
        maxRedemptions
      </th>
      <th scope="col" sortable-column="MaxRedemptionsPerUser" class="pointer">
        maxRedemptionsPerUser
      </th>
      <th scope="col" sortable-column="SetPriceTo" class="pointer">
        setPriceTo
      </th>
      <th scope="col">
        promoCodeClaims count
      </th>
      <th scope="col">
        redemptions count
      </th>
      <th scope="col" sortable-column="StartDate" class="pointer">
        startDate
      </th>
      <th scope="col" sortable-column="EndDate" class="pointer">
        endDate
      </th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let item of currentPromoCodes?.result">
      <tr (click)="editItem(item)" [ngClass]="{'bg-danger':item.isDeleted}">
        <td class="width-60">{{item.id}}</td>
        <td>{{item.name}}</td>
        <td>{{item.isDeleted}}</td>
        <td>{{item.code}}</td>
        <td>{{item.description}}</td>
        <td>{{item.maxRedemptions}}</td>
        <td>{{item.maxRedemptionsPerUser}}</td>
        <td>{{item.setPriceTo}}</td>
        <td>{{item.promoCodeClaims?.length}}</td>
        <td>{{item.redemptions?.length}}</td>
        <td>{{item.startDate | date:'shortDate'}}</td>
        <td>{{item.endDate | date:'shortDate'}}</td>
      </tr>
    </ng-container>
    </tbody>
  </table>
  <ng-container *ngIf="currentPromoCodes">
    <ngb-pagination [pageSize]="pageSize" [collectionSize]="currentPromoCodes.totalItems"
                    class="d-flex justify-content-center"
                    (pageChange)="pageChanged($event)" [rotate]="true" [(page)]="currentPage" [maxSize]="30"
                    [boundaryLinks]="true">
    </ngb-pagination>
  </ng-container>
</ng-container>
<ng-container *ngIf="selected">
  <app-promo-code-edit [editPromoCode]="selected" (editFinished)="editItem(null)"></app-promo-code-edit>
</ng-container>
