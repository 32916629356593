import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TakeUntilDestroy} from '../../services/take-until-destroy.decorator';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {Observable} from 'rxjs';
import {CourseDiagram, CourseDiagramsService} from '../../swagger-codegen';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {finalize, takeUntil} from 'rxjs/operators';
import {ToastService} from '../../services/toast.service';


@TakeUntilDestroy
@Component({
  selector: 'app-course-diagram-edit',
  templateUrl: './course-diagram-edit.component.html',
  styleUrls: ['./course-diagram-edit.component.scss']
})
export class CourseDiagramEditComponent implements OnInit {
  @BlockUI()
  blockUI: NgBlockUI;
  componentDestroy: () => Observable<boolean>;
  @Input()
  editItem: CourseDiagram;
  @Output()
  editFinished: EventEmitter<CourseDiagram[]> = new EventEmitter<CourseDiagram[]>();

  cdForm: FormGroup;
  surfaceArray = ['Drt', 'Syn', 'Trf', 'Other', 'Jump', 'Track', 'Hunt'];


  constructor(private cdService: CourseDiagramsService, private toast: ToastService) {
  }

  ngOnInit(): void {
    if (this.editItem.id) {
      this.cdForm = new FormGroup(
        {
          id: new FormControl(this.editItem?.id),
          sortOrder: new FormControl(this.editItem?.sortOrder, [Validators.required]),
          surface: new FormControl(this.editItem?.surface, [Validators.required]),
          svg: new FormControl(this.editItem?.svg, [Validators.required])
        }
      );
    } else {
      this.cdForm = new FormGroup(
        {
          id: new FormControl(0),
          sortOrder: new FormControl(1, [Validators.required]),
          surface: new FormControl(this.surfaceArray[0], [Validators.required]),
          svg: new FormControl('', [Validators.required])
        }
      );
    }

  }

  cancel(): void {
    this.editFinished.emit(null);
  }

  save(): void {
    if (this.cdForm.valid) {
      this.blockUI.start();
      this.cdService.apiAdminCourseDiagramsSavePost(this.cdForm.value).pipe(
        finalize(() => {
          this.blockUI.stop();
        }),
        takeUntil(this.componentDestroy())).subscribe((result) => {
        this.editFinished.emit(result);
        this.toast.success('Course Diagram successfully saved');
      });
    }
  }
}
