import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {asyncScheduler, Observable} from 'rxjs';
import {Router} from '@angular/router';
import {map} from 'rxjs/operators';
import {ToastService} from './services/toast.service';
import {BlockUI, NgBlockUI} from 'ng-block-ui';

@Injectable()
export class JtHttp {
  // tslint:disable-next-line:variable-name
  private _cache: { [url: string]: any } = {};
  @BlockUI()
  blockUI: NgBlockUI;

  constructor(private http: HttpClient, private toast: ToastService, private router: Router) {
  }

  // public get<T>(url: string, options: any): Observable<T> {
  //   return this.http.get(url, options)
  //     .pipe(map(r => this.processResponse<T>(r)));
  // }
  public clearCache(): void {
    this._cache = {};
  }

  public get<T>(url: string, options: Options): Observable<T> {
    let showData = null;
    let complete = null;
    const o = Observable.create(observer => {
      showData = v => {
        observer.next(v);
      };
      complete = () => {
        observer.complete();
      };
      // getCache
      if (this._cache[url] && options && options.reportProgress) {
        options.reportProgress = false;
        showData(this._cache[url]);
      }
    });

    let refresh = null;
    Observable.create(observer => {
      refresh = () => {
        observer.next();
      };
    })
      .throttleTime(3000, asyncScheduler, {
        leading: true,
        trailing: true,
      })
      .subscribe(() => {
        return this.http
          .get(url, options)
          .pipe(map(r => this.processResponse<T>(r)))
          .subscribe(response => {
            showData((this._cache[url] = response));
            complete();
          });
      });
    refresh();
    return o;
  }

  public request<T>(cmd: string, url: string, options: Options): Observable<T> {
    switch (cmd) {
      case 'get':
        return this.get<T>(url, options);
      case 'post':
        return this.post<T>(url, options?.body, options);
      case 'put':
        return this.put<T>(url, options?.body, options);
      case 'delete':
        return this.delete<T>(url, options);
      default:
        return this.get<T>(url, options);
    }
  }

  public post<T>(url: string, body: any | null, options: any): Observable<T> {
    return this.http.post(url, body, options).pipe(map(r => this.processResponse(r)));
  }

  public put<T>(url: string, body: any | null, options: any): Observable<T> {
    return this.http.put(url, body, options).pipe(map(r => this.processResponse(r)));
  }

  public delete<T>(url: string, options: any): Observable<T> {
    return this.http.delete(url, options).pipe(map(r => this.processResponse(r)));
  }

  private processResponse<T>(response: any): T {
    if (response.body) {
      return response.body;
    }
    const resp = response as HttpResponse<T>;
    if (resp.isSuccess) {
      return resp.successData;
    }
    if (resp.errorCode === 'Unauthenticated' || resp.errorCode === 'Unauthorized') {
      this.toast.warning(resp.errorHumanReadableMessage);
      this.router.navigate(['/login']);
      return;
    }
    // console.log(resp);
    this.blockUI.stop();
    this.toast.error(response.errorHumanReadableMessage);
    throw new Error(resp.errorCode);
  }
}

export class HttpResponse<T> {
  errorCode?: string;
  errorHumanReadableMessage?: string;
  isSuccess: boolean;
  successData: T;
}

interface Options {
  body?: any;
  params?: any;
  withCredentials?: any;
  headers?: any;
  observe?: any;
  reportProgress?: boolean;
}

declare module './swagger-codegen/model/publicToteEvent' {
  interface PublicToteEvent {
    equinEdgeTrackName?: string;
  }
}

export interface TooltipeStorage {
  key: string;
  value: string;
}

// help cache  example
// to use the cache you need to set the last parameter = true;
// racesService.apiRacesByIdGet(race.raceId, null, true)
//         .subscribe(raceDetails => {
//         });

// help How get full request example
// need to add 'response'
// racesService.apiRacesByIdGet(race.raceId, 'response')
//         .subscribe(result => {
//             const temp= result as HttpResponse<RaceDetails>;
//         });
