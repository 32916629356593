import {Injectable} from '@angular/core';
import {TrackDiagramsService, TracksService, WagerTypeTextsService} from '../swagger-codegen';
import {LoginService} from './login.service';

@Injectable()
export class DataLeftService {
  public wagerTypeTextsLeft: number;
  public needsAttentionLeft: number;
  public needsTrackDiagramsLeft: number;
  public needsToteTracksLeft: number;

  constructor(
    private tdService: TrackDiagramsService,
    private wtService: WagerTypeTextsService,
    private tService: TracksService,
    private loginService: LoginService
  ) {
    loginService.loggedIn$.subscribe((auth) => {
      if (auth || loginService.isAuthenticated()) {
        wtService.apiAdminWagerTypeTextsGet('response').subscribe(result => {
          if (result.isSuccess) {
            this.wagerTypeTextsLeft = result.successData.length;
          }
        });
        tService.apiAdminTracksGet('response').subscribe(result => {
          if (result.isSuccess) {
            this.needsAttentionLeft = result.successData.filter(x => x.needsAttention === true).length;
          }
        });
        tdService.apiAdminTrackDiagramsMissingGet('response').subscribe(result => {
          if (result.isSuccess) {
            this.needsTrackDiagramsLeft = result.successData.length;
          }
        });
      }
    });
  }
}
