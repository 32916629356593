/**
 * Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import {  HttpHeaders, HttpParams,
          HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ClaimType } from '../model/claimType';
import { CouponDetailDto } from '../model/couponDetailDto';
import { CouponDto } from '../model/couponDto';
import { CouponOptionDto } from '../model/couponOptionDto';
import { PublicCouponDefinition } from '../model/publicCouponDefinition';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


import { JtHttp, HttpResponse } from '../../JtHttp';

@Injectable()
export class CouponAdminService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: JtHttp, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminCouponCouponClaimTypesGet(observe?: 'body', reportProgress?: boolean): Observable<Array<ClaimType>>;
    public apiAdminCouponCouponClaimTypesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ClaimType>>>;
    public apiAdminCouponCouponClaimTypesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ClaimType>>>;
    public apiAdminCouponCouponClaimTypesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ClaimType>>('get',`${this.basePath}/api/Admin/Coupon/CouponClaimTypes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminCouponCouponOptionsGet(observe?: 'body', reportProgress?: boolean): Observable<Array<CouponOptionDto>>;
    public apiAdminCouponCouponOptionsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CouponOptionDto>>>;
    public apiAdminCouponCouponOptionsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CouponOptionDto>>>;
    public apiAdminCouponCouponOptionsGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<CouponOptionDto>>('get',`${this.basePath}/api/Admin/Coupon/CouponOptions`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminCouponGet(observe?: 'body', reportProgress?: boolean): Observable<Array<PublicCouponDefinition>>;
    public apiAdminCouponGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PublicCouponDefinition>>>;
    public apiAdminCouponGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PublicCouponDefinition>>>;
    public apiAdminCouponGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<PublicCouponDefinition>>('get',`${this.basePath}/api/Admin/Coupon`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminCouponIdGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<CouponDetailDto>;
    public apiAdminCouponIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CouponDetailDto>>;
    public apiAdminCouponIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CouponDetailDto>>;
    public apiAdminCouponIdGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiAdminCouponIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CouponDetailDto>('get',`${this.basePath}/api/Admin/Coupon/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param filterBy 
     * @param filterValue 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminCouponNewCouponsGet(filterBy?: string, filterValue?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<CouponDto>>;
    public apiAdminCouponNewCouponsGet(filterBy?: string, filterValue?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CouponDto>>>;
    public apiAdminCouponNewCouponsGet(filterBy?: string, filterValue?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CouponDto>>>;
    public apiAdminCouponNewCouponsGet(filterBy?: string, filterValue?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filterBy !== undefined && filterBy !== null) {
            queryParameters = queryParameters.set('FilterBy', <any>filterBy);
        }
        if (filterValue !== undefined && filterValue !== null) {
            queryParameters = queryParameters.set('FilterValue', <any>filterValue);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<CouponDto>>('get',`${this.basePath}/api/Admin/Coupon/NewCoupons`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminCouponSecureGUIDGet(observe?: 'body', reportProgress?: boolean): Observable<string>;
    public apiAdminCouponSecureGUIDGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public apiAdminCouponSecureGUIDGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public apiAdminCouponSecureGUIDGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('get',`${this.basePath}/api/Admin/Coupon/SecureGUID`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
