import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';


@Injectable()
export class ToastService {
  public warning: (message: string) => void;
  public success: (message: string) => void;
  public successRequiresReload: (message: string) => void;
  public error: (message: string) => void;
  public showApiError: (error: any) => void;
  public clearAllToasts: () => void;

  constructor(toastsManager: ToastrService) {
    this.success = (message: string): void => {
      toastsManager.success(message, null, {timeOut: 5000 + 0.1 * message.length});
    };

    this.warning = (message: string): void => {
      toastsManager.warning(message, null, {timeOut: 5000 + 0.1 * message.length});
    };

    this.error = (message: string) => {
      toastsManager.error(
        message,
        null,
        {
          disableTimeOut: true
        }
      );
    };

    this.showApiError = (error: Error) => {
      this.error(error.message);
    };

    this.clearAllToasts = () => {
      toastsManager.clear();
    };
  }

}
