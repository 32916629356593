import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {PublicSubscription, PublicUser, UsersService} from '../../swagger-codegen';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastService} from '../../services/toast.service';
import {Observable} from 'rxjs';
import {TakeUntilDestroy} from '../../services/take-until-destroy.decorator';
import {takeUntil} from 'rxjs/operators';

@TakeUntilDestroy
@Component({
  selector: 'app-user-subscription',
  templateUrl: './user-subscription.component.html',
  styleUrls: ['./user-subscription.component.scss']
})
export class UserSubscriptionComponent implements OnInit {
  subForm: FormGroup = new FormGroup({
    status: new FormControl('', Validators.required),
    currentPeriodEndsDaysFromNow: new FormControl(0, Validators.required)
  });
  editSubModel: PublicSubscription = null;
  currentUser: PublicUser = null;
  componentDestroy: () => Observable<boolean>;

  constructor(public activeModal: NgbActiveModal, private toast: ToastService, private userService: UsersService) {
  }

  ngOnInit(): void {
  }


  checkSubError(control: string): boolean {
    return this.subForm.controls[control].invalid && this.subForm.controls[control].touched;
  }

  checkSubValid(control: string): boolean {
    return !this.subForm.controls[control].valid && this.subForm.controls[control].touched;
  }

  onSubSubmit(): void {
    if (this.subForm.valid) {
      this.userService.apiAdminUsersSetSubscriptionStatusPost({
        currentPeriodEndsDaysFromNow: this.subForm.value.currentPeriodEndsDaysFromNow,
        status: this.subForm.value.status,
        email: this.currentUser.email
      }).pipe(
        takeUntil(this.componentDestroy())
      ).subscribe((result) => {
        this.toast.success(this.currentUser.email + ' subscription is updated!');
        this.activeModal.close(true);
      });
    } else {
      this.toast.error('Please, set status and Current Period Ends Days From Now');
    }
  }
}
