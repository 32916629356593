import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {EntryWagerTypeDto, RaceWagerType, RaceWagerTypeService} from '../../swagger-codegen';
import {Observable} from 'rxjs';
import {EntryWagerCustom} from '../../wager-type-texts/wager-type-texts.component';
import _ from 'lodash';
import {finalize, takeUntil} from 'rxjs/operators';
import {TakeUntilDestroy} from '../../services/take-until-destroy.decorator';
import {ToastService} from '../../services/toast.service';

@TakeUntilDestroy
@Component({
  selector: 'app-race-wager-type-edit',
  templateUrl: './race-wager-type-edit.component.html',
  styleUrls: ['./race-wager-type-edit.component.scss']
})
export class RaceWagerTypeEditComponent implements OnInit {
  @BlockUI()
  blockUI: NgBlockUI;
  componentDestroy: () => Observable<boolean>;
  public possibleDenominations: Denomination[] = [
    {value: 0, display: '0¢'},
    {value: .1, display: '10¢'},
    {value: .2, display: '20¢'},
    {value: .5, display: '50¢'},
    {value: 1, display: '$1'},
    {value: 2, display: '$2'},
    {value: 5, display: '$5'},
    {value: 10, display: '$10'},
    {value: 20, display: '$20'},
    {value: 50, display: '$50'},
  ];
  public wagerTypes: EntryWagerCustom[];
  @Input()
  editRaceWagerText: RaceWagerType;
  @Output()
  editFinished: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private rwtService: RaceWagerTypeService, private toast: ToastService) {
  }

  ngOnInit(): void {
    this.wagerTypes = [
      {name: 'Exacta', active: false, type: EntryWagerTypeDto.TypeEnum.Vertical, slotCount: 2},
      {name: 'Trifecta', active: false, type: EntryWagerTypeDto.TypeEnum.Vertical, slotCount: 3},
      {name: 'Superfecta', active: false, type: EntryWagerTypeDto.TypeEnum.Vertical, slotCount: 4},
      {name: 'Super High 5', active: false, type: EntryWagerTypeDto.TypeEnum.Vertical, slotCount: 5},
      {name: 'Daily Double', active: false, type: EntryWagerTypeDto.TypeEnum.Horizontal, slotCount: 2},
      {name: 'Pick 3', active: false, type: EntryWagerTypeDto.TypeEnum.Horizontal, slotCount: 3},
      {name: 'Pick 4', active: false, type: EntryWagerTypeDto.TypeEnum.Horizontal, slotCount: 4},
      {name: 'Pick 5', active: false, type: EntryWagerTypeDto.TypeEnum.Horizontal, slotCount: 5},
      {name: 'Pick 6', active: false, type: EntryWagerTypeDto.TypeEnum.Horizontal, slotCount: 6},
    ];
    if (!_.isEmpty(this.editRaceWagerText) && this.editRaceWagerText?.type) {
      _.find(this.wagerTypes,
          (x) => x.slotCount === this.editRaceWagerText.slotCount && x.type === this.editRaceWagerText.type).active = true;
    }
  }

  setActive(r: EntryWagerCustom): void {
    _.forEach(this.wagerTypes, (x) => {
      x.active = x.slotCount === r.slotCount && x.type === r.type;
    });
  }

  cancel(): void {
    this.editFinished.emit(true);
  }

  save(): void {
    this.wagerTypes.forEach(element => {
      if (element.active) {
        this.editRaceWagerText.slotCount = element.slotCount;
        this.editRaceWagerText.type = element.type;
        return;
      }
    });
    if (!this.editRaceWagerText?.raceId || !this.editRaceWagerText?.type) {
      this.toast.error('Please, fill raceId and wager');
      return;
    }
    this.blockUI.start();
    this.rwtService.apiAdminRaceWagerTypePost(this.editRaceWagerText)
        .pipe(
            finalize(() => {
              this.blockUI.stop();
              this.editFinished.emit(true);
            }),
            takeUntil(this.componentDestroy()))
        .subscribe(data => {
          this.blockUI.stop();
        });
  }

  delete(): void {
    this.blockUI.start();
    this.rwtService.apiAdminRaceWagerTypeIdDelete(this.editRaceWagerText.id)
        .pipe(
            finalize(() => {
              this.blockUI.stop();
              this.editFinished.emit(true);
            }),
            takeUntil(this.componentDestroy()))
        .subscribe(data => {
          this.blockUI.stop();
        });
  }
}

interface Denomination {
  value: number;
  display: string;
}
