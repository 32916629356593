import { Component, OnInit } from '@angular/core';
import {FinanceService, SettledTransactionReportResponse} from "@swagger-codegen/*";

@Component({
  selector: 'app-settled-transactions',
  templateUrl: './settled-transactions.component.html',
  styleUrls: ['./settled-transactions.component.scss']
})
export class SettledTransactionsComponent implements OnInit {
  report: SettledTransactionReportResponse;
  constructor(private readonly financeService: FinanceService) { }

  ngOnInit(): void {
    this.financeService.apiAdminReportsFinanceSettledTransactionsGet().subscribe((response: SettledTransactionReportResponse) => {
      console.log(response);
      this.report = response;
    });
  }

}
