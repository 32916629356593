import {Component, OnInit, ViewChild} from '@angular/core';
import {EntryWagerTypeDto, PublicMinimumDenomination, PublicTrack, Timezone, TracksService} from '../swagger-codegen';
import * as _ from 'lodash';
import {ColumnSortedEvent, SortService} from '../sortable-column/sort-service';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DataLeftService} from '../services/data-left.service';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {TakeUntilDestroy} from '../services/take-until-destroy.decorator';
import {Observable} from 'rxjs';
import {finalize, takeUntil} from 'rxjs/operators';

@TakeUntilDestroy
@Component({
  selector: 'app-tracks',
  templateUrl: './tracks.component.html',
  styleUrls: ['./tracks.component.scss']
})
export class TracksComponent implements OnInit {
  componentDestroy: () => Observable<boolean>;
  public originalTracks: PublicTrack[];
  public tracks: PublicTrack[];
  public tracksSorted: PublicTrack[];
  @BlockUI()
  blockUI: NgBlockUI;
  filter = '';
  currentPage = 1;
  pageSize = 12;
  sortEvent: ColumnSortedEvent;
  closeResult: string;
  trackForm: FormGroup;
  timeZones: Timezone[];
  minimumWagers: { text: string, value: number }[] =
    [{text: '10¢', value: 0.1},
      {text: '20¢', value: 0.2},
      {text: '50¢', value: 0.5},
      {text: '1.00$', value: 1},
      {text: '2.00$', value: 2},
      {text: '3.00$', value: 3},
      {text: '5.00$', value: 5}];
  wagerTypes = [
    {name: 'Exacta', active: false, type: EntryWagerTypeDto.TypeEnum.Vertical, slotCount: 2, minimumWager: null},
    {name: 'Trifecta', active: false, type: EntryWagerTypeDto.TypeEnum.Vertical, slotCount: 3, minimumWager: null},
    {name: 'Superfecta', active: false, type: EntryWagerTypeDto.TypeEnum.Vertical, slotCount: 4, minimumWager: null},
    {name: 'Super High 5', active: false, type: EntryWagerTypeDto.TypeEnum.Vertical, slotCount: 5, minimumWager: null},
    {name: 'Daily Double', active: false, type: EntryWagerTypeDto.TypeEnum.Horizontal, slotCount: 2, minimumWager: null},
    {name: 'Pick 3', active: false, type: EntryWagerTypeDto.TypeEnum.Horizontal, slotCount: 3, minimumWager: null},
    {name: 'Pick 4', active: false, type: EntryWagerTypeDto.TypeEnum.Horizontal, slotCount: 4, minimumWager: null},
    {name: 'Pick 5', active: false, type: EntryWagerTypeDto.TypeEnum.Horizontal, slotCount: 5, minimumWager: null},
    {name: 'Pick 6', active: false, type: EntryWagerTypeDto.TypeEnum.Horizontal, slotCount: 6, minimumWager: null}
  ];
  @ViewChild('content', {static: true}) content: any;
  reg = '^(https?|ftp)://[^\\s/$.?#].[^\\s]*';

  constructor(public dataLeftService: DataLeftService, public sortService: SortService, private modalService: NgbModal,
              public trackService: TracksService) {
  }

  getWagerNameAndMin(a: PublicMinimumDenomination): string {
    const temp1 = this.wagerTypes.filter(x => x.slotCount === a.slotCount && x.type === a.type).pop();
    const temp2 = this.minimumWagers.filter(y => y.value === a.minimumDenomination).pop();
    if (!temp2) {
      return `${temp1.name}(${a.minimumDenomination})`;
    }
    return `${temp1.name}(${temp2.text})`;
  }

  getWagerString(b: PublicMinimumDenomination[]): string {
    if (b && b.length > 0) {
      const sorted = _.chain(b).sortBy(['type', 'slotCount']).uniqWith(_.isEqual).value();
      let st = '';
      sorted.forEach((x) => {
        if (st) {
          st += ', ';
        }
        st += this.getWagerNameAndMin(x);
      });
      return st;
    } else {
      return '';
    }

  }

  ngOnInit(): void {
    this.blockUI.start();
    this.trackService.apiAdminTracksGet()
      .pipe(
        finalize(() => {
          this.blockUI.stop();
        }),
        takeUntil(this.componentDestroy()))
      .subscribe((result) => {
        this.originalTracks = _.chain(result).orderBy(
          ['needsAttention', 'name'],
          ['desc', 'asc']
        ).value();
        this.sortByEvent();
      });
    this.trackService.apiAdminTracksTimezonesGet()
      .pipe(
        finalize(() => {
          this.blockUI.stop();
        }),
        takeUntil(this.componentDestroy()))
      .subscribe((result) => {
        this.timeZones = _.sortBy(result, ((x) => x.display));
        this.trackForm = new FormGroup({
          id: new FormControl('', Validators.required),
          equibaseCountry: new FormControl('', Validators.required),
          equibaseTrackId: new FormControl('', Validators.required),
          abbreviation: new FormControl('', Validators.required),
          name: new FormControl('', Validators.required),
          timezoneId: new FormControl(''),
          shouldShow: new FormControl('', Validators.required),
          needsAttention: new FormControl('', Validators.required),
          trackAddress: new FormControl(''),
          trackCity: new FormControl(''),
          trackState: new FormControl(''),
          trackCountry: new FormControl(''),
          weatherUrl: new FormControl('', Validators.pattern(this.reg)),
          checkGeolocation: new FormControl(false),
          longitude: new FormControl(''),
          latitude: new FormControl(''),
        });
      });

  }


  editTrack(id: number): void {
    const temp = _.cloneDeep(this.tracks.filter((x) => x.id === id)).pop();
    this.trackForm.patchValue(temp);
    this.trackForm.markAsUntouched();

    if (temp.minimumDenominations && temp.minimumDenominations.length > 0) {
      this.wagerTypes.forEach(x => {
        const inclided = temp.minimumDenominations.filter((y) => y.type === x.type && y.slotCount === x.slotCount).pop();
        if (inclided) {
          x.active = true;
          x.minimumWager = inclided.minimumDenomination;
        } else {
          x.active = false;
          x.minimumWager = null;
        }
      });
    } else {
      this.wagerTypes.forEach(x => {
        x.active = false;
        x.minimumWager = null;
      });
    }

    this.open(this.content);
  }

  sort(newPage: number): void {
    const startItem = (newPage - 1) * this.pageSize;
    this.tracksSorted = this.tracks.slice(startItem, startItem + this.pageSize);
  }

  search(): void {
    this.sortByEvent();
  }

  public onSorted(event: ColumnSortedEvent): void {
    this.sortEvent = event;
    this.sortByEvent();
  }

  sortByEvent(currentPage: number = 1): void {
    this.filter = this.filter.trim();
    if (this.filter) {
      this.tracks = this.originalTracks.filter((x) => _.includes(x.abbreviation.toLocaleUpperCase(), this.filter.toLocaleUpperCase())
        || _.includes(x.name.toLocaleUpperCase(), this.filter.toLocaleUpperCase()));
    } else {
      this.tracks = this.originalTracks;
    }
    if (this.sortEvent && this.tracks) {
      if (this.sortEvent.sortColumn === 'needsAttention') {

        this.tracks = _.chain(this.tracks)
          .orderBy(
            ['needsAttention', 'name'],
            [this.sortEvent.sortDirection, 'asc'])
          .value();
      } else {
        this.tracks = _.chain(this.tracks).orderBy(
          [this.sortEvent.sortColumn],
          [this.sortEvent.sortDirection]
        ).value();
      }
      this.sortService.lastSortEvent = this.sortEvent;
    } else if (!this.sortEvent && this.tracks && this.sortService.lastSortEvent) {
      this.sortEvent = this.sortService.lastSortEvent;
      this.sortByEvent();
    }
    this.currentPage = currentPage;
    this.sort(this.currentPage);
  }

  pageChanged(newPage: number): void {
    this.sort(newPage);
  }

  checkError(control: string): boolean {
    return this.trackForm.controls[control].invalid && this.trackForm.controls[control].touched;
  }

  onSubmit(): void {
    if (this.trackForm.valid) {
      const temp: PublicTrack = _.filter(this.tracks, (x) => x.id === this.trackForm.value.id)[0];
      temp.abbreviation = this.trackForm.value.abbreviation;
      temp.equibaseCountry = this.trackForm.value.equibaseCountry;
      temp.equibaseTrackId = this.trackForm.value.equibaseTrackId;
      temp.name = this.trackForm.value.name;
      temp.needsAttention = JSON.parse(this.trackForm.value.needsAttention);
      temp.shouldShow = JSON.parse(this.trackForm.value.shouldShow);
      temp.timezoneId = this.trackForm.value.timezoneId;
      temp.trackAddress = this.trackForm.value.trackAddress;
      temp.trackCity = this.trackForm.value.trackCity;
      temp.trackState = this.trackForm.value.trackState;
      temp.trackCountry = this.trackForm.value.trackCountry;
      temp.weatherUrl = this.trackForm.value.weatherUrl;
      temp.checkGeolocation = this.trackForm.value.checkGeolocation;
      temp.longitude = this.trackForm.value.longitude;
      temp.latitude = this.trackForm.value.latitude;
      temp.minimumDenominations = [];
      this.wagerTypes.forEach((x) => {
        if (x.minimumWager) {
          temp.minimumDenominations.push({type: x.type, slotCount: x.slotCount, minimumDenomination: x.minimumWager});
        }
      });
      this.trackService.apiAdminTracksUpdatePut(temp).subscribe((result) => {
        this.originalTracks = _.chain(result).orderBy(
          ['needsAttention', 'name'],
          ['desc', 'asc']
        ).value();
        this.sortByEvent(this.currentPage);
        this.dataLeftService.needsAttentionLeft = _.filter(result, (x) => x.needsAttention === true).length;
      });
      this.modalService.dismissAll();
    } else {
      this.trackForm.controls.abbreviation.markAsTouched();
      this.trackForm.controls.equibaseCountry.markAsTouched();
      this.trackForm.controls.equibaseTrackId.markAsTouched();
      this.trackForm.controls.name.markAsTouched();
      this.trackForm.controls.needsAttention.markAsTouched();
      this.trackForm.controls.shouldShow.markAsTouched();
      this.trackForm.controls.timezoneId.markAsTouched();
      this.trackForm.controls.trackAddress.markAsTouched();
      this.trackForm.controls.trackCity.markAsTouched();
      this.trackForm.controls.trackState.markAsTouched();
      this.trackForm.controls.trackCountry.markAsTouched();
      this.trackForm.controls.weatherUrl.markAsTouched();
      this.trackForm.controls.id.markAsTouched();
      this.trackForm.controls.checkGeolocation.markAsTouched();
      this.trackForm.controls.longitude.markAsTouched();
      this.trackForm.controls.latitude.markAsTouched();
    }
  }

  open(content): void {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'lg'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
