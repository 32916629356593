import { Component, OnInit } from '@angular/core';
import {TakeUntilDestroy} from '../services/take-until-destroy.decorator';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {Observable} from 'rxjs';
import {ColumnSortedEvent, SortService} from '../sortable-column/sort-service';
import {finalize, takeUntil} from 'rxjs/operators';
import {Plan, PlanAdminService, PlanPagination} from '../swagger-codegen';

@TakeUntilDestroy
@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.scss']
})
export class PlanComponent implements OnInit {
  @BlockUI()
  blockUI: NgBlockUI;
  selected: Plan;
  componentDestroy: () => Observable<boolean>;
  currentPlans: PlanPagination;
  sortEvent: ColumnSortedEvent = {sortColumn: 'Id', sortDirection: 'asc'};
  pageSize = 100;
  previousPage = 1;
  currentPage = 1;
  totalPages = 0;
  filterByIsDeleted = true;
  filter: {by: string, value: string};
  searchTerm: string;

  constructor(private planService: PlanAdminService, private sortService: SortService) { }

  ngOnInit(): void {
    this.filter = {by: '', value: ''};
    this.sortEvent = {sortColumn: 'Id', sortDirection: 'asc'};
    this.sortByEvent();
  }

  public onSorted(event: ColumnSortedEvent): void {
    this.sortEvent = event;
    this.sortByEvent();
  }

  search(): void {
    this.sortByEvent();
  }

  sortByEvent(currentPage: number = 1): void {
      if (this.sortEvent) {
        this.blockUI.start();
        this.planService.apiAdminPlanGet(
          this.filter.by,
          this.filter.value,
          this.sortEvent.sortColumn,
          this.sortEvent.sortDirection === 'desc',
          currentPage,
          this.pageSize)
          .pipe(
            finalize(this.blockUI.stop),
            takeUntil(this.componentDestroy()))
          .subscribe((result) => {
            this.currentPlans = result;
            this.currentPage = result.currentPage;
            this.pageSize = result.pageSize;
            this.totalPages = result.totalPages;
            this.sortService.lastSortEvent = this.sortEvent;
          });
      } else if (
        !this.sortEvent &&
        this.sortService.lastSortEvent
      ) {
        this.sortEvent = this.sortService.lastSortEvent;
        this.sortByEvent();
      }
  }

  editItem(e?: Plan): void {
    this.selected = e;
    if (!e) {
      this.sortByEvent();
    }
  }

  pageChanged(newPage: number): void {
    if (newPage !== this.previousPage) {
      this.previousPage = newPage;
      this.sortByEvent(newPage);
    }
  }

  choose(val: boolean): void {
    this.filterByIsDeleted = val;
    this.filter = {by: 'IsDeleted', value: `${val}`};
    this.sortByEvent();
  }
}
