import {Component, OnInit} from '@angular/core';
import {TakeUntilDestroy} from '../services/take-until-destroy.decorator';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {
  EntryWagerType,
  EntryWagerTypeDto,
  WagerTypeTextMappingPagination,
  WagerTypeTextMapping,
  WagerTypeTextsService
} from '../swagger-codegen';
import {Observable} from 'rxjs';
import {EntryWagerCustom} from '../wager-type-texts/wager-type-texts.component';
import {finalize, takeUntil} from 'rxjs/operators';
import {ColumnSortedEvent, SortService} from '../sortable-column/sort-service';
import * as _ from 'lodash';

@TakeUntilDestroy
@Component({
  selector: 'app-wager-type-text-mappings',
  templateUrl: './wager-type-text-mappings.component.html',
  styleUrls: ['./wager-type-text-mappings.component.scss']
})
export class WagerTypeTextMappingsComponent implements OnInit {
  @BlockUI()
  blockUI: NgBlockUI;
  selected: WagerTypeTextMapping;
  componentDestroy: () => Observable<boolean>;
  entryWager: Array<EntryWagerTypeDto>;
  public wagerTypes: EntryWagerCustom[];
  public currentMapping: WagerTypeTextMappingPagination;
  sortEvent: ColumnSortedEvent = {sortColumn: 'Id', sortDirection: 'asc'};
  pageSize = 100;
  previousPage = 1;
  currentPage = 1;
  totalPages = 0;

  constructor(private wtService: WagerTypeTextsService, private sortService: SortService) {
  }

  ngOnInit(): void {
    this.init();
    this.sortEvent = {sortColumn: 'Id', sortDirection: 'asc'};
    this.sortByEvent();
  }

  init(): void {
    this.wagerTypes = [
      {name: 'Exacta', active: false, type: 'Vertical', slotCount: 2},
      {name: 'Trifecta', active: false, type: 'Vertical', slotCount: 3},
      {name: 'Superfecta', active: false, type: 'Vertical', slotCount: 4},
      {name: 'Super High 5', active: false, type: 'Vertical', slotCount: 5},
      {name: 'Daily Double', active: false, type: 'Horizontal', slotCount: 2},
      {name: 'Pick 3', active: false, type: 'Horizontal', slotCount: 3},
      {name: 'Pick 4', active: false, type: 'Horizontal', slotCount: 4},
      {name: 'Pick 5', active: false, type: 'Horizontal', slotCount: 5},
      {name: 'Pick 6', active: false, type: 'Horizontal', slotCount: 6},
    ];
  }

  getWagerName(e: EntryWagerType): string {
    return _.chain(this.wagerTypes).findLast((x) => x.slotCount === e.slotCount && x.type === e.type).value().name;
  }

  public onSorted(event: ColumnSortedEvent): void {
    this.sortEvent = event;
    this.sortByEvent();
  }

  sortByEvent(currentPage: number = 1): void {
    if (this.sortEvent) {
      this.blockUI.start();
      this.wtService.apiAdminWagerTypeTextsMappingsGet(this.sortEvent.sortColumn,
        this.sortEvent.sortDirection === 'desc', currentPage, this.pageSize)
        .pipe(finalize(this.blockUI.stop), takeUntil(this.componentDestroy()))
        .subscribe((result) => {
          this.currentMapping = result;
          this.currentPage = result.currentPage;
          this.pageSize = result.pageSize;
          this.totalPages = result.totalPages;
          this.sortService.lastSortEvent = this.sortEvent;
        });
    } else if (
      !this.sortEvent &&
      this.sortService.lastSortEvent
    ) {
      this.sortEvent = this.sortService.lastSortEvent;
      this.sortByEvent();
    }
  }

  editItem(e: WagerTypeTextMapping): void {
    this.selected = e;
    if (!e) {
      this.sortByEvent();
    }
  }

  pageChanged(newPage: number): void {
    if (newPage !== this.previousPage) {
      this.previousPage = newPage;
      this.sortByEvent(newPage);
    }
  }
}
