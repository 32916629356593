export const environment = {
  production: true,
  CLIENT_ID: 'SvIZpOyF2g6nGp3fbG3JkAFJcvGRM0cb',
  CLIENT_DOMAIN: 'equinedge.auth0.com',
  AUDIENCE: 'https://dashboard.equinedge.com',
  // REDIRECT: 'http://localhost:20001/loginCallback',
  REDIRECT: 'https://dashboard.equinedge.com/loginCallback',
  SCOPE: 'openid profile email',
  ApiBaseUrl: 'https://dashboard-api.equinedge.com',
  isDevelopment: false,
  enable: {
    enableServiceWorker: true,
    enableRollbar: true,
  }
};
