<div class="modal-header">
  <h4 class="modal-title" id="modal-subscription">
    Edit
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close(false)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="subForm" (ngSubmit)="onSubSubmit()">
    <table class="table table-sm">
      <thead>
      <tr>
        <th colspan="2">Subscription for {{currentUser?.email}}</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>Id</td>
        <td>{{editSubModel?.id}}</td>
      </tr>
      <tr>
        <td>Name</td>
        <td>{{editSubModel?.name}}</td>
      </tr>
      <tr>
        <td>SubscriptionCreated</td>
        <td>{{editSubModel?.subscriptionCreated | date:'MM/dd/yy'}}</td>
      </tr>
      <tr>
        <td>PeriodStarted</td>
        <td>{{editSubModel?.currentPeriodStarted | date:'MM/dd/yy'}}</td>
      </tr>
      <tr>
        <td>PeriodEnds</td>
        <td>{{editSubModel?.currentPeriodEnds | date:'MM/dd/yy'}}</td>
      </tr>
      <tr>
        <td>Reason</td>
        <td>{{editSubModel?.cancellationReason}}</td>
      </tr>
      </tbody>
    </table>
    <div class="form-group">
      <label for="subStatus" class="col-form-label">Status</label>
      <div>
        <select id="subStatus" class="form-control" formControlName="status">
          <option value="" disabled>Choose subscription status</option>
          <option value="Active">Active</option>
          <option value="GracePeriod">GracePeriod</option>
          <option value="PastDue">PastDue</option>
          <option value="Canceling">Canceling</option>
          <option value="Canceled">Canceled</option>
          <option value="Expired">Expired</option>
          <option value="Pending">Pending</option>
        </select>
      </div>
    </div>
    <div class="form-group">
      <label class="col-form-label" for="currentPeriodEndsDaysFromNow">Current Period Ends Days From
        Now</label>
      <div>
        <input id="currentPeriodEndsDaysFromNow" class="form-control" type="number" [min]="0"
               formControlName="currentPeriodEndsDaysFromNow"
               [ngClass]="{'is-invalid':checkSubError('currentPeriodEndsDaysFromNow'),
                               'is-valid':checkSubValid('currentPeriodEndsDaysFromNow')}">
      </div>
    </div>
  </form>
  <div class="text-center">
    <button class="btn" (click)="onSubSubmit()"
            [ngClass]="{'btn-outline-dark':subForm.invalid,'btn-outline-success':subForm.valid }">Save Subscription
    </button>
  </div>
</div>

