import {Component, OnInit} from '@angular/core';
import {TakeUntilDestroy} from '../services/take-until-destroy.decorator';
import {Meet, MeetAdminService, MeetPagination} from '../swagger-codegen';
import {ColumnSortedEvent, SortService} from '../sortable-column/sort-service';
import {finalize, takeUntil} from 'rxjs/operators';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {Observable} from 'rxjs';
import _ from 'lodash';

@TakeUntilDestroy
@Component({
  selector: 'app-meet',
  templateUrl: './meet.component.html',
  styleUrls: ['./meet.component.scss']
})
export class MeetComponent implements OnInit {
  @BlockUI()
  blockUI: NgBlockUI;
  selected: Meet;
  componentDestroy: () => Observable<boolean>;
  currentMeets: MeetPagination;
  sortEvent: ColumnSortedEvent = {sortColumn: 'Id', sortDirection: 'asc'};
  pageSize = 100;
  previousPage = 1;
  currentPage = 1;
  totalPages = 0;
  filterByIsDeleted = true;
  showPotentialMeets = false;
  filter: { by: string, value: string };
  searchTerm: string;
  potentialMeets: Meet[] = [];
  filterByTrack = '';

  constructor(private meetsService: MeetAdminService,
              private sortService: SortService) {
  }

  ngOnInit(): void {
    this.filter = {by: '', value: ''};
    this.sortEvent = {sortColumn: 'Id', sortDirection: 'asc'};
    this.sortByEvent();
  }

  public onSorted(event: ColumnSortedEvent): void {
    this.sortEvent = event;
    this.sortByEvent();
  }

  get meets(): Meet[] {
    if (this.showPotentialMeets) {
      if (this.filterByTrack) {
        return _.filter(this.potentialMeets, (x) => x.track?.name &&
          _.includes(x.track.name.toLowerCase(), this.filterByTrack.toLowerCase()));
      }
      return this.potentialMeets;
    } else {
      return this.currentMeets.result;
    }
  }

  sortByEvent(currentPage: number = 1): void {
    if (this.sortEvent) {
      if (this.showPotentialMeets) {
        this.potentialMeets = _.orderBy(this.potentialMeets, _.camelCase(this.sortEvent.sortColumn), this.sortEvent.sortDirection);
      } else {
        this.blockUI.start();
        this.meetsService.apiAdminMeetGet(
          this.filter.by,
          this.filter.value,
          this.sortEvent.sortColumn,
          this.sortEvent.sortDirection === 'desc', currentPage, this.pageSize)
          .pipe(finalize(this.blockUI.stop), takeUntil(this.componentDestroy()))
          .subscribe((adminMeet) => {
            this.currentMeets = adminMeet.meets;
            this.currentPage = adminMeet.meets.currentPage;
            this.pageSize = adminMeet.meets.pageSize;
            this.totalPages = adminMeet.meets.totalPages;
            this.sortService.lastSortEvent = this.sortEvent;
            this.potentialMeets = _.filter(adminMeet.potentialMeets, (x) => !!x);
          });
      }

    } else if (
      !this.sortEvent &&
      this.sortService.lastSortEvent
    ) {
      this.sortEvent = this.sortService.lastSortEvent;
      this.sortByEvent();
    }
  }

  editItem(e: Meet): void {
    this.selected = e;
    if (!e) {
      this.sortByEvent();
    }
  }

  pageChanged(newPage: number): void {
    if (newPage !== this.previousPage) {
      this.previousPage = newPage;
      this.sortByEvent(newPage);
    }
  }

  choose(val: boolean): void {
    this.filterByIsDeleted = val;
    this.filter = {by: 'IsDeleted', value: `${val}`};
    this.sortByEvent();
  }
}
