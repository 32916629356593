<h1 class="text-center" *ngIf="editItem?.id">Course Diagrams Edit {{editItem?.id}}</h1>
<h1 class="text-center" *ngIf="!editItem?.id">Create New Course Diagrams</h1>
<form [formGroup]="cdForm" class="px-5" (ngSubmit)="save()">
  <div class="row">
    <div class="col-2 offset-3">
      <div *ngIf="cdForm.value.svg" [innerHTML]="cdForm.value.svg | safe: 'html'" class="diagram"></div>
    </div>
    <div class="col">
      <div class="form-group row">
        <label for="surface" class="col-2">surface</label>
        <select class="form-control col-3" id="surface" formControlName="surface">
          <option *ngFor="let item of surfaceArray">{{item}}</option>
        </select>
      </div>
      <div class="form-group row">
        <label for="sortOrder" class="col-2">sortOrder</label>
        <input type="number" class="form-control col-3" formControlName="sortOrder" id="sortOrder">
      </div>
      <div class="form-group">
        <button class="btn btn-secondary mr-5" (click)="cancel()">Cancel</button>
        <button class="btn btn-success" type="submit" [disabled]="!cdForm.valid">Save</button>
      </div>
    </div>
  </div>
  <input type="hidden" class="form-control" id="id" formControlName="id">
  <div class="form-group">
    <label for="svg">Svg Text</label>
    <textarea class="form-control" id="svg" rows="25" formControlName="svg"></textarea>
  </div>
</form>
