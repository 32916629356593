<ng-container *ngIf="affiliateForm&&editAffiliate&&plans">
  <form class="container-fluid" [formGroup]="affiliateForm">
    <div class="card-header text-center">
      <ng-container *ngIf="!editAffiliate?.id">
        <h1>
          Create new Affiliate
        </h1>
      </ng-container>
      <ng-container *ngIf="editAffiliate?.id">
        <h1>Id: {{ editAffiliate?.id }}</h1>
      </ng-container>
    </div>
    <div class="text-center mt-3">
      <div class="w-50 d-inline-block">
        <div class="mb-2 row">
          <label for="channel" class="col-sm-6 col-form-label">Channel</label>
          <div class="col-sm-6">
            <input type="text" class="form-control" id="channel" formControlName="channel">
          </div>
        </div>
        <div class="mb-2 row">
          <label for="code" class="col-sm-6 col-form-label">Code</label>
          <div class="col-sm-6">
            <input type="text" class="form-control" id="code" formControlName="code">
          </div>
        </div>
        <div class="mb-2 row">
          <label for="companyName" class="col-sm-6 col-form-label">CompanyName</label>
          <div class="col-sm-6">
            <input type="text" class="form-control" id="companyName" formControlName="companyName">
          </div>
        </div>
        <div class="mb-2 row">
          <label for="isDeleted" class="col-sm-6  col-form-label">IsDeleted</label>
          <div class="col-sm-6">
            <input type="checkbox" class="form-control" id="isDeleted" formControlName="isDeleted">
          </div>
        </div>
      </div>
      <div>
        <div class="mb-2">
          <h4>
            FeeSchedules:
          </h4>
        </div>
        <ng-container *ngIf="affiliateForm?.controls?.feeSchedules">
          <table class="table">
            <thead>
            <tr>
              <th>
                #
              </th>
              <th>
                planId
              </th>
              <th>
                planName
              </th>
              <th>
                planAmount
              </th>
              <th>
                planIsDeleted
              </th>
              <th>
                fee
              </th>
              <th>
                action
              </th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let fee of affiliateForm.controls?.feeSchedules?.controls; index as i ">
              <ng-container *ngIf="feeLineForEdit!==i">
                <tr [formGroup]="fee">
                  <td>
                    {{i + 1}}
                  </td>
                  <td>
                    {{getPlan(fee.controls.planId)?.code}}
                  </td>
                  <td>
                    {{getPlan(fee.controls.planId)?.name}}
                  </td>
                  <td>
                    {{getPlan(fee.controls.planId)?.amount}}
                  </td>
                  <td [ngClass]="{'text-danger':getPlan(fee.controls.planId)?.isDeleted}">
                    {{getPlan(fee.controls.planId)?.isDeleted}}
                  </td>
                  <td>
                    {{fee.controls.fee.value}}
                  </td>
                  <td>
                    <button class="btn btn-success mr-2" (click)="selectFeeLine(i)">Edit</button>
                    <button class="btn btn-info" (click)="deleteFee(i)">Delete</button>
                  </td>
                </tr>
              </ng-container>
              <ng-container *ngIf="feeLineForEdit===i">
                <tr [formGroup]="fee">
                  <td>
                    {{i + 1}}
                  </td>
                  <td>
                    <select class="form-control" formControlName="planId">
                      <ng-container *ngFor="let item of plans">
                        <option value="{{item.id}}">{{item.code}}</option>
                      </ng-container>
                    </select>
                  </td>
                  <td>
                    {{getPlan(fee.controls.planId)?.name}}
                  </td>
                  <td>
                    {{getPlan(fee.controls.planId)?.amount}}
                  </td>
                  <td [ngClass]="{'text-danger':getPlan(fee.controls.planId)?.isDeleted}">
                    {{getPlan(fee.controls.planId)?.isDeleted}}
                  </td>
                  <td>
                    <input type="number" class="form-control" formControlName="fee">
                  </td>
                  <td>
                    <button class="btn btn-success mr-2" (click)="selectFeeLine(null)">Save</button>
                    <button class="btn btn-info" (click)="deleteFee(i)">Delete</button>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
            <tr>
              <td colspan="99" class="ml-3 text-left">
                <button class="btn btn-success" (click)="addNewFee()">
                  Add New Fee
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </ng-container>
        <div class="mb-2">
          <h4>Subscriptions:</h4>
        </div>
        <ng-container *ngIf="affiliateForm?.controls?.subscriptions">
          <table class="table">
            <thead>
            <tr>
              <th>
                #
              </th>
              <th>
                email
              </th>
              <th>
                subscriptionId
              </th>
              <th>
                planCode
              </th>
              <th>
                status
              </th>
              <th>
                notes
              </th>
              <th>
                action
              </th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let sub of affiliateForm.controls?.subscriptions?.controls; index as i">
              <ng-container *ngIf="subLineForEdit!==i">
                <tr>
                  <th>
                    {{i + 1}}
                  </th>
                  <th>
                    {{editAffiliate.subscriptions[i]?.subscription?.user?.email}}
                  </th>
                  <th>
                    {{editAffiliate.subscriptions[i]?.subscriptionId}}
                  </th>
                  <td>
                    {{editAffiliate.subscriptions[i]?.subscription?.planCode}}
                  </td>
                  <td>
                    {{editAffiliate.subscriptions[i]?.subscription?.status}}
                  </td>
                  <th>
                    {{sub.controls.notes.value}}
                  </th>
                  <th>
                    <button class="btn btn-success mr-2" (click)="selectSubLine(i)">Edit</button>
                    <button class="btn btn-info" (click)="deleteSubscription(i)">Delete</button>
                  </th>
                </tr>
              </ng-container>
              <ng-container *ngIf="subLineForEdit===i">
                <tr [formGroup]="sub">
                  <td>
                    {{i + 1}}
                  </td>
                  <td style="min-width: 300px;">
                    <ng-select [items]="people$ | async"
                               bindLabel="email"
                               [hideSelected]="true"
                               [trackByFn]="trackByFn"
                               bindValue="id"
                               [minTermLength]="3"
                               [loading]="peopleLoading"
                               typeToSearchText="Please enter 3 or more characters"
                               [typeahead]="peopleInput$"
                               formControlName="userId"
                               (change)="setUser($event)">
                      <ng-template ng-option-tmp ng-label-tmp let-item="item" let-index="index" let-search="searchTerm">
                        <div>{{item?.email}}, {{item?.firstName }} {{item?.lastName }}</div>
                      </ng-template>
                    </ng-select>
                  </td>
                  <td>
                    {{sub.controls.subscriptionId.value}}
                  </td>
                  <td>
                    {{editAffiliate.subscriptions[i]?.subscription?.planCode}}
                  </td>
                  <td>
                    {{editAffiliate.subscriptions[i]?.subscription?.status}}
                  </td>
                  <td>
                    <input type="text" class="form-control" formControlName="notes">
                  </td>
                  <td>
                    <button class="btn btn-success mr-2" (click)="selectSubLine(null)">Save</button>
                    <button class="btn btn-info" (click)="deleteSubscription(i)">Delete</button>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
            <tr>
              <td colspan="99" class="ml-3 text-left">
                <button class="btn btn-success" (click)="addNewSubscription()">
                  Add New Subscriptions
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </ng-container>
      </div>
    </div>
    <div class="text-center py-3">
      <button *ngIf="editAffiliate" type="button" class="toggleButton btn btn-secondary mr-3" (click)="cancel()">
        Cancel
      </button>
      <button type="button" class="toggleButton btn btn-primary" (click)="save()"
              [disabled]="!affiliateForm?.valid">
        Save
      </button>

    </div>
  </form>
</ng-container>

