<ng-container *ngIf="!selected">
  <h1 class="text-center">Plans</h1>
  <table class="table table-hover" sortable-table (sorted)="onSorted($event)" id="excel-table">
    <thead>
    <tr>
      <th colspan="4">
        <button class="btn btn-success" (click)="editItem()">Add New</button>
      </th>
      <th colspan="3" class="py-0">
        <div class="input-group mb-2">
          <div class="input-group-prepend">
            <div class="input-group-text"><i class="fa fa-search"></i></div>
          </div>
          <input type="text" class="form-control" [(ngModel)]="searchTerm" placeholder="Search" (ngModelChange)="search()">
        </div>
      </th>
      <th colspan="3" class="right">
        <div class="btn-group btn-group-toggle" data-toggle="buttons">
          <label class="btn btn-outline-info btn-sm" [ngClass]="{'active':filterByIsDeleted === true}">
            <input type="radio" name="options" id="option1"
                   [checked]="filterByIsDeleted === true"
                   [(ngModel)]="filterByIsDeleted"
                   (ngModelChange)="choose($event)"
                   [value]="true"> Show Deleted
          </label>
          <label class="btn btn-outline-info btn-sm" [ngClass]="{'active':filterByIsDeleted === false}">
            <input type="radio" name="options" id="option2"
                   [checked]="filterByIsDeleted === false"
                   [(ngModel)]="filterByIsDeleted"
                   (ngModelChange)="choose($event)"
                   [value]="false"> Hide Deleted
          </label>
        </div>
      </th>
    </tr>
    <tr>
      <th scope="col" sortable-column="Id" class="pointer">Id</th>
      <th scope="col" sortable-column="Code" class="pointer">Code</th>
      <th scope="col" sortable-column="Name" class="pointer">Name</th>
      <th scope="col" sortable-column="Description" class="pointer">Description</th>
      <th scope="col" sortable-column="Amount" class="pointer">Amount</th>
      <th scope="col" sortable-column="Type" class="pointer">Type</th>
      <th scope="col" sortable-column="ShouldAutoRenew" class="pointer">ShouldAutoRenew?</th>
      <th scope="col" sortable-column="DaysBeforeRenewalOrExpiration" class="pointer">
        DaysBeforeRenewalOrExpiration
      </th>
      <th scope="col" sortable-column="CanSell" class="pointer">Can Sell?</th>
      <th scope="col" sortable-column="IsDeleted" class="pointer">
        Is Deleted?
      </th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let item of currentPlans?.result">
      <tr (click)="editItem(item)" [ngClass]="{'bg-danger':item.isDeleted}">
        <td class="width-60">{{item.id}}</td>
        <td>{{item.code}}</td>
        <td>{{item.name}}</td>
        <td>{{item.description}}</td>
        <td>{{item.amount}}</td>
        <td>{{item.type}}</td>
        <td>{{item.shouldAutoRenew}}</td>
        <td>{{item.daysBeforeRenewalOrExpiration}}</td>
        <td>{{item.canSell}}</td>
        <td>{{item.isDeleted}}</td>
      </tr>
    </ng-container>
    </tbody>
  </table>
  <ng-container *ngIf="currentPlans">
    <ngb-pagination [pageSize]="pageSize" [collectionSize]="currentPlans.totalItems"
                    class="d-flex justify-content-center"
                    (pageChange)="pageChanged($event)" [rotate]="true" [(page)]="currentPage" [maxSize]="30"
                    [boundaryLinks]="true">
    </ngb-pagination>
  </ng-container>
</ng-container>
<ng-container *ngIf="selected">
  <app-plan-edit [editPlan]="selected" (editFinished)="editItem(null)"></app-plan-edit>
</ng-container>
