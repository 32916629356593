import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TakeUntilDestroy} from '../../services/take-until-destroy.decorator';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {Affiliate, AffiliatesService, Plan, PlanAdminService, User, UsersService} from '../../swagger-codegen';
import {concat, Observable, of, Subject, Subscription} from 'rxjs';
import {ToastService} from '../../services/toast.service';
import {catchError, debounceTime, distinctUntilChanged, filter, finalize, map, switchMap, takeUntil, tap} from 'rxjs/operators';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import * as _ from 'lodash';

@TakeUntilDestroy
@Component({
  selector: 'app-affiliate-edit',
  templateUrl: './affiliate-edit.component.html',
  styleUrls: ['./affiliate-edit.component.scss']
})
export class AffiliateEditComponent implements OnInit {
  @BlockUI()
  blockUI: NgBlockUI;
  componentDestroy: () => Observable<boolean>;

  @Input()
  editAffiliate: Affiliate;
  @Output()
  editFinished: EventEmitter<boolean> = new EventEmitter<boolean>();
  plans: Plan[];
  affiliateForm: FormGroup;
  people$: Observable<User[]>;
  peopleLoading = false;
  peopleInput$ = new Subject<string>();
  feeLineForEdit?: number = null;
  subLineForEdit?: number = null;
  newUser: User;

  constructor(private afService: AffiliatesService, private toast: ToastService, private ps: PlanAdminService, private us: UsersService) {
  }

  selectSubLine(n: number): void {
    if (this.subLineForEdit !== null) {
      if (this.newUser) {
        this.changeUserSettings(this.newUser);
        this.subLineForEdit = n;
      } else {
        if (this.editAffiliate?.subscriptions[this.subLineForEdit]?.subscription?.user) {
          this.setUser(this.editAffiliate?.subscriptions[this.subLineForEdit]?.subscription?.user);
          this.subLineForEdit = n;
        } else {
          this.toast.error('Please, set valid data');
        }
      }
    } else {
      this.subLineForEdit = n;
    }
  }

  changeUserSettings(u: User): void {
    if (u.subscriptions && u.subscriptions.length > 0) {
      this.editAffiliate.subscriptions[this.subLineForEdit].subscriptionId = u.subscriptions[u.subscriptions.length - 1].id;
      this.editAffiliate.subscriptions[this.subLineForEdit].subscription = _.cloneDeep(u.subscriptions[u.subscriptions.length - 1]);
      this.editAffiliate.subscriptions[this.subLineForEdit].subscription.user = _.cloneDeep(u);
      (this.affiliateForm.controls.subscriptions as any).controls[this.subLineForEdit]
        .controls.subscriptionId.patchValue(this.editAffiliate.subscriptions[this.subLineForEdit].subscriptionId);
    }
    this.newUser = null;
  }

  selectFeeLine(n: number): void {
    if (this.feeLineForEdit !== null) {
      if ((this.affiliateForm.controls.feeSchedules as FormArray).controls[this.feeLineForEdit].valid) {
        this.feeLineForEdit = n;
      } else {
        this.toast.error('Please, set valid data');
      }
    } else {
      this.feeLineForEdit = n;
    }
  }

  trackByFn(item: User): any {
    return item.email;
  }


  private loadPeople(): void {
    this.people$ = concat(
      of(_.chain(this.editAffiliate?.subscriptions).map(x => x.user).unionBy(x => x?.id).value()), // default items
      this.peopleInput$.pipe(
        map(x => _.trim(x)),
        distinctUntilChanged(),
        filter(x => x && x.length > 2),
        debounceTime(1000),
        tap(() => this.peopleLoading = true),
        switchMap(term => {
            return this.us.apiAdminUsersSearchGet(term).pipe(
              catchError(() => of([])), // empty list on error
              tap(() => this.peopleLoading = false)
            );
          }
        ),
        takeUntil(this.componentDestroy())
      ));
  }

  ngOnInit(): void {
    this.blockUI.start();
    this.ps.apiAdminPlanGet()
      .pipe(finalize(this.blockUI.stop), takeUntil(this.componentDestroy()))
      .subscribe((planPagination) => {
        this.plans = planPagination.result;
      });
    if (this.editAffiliate?.id) {
      this.affiliateForm = new FormGroup(
        {
          id: new FormControl(this.editAffiliate?.id),
          isDeleted: new FormControl(this.editAffiliate?.isDeleted),
          code: new FormControl(this.editAffiliate?.code, [Validators.required]),
          companyName: new FormControl(this.editAffiliate?.companyName, [Validators.required]),
          channel: new FormControl(this.editAffiliate?.channel, [Validators.required]),
          feeSchedules: new FormArray([]),
          subscriptions: new FormArray([])
        }
      );
      _.forEach(this.editAffiliate.feeSchedules, (x) => {
        (this.affiliateForm.controls.feeSchedules as FormArray).push(
          new FormGroup(
            {
              id: new FormControl(x.id, [Validators.required]),
              planId: new FormControl(x.planId, [Validators.required]),
              fee: new FormControl(x.fee, [Validators.required])
            }
          )
        );
      });
      _.forEach(this.editAffiliate.subscriptions, (x) => {
        (this.affiliateForm.controls.subscriptions as FormArray).push(
          new FormGroup(
            {
              id: new FormControl(x.id, [Validators.required]),
              userId: new FormControl(x.userId, [Validators.required]),
              subscriptionId: new FormControl(x.subscriptionId, [Validators.required]),
              notes: new FormControl(x.notes)
            }
          )
        );
      });
    } else {
      this.affiliateForm = new FormGroup(
        {
          id: new FormControl(0),
          isDeleted: new FormControl(false),
          code: new FormControl('', [Validators.required]),
          companyName: new FormControl('', [Validators.required]),
          channel: new FormControl('', [Validators.required]),
          feeSchedules: new FormArray([]),
          subscriptions: new FormArray([])
        }
      );
      this.editAffiliate = {
        feeSchedules: [],
        subscriptions: []
      };
    }
    this.loadPeople();
  }

  addNewFee(): void {
    if (this.feeLineForEdit === null) {
      (this.affiliateForm.controls.feeSchedules as FormArray).push(
        new FormGroup(
          {
            id: new FormControl(0),
            planId: new FormControl(null, [Validators.required]),
            fee: new FormControl(0, [Validators.required])
          }
        )
      );
      this.feeLineForEdit = (this.affiliateForm.controls.feeSchedules as FormArray).length - 1;
    } else {
      this.selectFeeLine(null);
    }
  }

  deleteFee(i: number): void {
    (this.affiliateForm.controls.feeSchedules as FormArray).removeAt(i);
    this.feeLineForEdit = null;
  }

  addNewSubscription(): void {
    if (this.subLineForEdit === null) {
      this.subLineForEdit = (this.affiliateForm.controls.subscriptions as FormArray).length;
      (this.affiliateForm.controls.subscriptions as FormArray).push(
        new FormGroup(
          {
            id: new FormControl(0),
            userId: new FormControl(null, [Validators.required]),
            subscriptionId: new FormControl(null, [Validators.required]),
            notes: new FormControl('')
          }
        )
      );
      this.editAffiliate.subscriptions.push({});
    } else {
      this.selectSubLine(null);
    }
  }

  deleteSubscription(i: number): void {
    (this.affiliateForm.controls.subscriptions as FormArray).removeAt(i);
    _.slice(this.editAffiliate.subscriptions, i, 1);
    this.subLineForEdit = null;
  }

  setUser(v: User): void {
    this.newUser = v;
  }

  getPlan(id: FormControl): Plan {
    return _.find(this.plans, (x) => x.id === +id.value);
  }

  cancel(): void {
    this.editFinished.emit(true);
  }

  save(): void {
    if (this.affiliateForm.valid) {
      const aff = this.affiliateForm.value;
      this.blockUI.start();
      this.afService.apiAdminAffiliatesPost(aff)
        .pipe(
          finalize(() => {
            this.blockUI.stop();
            this.editFinished.emit(true);
          }),
          takeUntil(this.componentDestroy()))
        .subscribe(data => {
          this.blockUI.stop();
        });
    } else {
      this.toast.error('Form is not valid.');
    }
  }
}
