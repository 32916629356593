import {Component, OnInit} from '@angular/core';
import {ReportingService} from 'src/app/swagger-codegen';
import {ActiveUsersByPlan} from 'src/app/swagger-codegen/model/activeUsersByPlan';
import {Router} from '@angular/router';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {TakeUntilDestroy} from '../../../services/take-until-destroy.decorator';
import {finalize, takeUntil} from 'rxjs/operators';
import {Observable} from 'rxjs';

@TakeUntilDestroy
@Component({
  selector: 'app-active-users-by-plan',
  templateUrl: './active-users-by-plan.component.html',
  styleUrls: ['./active-users-by-plan.component.scss']
})
export class ActiveUsersByPlanComponent implements OnInit {
  activeUsers: ActiveUsersByPlan[];
  monthYearOptions: string[];
  currentMonthYear: string;
  @BlockUI()
  blockUI: NgBlockUI;
  componentDestroy: () => Observable<boolean>;

  constructor(private reportingService: ReportingService, private router: Router) {
  }

  ngOnInit(): void {
    const currentDate = new Date();
    this.currentMonthYear = (currentDate.getMonth() < 10 ? '0' : '') + (currentDate.getMonth() + 1) + currentDate.getFullYear();
    this.blockUI.start();
    this.reportingService.apiAdminReportingActiveUsersByPlanGet('012020')
      .pipe(
        finalize(() => {
          this.blockUI.stop();
        }),
        takeUntil(this.componentDestroy()))
      .subscribe(response => {
        this.activeUsers = response.activeUsersByPlans;
        this.monthYearOptions = response.monthYearOptions;
      });
    // this.reportingService.apiReportingUserPaymentHistoryGet().subscribe(response => {
    //   console.log(response);
    // });
  }

  onMonthYearChange($event): void {
    this.reportingService.apiAdminReportingActiveUsersByPlanGet($event.target.value)
      .pipe(
        finalize(() => {
          this.blockUI.stop();
        }),
        takeUntil(this.componentDestroy())).subscribe(response => {
      this.activeUsers = response.activeUsersByPlans;
    });
  }

  onRowClick(plan, status, range): void {
    this.router.navigate(['/users'], {queryParams: {plan, status, range}});
  }

}
