import {Component, OnInit} from '@angular/core';
import {UsersService} from '../swagger-codegen/api/users.service';
import {PublicUser} from '../swagger-codegen/model/publicUser';
import {ColumnSortedEvent, SortService} from '../sortable-column/sort-service';
import * as _ from 'lodash';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {TakeUntilDestroy} from '../services/take-until-destroy.decorator';
import {Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, finalize, map, takeUntil} from 'rxjs/operators';
import {Functions} from '../services/functions';
import {AbstractControl, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {NgbActiveModal, NgbCalendar, NgbDateNativeAdapter, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AdminAccountService, PlanAdminService, RegisterCommand, UserDto} from '../swagger-codegen';
import {ToastService} from '../services/toast.service';
import zxcvbn from 'zxcvbn';
import {Router} from '@angular/router';
import {saveAs} from 'file-saver';

@TakeUntilDestroy
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  providers: [NgbDateNativeAdapter]
})
export class UsersComponent implements OnInit {
  @BlockUI()
  blockUI: NgBlockUI;
  previousPage = 1;
  currentPage = 1;
  pageSize = 100;
  users: UserDto[];
  usersSorted: UserDto[];
  usersSortedFiltered: UserDto[];
  sortEvent: ColumnSortedEvent = {sortColumn: 'id', sortDirection: 'asc'};
  componentDestroy: () => Observable<boolean>;
  filterControl = new FormControl();
  colectionSize = 0;
  fileName = 'EquinEdgeUsers.csv';
  currentUser: PublicUser = null;
  planCodes: string[] = [];

  startWithItems: string[] = [];
  hasPurchasedItems: string[] = [];
  withCurrentPlanItems: string[] = [];
  canQualifyForItems: string[] = [];
  bundleFilterItems: string[] = ['AllUsers', 'NoPurchases', 'NoSubscriptions', 'WWUnsubscribed', 'UnlimitedUnsubscribed', 'Downgrades',
    'Upgrades', 'BySubscriptions'];

  startWithFilter = 'All';
  hasPurchasedFilter = 'All';
  withCurrentPlanFilter = 'All';
  canQualifyForFilter = 'All';
  bundleFilter = 'AllUsers';

  accountForm: FormGroup = new FormGroup({
    firstName: new FormControl(null, [Validators.required]),
    lastName: new FormControl(null, [Validators.required]),
    email: new FormControl(
      null,
      [
        Validators.required,
        Validators.pattern(
          '[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]{1,}@.{2,}[.]{1}[a-zA-Z]{2,}'
        )
      ],
      this.checkEmail.bind(this)
    ),
    password: new FormControl(null, [
      Validators.required,
      Validators.minLength(8),
      this.customValidator()
    ]),
    confirmPassword: new FormControl(null, [Validators.required]),
    planCode: new FormControl('DAY', [Validators.required]),
    startDate: new FormControl(this.calendar.getToday(), [Validators.required]),
    accountType: new FormControl('FREE', [Validators.required])
  }, this.passwordMatchValidator);
  isEmailInUseShowModal = false;

  constructor(
    private userService: UsersService,
    private sortService: SortService,
    private toast: ToastService,
    private accountService: AdminAccountService,
    private activeModal: NgbActiveModal,
    private planService: PlanAdminService,
    private calendar: NgbCalendar,
    private ad: NgbDateNativeAdapter,
    private router: Router,
    private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.updateUsersList();
    this.filterControl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        takeUntil(this.componentDestroy())
      ).subscribe(() => {
      this.sort(this.currentPage);
    });
  }

  filter(): void {
    this.sort(this.currentPage);
  }

  updateUsersList(): void {
    this.blockUI.start();
    this.userService.apiAdminUsersGet().pipe(
      finalize(() => {
        this.blockUI.stop();
      }),
      takeUntil(this.componentDestroy())).subscribe((results) => {
      _.map(results.items, (x) => {
        x.subscriptions = _.orderBy(x.subscriptions, (y) => y.status);
      });
      this.users = results.items;
      this.startWithItems = _.chain(this.users).flatMap((x) => x.startedWithPlan).concat(['All']).uniq().sort().value();
      this.withCurrentPlanItems = _.chain(this.users).flatMap((x) => x.withCurrentPlan).concat(['All']).uniq().sort().value();
      this.hasPurchasedItems = _.chain(this.users).flatMap((x) => x.hasPurchasedPlans).concat(['All', 'None']).uniq().sort().value();
      this.canQualifyForItems = _.chain(this.users).flatMap((x) => x.canQualifyFor).flatMap((x) => x?.upgrade).concat(['All']).uniq()
        .sort().value();
      this.sort(1);
    });
  }

  export(): void {
    const header = ['id', 'firstName', 'lastName', 'email', 'startedWithPlan', 'hasPurchasedPlans',
      'withCurrentPlan', 'canQualifyFor', 'authorizeNetId', 'paying'];
    const headerTitles = ['Id', 'First Name', 'Last Name', 'Email', 'StartedWithPlan', 'HasPurchasedPlans',
      'WithCurrentPlan', 'CanQualifyFor', 'Authorize.NET ID', 'Paying?'];
    const users = this.filterAndSortUsers();
    if (users.length) {
      const csv = _.map(users, (row) => header.map(fieldName => {
        if (fieldName === 'paying') {
          return !!Functions.typeSort(row, 'authorizeNetId');
        }
        if (fieldName === 'hasPurchasedPlans') {
          const temp = Functions.typeSort(row, fieldName).join('; ');
          if (temp) {
            return temp;
          } else {
            return 'None';
          }
        }
        if (fieldName === 'canQualifyFor') {
          return Functions.typeSort(row, fieldName).join(';');
        }
        return Functions.typeSort(row, fieldName);
      }).join(','));
      csv.unshift(headerTitles.join(','));
      const csvArray = csv.join('\r\n');
      const blob = new Blob([csvArray], {type: 'text/csv'});
      saveAs(blob, `EquinEdgeUsers${this.filterControl?.value?.trim() ? '-' + this.filterControl?.value?.trim() : ''}.csv`);
    } else {
      this.toast.error('you have no data according to the current filter.');
    }
  }

  sort(newPage: number): void {
    const startItem = (newPage - 1) * this.pageSize;
    this.usersSorted = _.slice(this.filterAndSortUsers(), startItem, startItem + this.pageSize);
  }

  filterAndSortUsers(): PublicUser[] {
    return _.chain(this.users).filter((x) => {
      if (!this.filterControl?.value?.trim() && this.startWithFilter === 'All' && this.hasPurchasedFilter === 'All' &&
        this.withCurrentPlanFilter === 'All' && this.canQualifyForFilter === 'All' && this.bundleFilter === 'AllUsers') {
        return true;
      } else {
        const temp = x.id + ' ' + x.firstName + ' ' + x.lastName + ' ' + x.email + ' ' + x.authorizeNetId;
        let startWithFilterTag = false;
        let hasPurchasedFilterTag = false;
        let withCurrentPlanFilterTag = false;
        let canQualifyForFilterTag = false;
        let bundleFilterTag = false;
        if (this.startWithFilter === 'All' || x.startedWithPlan === this.startWithFilter) {
          startWithFilterTag = true;
        }
        if (this.hasPurchasedFilter === 'All' || x.hasPurchasedPlans.indexOf(this.hasPurchasedFilter) !== -1) {
          hasPurchasedFilterTag = true;
        }
        if (this.hasPurchasedFilter === 'None' && (!x.hasPurchasedPlans || !x.hasPurchasedPlans.length)) {
          hasPurchasedFilterTag = true;
        }
        if (this.withCurrentPlanFilter === 'All' || x.withCurrentPlan === this.withCurrentPlanFilter) {
          withCurrentPlanFilterTag = true;
        }
        if (this.bundleFilter === 'AllUsers' ||
          (this.bundleFilter === 'NoPurchases' && x.isNoPurchases) ||
          (this.bundleFilter === 'NoSubscriptions' && x.isNoSubscription) ||
          (this.bundleFilter === 'WWUnsubscribed' && x.isWWUnsubscribed) ||
          (this.bundleFilter === 'UnlimitedUnsubscribed' && x.isUnlimitedUnsubscribed) ||
          (this.bundleFilter === 'Downgrades' && x.isDowngrades) ||
          (this.bundleFilter === 'Upgrades' && x.isUpgrades) ||
          (this.bundleFilter === 'BySubscriptions' && x.isSubscriptions)) {
          bundleFilterTag = true;
        }
        if (this.canQualifyForFilter === 'All' ||
          _.filter(x.canQualifyFor, (y) => y.canQualify && y.upgrade === this.canQualifyForFilter).length > 1) {
          canQualifyForFilterTag = true;
        }
        return startWithFilterTag && hasPurchasedFilterTag && withCurrentPlanFilterTag && canQualifyForFilterTag && bundleFilterTag &&
          _.toLower(temp).indexOf(_.toLower(this.filterControl?.value?.trim())) !== -1;
      }
    }).tap((x) => {
      this.colectionSize = x?.length;
    }).orderBy((x) => Functions.typeSort(x, this.sortEvent.sortColumn),
      this.sortEvent.sortDirection).value();
  }

  public onSorted(event: ColumnSortedEvent): void {
    this.sortEvent = event;
    this.sortByEvent();
  }

  sortByEvent(currentPage: number = 1): void {
    if (this.sortEvent && this.users) {
      this.usersSorted = this.usersSortedFiltered = _.chain(this.usersSortedFiltered)
        .orderBy([this.sortEvent.sortColumn], [this.sortEvent.sortDirection])
        .value();
      this.sortService.lastSortEvent = this.sortEvent;
    } else if (
      !this.sortEvent &&
      this.users &&
      this.sortService.lastSortEvent
    ) {
      this.sortEvent = this.sortService.lastSortEvent;
      this.sortByEvent();
    }
    this.currentPage = currentPage;
    this.sort(this.currentPage);
  }

  pageChanged(newPage: number): void {
    if (newPage !== this.previousPage) {
      this.previousPage = newPage;
      this.sort(newPage);
    }
  }

  public trackItem(index: number, item: any): void {
    return item.trackId;
  }

  open(content): void {
    this.planService.apiAdminPlanGet()
      .pipe(takeUntil(this.componentDestroy()))
      .subscribe((plans) => {
        this.planCodes = _.chain(plans.result).map((x) => x.code).sort().value();
      });
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'md'}).result
      .then((result) => {
        this.clear();
      }, () => {
        this.clear();
      });
  }

  clear(): void {
    this.clearControl('firstName', '');
    this.clearControl('lastName', '');
    this.clearControl('email', '');
    this.clearControl('password', '');
    this.clearControl('confirmPassword', '');
    this.clearControl('planCode', 'DAY');
    this.clearControl('startDate', this.calendar.getToday());
  }

  clearControl(name: string, value: any): void {
    this.accountForm.controls[name].patchValue(value);
    this.accountForm.controls[name].markAsPristine();
    this.accountForm.controls[name].markAsUntouched();
  }

  customValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value) {
        const test = zxcvbn(control.value) as {
          feedback: { warning: string; suggestions: string[] };
          score: number;
        };
        if (test.feedback.warning) {
          let error = '';
          error += test.feedback.warning + '.';
          _.forEach(test.feedback.suggestions, result => {
            error += ' ' + result;
          });
          return {customValidator: error};
        } else {
          return null;
        }
      } else {
        return null;
      }
    };
  }

  passwordMatchValidator(g: FormGroup): any {
    if (g.get('password').value !== g.get('confirmPassword').value) {
      g.get('confirmPassword').setErrors({mismatch: true});
    }
    return null;
  }

  checkEmail(control: FormControl): Promise<any> | Observable<any> {
    const checkEmails = this.accountService.apiAdminAccountIsEmailInUseGet(control.value)
      .pipe(map(result => {
          if (result && !result.isEmailInUse) {
            this.isEmailInUseShowModal = false;
            return null;
          }
          this.isEmailInUseShowModal = true;
          return {isEmailInUse: true};
        }),
        takeUntil(this.componentDestroy()));
    return checkEmails;
  }

  clearEmail(): void {
    this.accountForm.controls.email.patchValue('');
    this.accountForm.controls.email.markAsPristine();
    this.accountForm.controls.email.markAsUntouched();
    this.isEmailInUseShowModal = false;
  }

  isValid(controlName: string): boolean {
    return (this.accountForm.controls[controlName].valid && (this.accountForm.controls[controlName].dirty ||
        this.accountForm.controls[controlName].touched)
    );
  }

  isInValid(controlName: string): boolean {
    return (this.accountForm.controls[controlName].invalid && (this.accountForm.controls[controlName].dirty ||
        this.accountForm.controls[controlName].touched)
    );
  }

  validateForm(): void {
    if (this.accountForm.invalid) {
      this.accountForm.controls.firstName.markAsTouched();
      this.accountForm.controls.lastName.markAsTouched();
      this.accountForm.controls.email.markAsTouched();
      this.accountForm.controls.password.markAsTouched();
      this.accountForm.controls.confirmPassword.markAsTouched();
      this.accountForm.controls.planCode.markAsTouched();
      this.accountForm.controls.startDate.markAsTouched();
    } else {
      const r: RegisterCommand = {
        firstName: this.accountForm.controls.firstName.value,
        lastName: this.accountForm.controls.lastName.value,
        email: this.accountForm.controls.email.value,
        password: this.accountForm.controls.password.value,
        planCode: this.accountForm.controls.planCode.value,
        startDate: this.ad.toModel(this.accountForm.controls.startDate.value).toISOString(),
        deviceId: Functions.getDeviceId(),
        emailVerificationCode: null
      };
      this.blockUI.start();
      this.accountService.apiAdminAccountRegisterPost(r).pipe(finalize(() => {
          this.blockUI.stop();
        }),
        takeUntil(this.componentDestroy())).subscribe((result) => {
          this.router.navigate(['/users/' + result.user.id]).then();
      });
      this.modalService.dismissAll();
    }
  }
}
