<ng-container *ngIf="promoCodeForm&&editPromoCode">
  <form class="container-fluid" [formGroup]="promoCodeForm">
    <div class="card-header text-center">
      <ng-container *ngIf="!editPromoCode?.id">
        <h1>
          Create New PromoCode
        </h1>
      </ng-container>
      <ng-container *ngIf="editPromoCode?.id">
        <h1>Edit PromoCode Id: {{ editPromoCode?.id }}</h1>
      </ng-container>
    </div>
    <div class="text-center mt-3">
      <div class="w-50 d-inline-block">
        <div class="mb-2 row">
          <label for="name" class="col-sm-6 col-form-label">name</label>
          <div class="col-sm-6">
            <input type="text" class="form-control" id="name" formControlName="name">
          </div>
        </div>
        <div class="mb-2 row">
          <label for="description" class="col-sm-6 col-form-label">description</label>
          <div class="col-sm-6">
            <textarea name="description" class="form-control" id="description" cols="30" rows="3"
                      formControlName="description"></textarea>
          </div>
        </div>
        <div class="mb-2 row">
          <label for="code" class="col-sm-6 col-form-label">code</label>
          <div class="col-sm-6">
            <input type="text" class="form-control" id="code" formControlName="code">
          </div>
        </div>
        <div class="mb-2 row">
          <label for="startDate" class="col-sm-6 col-form-label">startDate</label>
          <div class="col-sm-6 pt-2">
            <div>
              <ng-container *ngIf="promoCodeForm?.controls?.startDate.valid">
                {{promoCodeForm?.value?.startDate | date :'short'}}
              </ng-container>
            </div>
            <input type="text" class="form-control" id="startDate" formControlName="startDate">
          </div>
        </div>
        <div class="mb-2 row">
          <label for="endDate" class="col-sm-6 col-form-label">endDate</label>
          <div class="col-sm-6">
            <div>
              <ng-container *ngIf="promoCodeForm?.controls?.endDate.valid">
                {{promoCodeForm?.value?.endDate | date :'short'}}
              </ng-container>
            </div>
            <input type="text" class="form-control" id="endDate" formControlName="endDate">
          </div>
        </div>
        <div class="mb-2 row">
          <label for="setPriceTo" class="col-sm-6 col-form-label">setPriceTo</label>
          <div class="col-sm-6">
            <input type="number" class="form-control" id="setPriceTo" formControlName="setPriceTo">
          </div>
        </div>
        <div class="mb-2 row">
          <label for="maxRedemptions" class="col-sm-6 col-form-label">maxRedemptions</label>
          <div class="col-sm-6">
            <input type="number" class="form-control" id="maxRedemptions" formControlName="maxRedemptions">
          </div>
        </div>
        <div class="mb-2 row">
          <label for="maxRedemptionsPerUser" class="col-sm-6 col-form-label">maxRedemptionsPerUser</label>
          <div class="col-sm-6">
            <input type="number" class="form-control" id="maxRedemptionsPerUser"
                   formControlName="maxRedemptionsPerUser">
          </div>
        </div>
        <div class="mb-2 row">
          <label for="isDeleted" class="col-sm-6  col-form-label">IsDeleted</label>
          <div class="col-sm-6">
            <input type="checkbox" class="form-control" id="isDeleted" formControlName="isDeleted">
          </div>
        </div>
        <ng-container *ngIf="allMarketingChannels?.length">
          <div class="mb-2">
            <h4>
              Marketing Channels:
            </h4>
          </div>
          <ng-container *ngFor="let item of promoCodeForm.controls?.channels?.controls; index as i ">
            <div class="mb-2 row" [formGroup]="item">
              <label [for]="item?.controls?.name?.code" class="col-sm-6  col-form-label">{{item?.controls?.name?.value}} ({{item?.controls?.code?.value}})</label>
              <div class="col-sm-6">
                <input type="checkbox" class="form-control" [id]="item?.controls?.name?.code" formControlName="select">
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
      <div>
        <div class="mb-2">
          <h4>
            PromoCodeClaims:
          </h4>
        </div>
        <ng-container *ngIf="promoCodeForm?.controls?.promoCodeClaims">
          <table class="table">
            <thead>
            <tr>
              <th>
                #
              </th>
              <th>
                claimType
              </th>
              <th>
                claimValue
              </th>
              <th>
                claimInt
              </th>
              <th>
                claimDecimal
              </th>
              <th>
                isDeleted
              </th>
              <th>
                action
              </th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let item of promoCodeForm.controls?.promoCodeClaims?.controls; index as i ">
              <ng-container *ngIf="codeClaimsLineForEdit!==i">
                <tr>
                  <td>
                    {{i + 1}}
                  </td>
                  <td>
                    {{item?.controls?.claimType?.value}}
                  </td>
                  <td>
                    {{item?.controls?.claimValue.value}}
                  </td>
                  <td>
                    {{item?.controls?.claimInt.value}}
                  </td>
                  <td>
                    {{item?.controls?.claimDecimal.value}}
                  </td>
                  <td [ngClass]="{'text-danger':item?.controls?.isDeleted.value}">
                    {{item?.controls?.isDeleted.value}}
                  </td>
                  <td>
                    <button class="btn btn-success mr-2" (click)="selectClaim(i)">Edit</button>
                    <button class="btn btn-info" (click)="deleteClaim(i)">Delete</button>
                  </td>
                </tr>
              </ng-container>
              <ng-container *ngIf="codeClaimsLineForEdit===i">
                <tr [formGroup]="item">
                  <td>
                    {{i + 1}}
                  </td>
                  <td>
                    <select class="form-control" formControlName="claimType">
                      <ng-container *ngFor="let t of claimTypes">
                        <option [value]="t">{{t}}</option>
                      </ng-container>
                    </select>
                  </td>
                  <td>
                    <input type="text" class="form-control" formControlName="claimValue">
                  </td>
                  <td>
                    <input type="number" class="form-control" formControlName="claimInt">
                  </td>
                  <td>
                    <input type="number" class="form-control" formControlName="claimDecimal">
                  </td>
                  <td [ngClass]="{'text-danger':item?.isDeleted}">
                    <input type="checkbox" class="form-control" formControlName="isDeleted">
                  </td>
                  <td>
                    <button class="btn btn-success mr-2" (click)="selectClaim(null)">Save</button>
                    <button class="btn btn-info" (click)="deleteClaim(i)">Delete</button>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
            <tr>
              <td colspan="99" class="ml-3 text-left">
                <button class="btn btn-success" (click)="addNewClaim()">
                  Add New PromoCodeClaims
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </ng-container>
      </div>
    </div>
    <div class="text-center py-3">
      <button *ngIf="editPromoCode" type="button" class="toggleButton btn btn-secondary mr-3" (click)="cancel()">
        Cancel
      </button>
      <button type="button" class="toggleButton btn btn-primary" (click)="save()">
        Save
      </button>
    </div>
  </form>
  <ng-container *ngIf="editPromoCode?.redemptions">
    <table class="table mt-5">
      <thead>
      <tr>
        <th colspan="99">
          <h4 class="text-center"> Redemptions:</h4>
        </th>
      </tr>
      <tr>
        <th>
          #
        </th>
        <th>
          User email
        </th>
        <th>
          PlanCode
        </th>
        <th>
          Status
        </th>
        <th>
          time
        </th>
        <th>
          email
        </th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngFor="let red of editPromoCode.redemptions; index as i">
        <tr>
          <th>
            {{i + 1}}
          </th>
          <th>
            {{red?.subscription?.user?.email}}
          </th>
          <th>
            {{red?.subscription?.planCode}}
          </th>
          <th>
            {{red?.subscription?.status}}
          </th>
          <th>
            {{red?.time | date: 'short'}}
          </th>
          <th>
            {{red?.email}}
          </th>
        </tr>
      </ng-container>
      </tbody>
    </table>
  </ng-container>

</ng-container>


