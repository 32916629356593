import {Component, OnInit} from '@angular/core';
import {TakeUntilDestroy} from '../services/take-until-destroy.decorator';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {Observable} from 'rxjs';
import {CourseDiagram, CourseDiagramsService} from '../swagger-codegen';
import {ColumnSortedEvent, SortService} from '../sortable-column/sort-service';
import _ from 'lodash';

@TakeUntilDestroy
@Component({
  selector: 'app-course-diagrams',
  templateUrl: './course-diagrams.component.html',
  styleUrls: ['./course-diagrams.component.scss']
})
export class CourseDiagramsComponent implements OnInit {
  @BlockUI()
  blockUI: NgBlockUI;
  componentDestroy: () => Observable<boolean>;
  list: CourseDiagram[];
  sortEvent: ColumnSortedEvent = {sortColumn: 'Id', sortDirection: 'asc'};
  selected: CourseDiagram;

  constructor(private cdService: CourseDiagramsService, private sortService: SortService) {
  }

  ngOnInit(): void {
    this.cdService.apiAdminCourseDiagramsAllGet().subscribe((result) => {
      this.list = result;
    });
    this.sortEvent = {sortColumn: 'Id', sortDirection: 'asc'};
    this.sortByEvent();
  }

  public onSorted(event: ColumnSortedEvent): void {
    this.sortEvent = event;
    this.sortByEvent();
  }

  sortByEvent(): void {
    if (this.sortEvent) {
      this.list = _.orderBy(this.list, [this.sortEvent.sortColumn], [this.sortEvent.sortDirection]);
      this.sortService.lastSortEvent = this.sortEvent;
    } else if (
      !this.sortEvent &&
      this.sortService.lastSortEvent
    ) {
      this.sortEvent = this.sortService.lastSortEvent;
      this.sortByEvent();
    }
  }

  editItem(e: CourseDiagram): void {
    this.selected = e;
    if (!e) {
      this.sortByEvent();
    }
  }

  finishedEdit(e: CourseDiagram[]): void {
    this.selected = null;
    if (e) {
      this.list = e;
    }
  }
}
