import {AbstractControlOptions, FormControl, ValidatorFn} from '@angular/forms';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import _ from 'lodash';

export class Functions {
  constructor() {
  }

  public static isSafari(): boolean {
    try {
      const isSafari = !!navigator.userAgent.match(/safari/i) && !navigator.userAgent.match(/chrome/i) &&
        typeof document.body.style.webkitFilter !== 'undefined';
      return isSafari;
    } catch (e) {
    }
    return false;
  }

  public static checkInternalValue(x: any, sortColumn: string): any {
    if (x === undefined || x === null) {
      return '';
    }
    const sb = sortColumn.indexOf('.');
    if (sb !== -1) {
      if (x[sortColumn.substr(0, sb)] === undefined || x[sortColumn.substr(0, sb)] === null) {
        return '';
      }
      if (sortColumn.substr(sb + 1).indexOf('.') === -1) {
        if (sortColumn.substr(0, sb) === '0' && x[sortColumn.substr(0, sb)]
          && x[sortColumn.substr(0, sb)].length === 0) {
          return '';
        }
        return x[sortColumn.substr(0, sb)][sortColumn.substr(sb + 1)];
      } else {
        return this.checkInternalValue(x[sortColumn.substr(0, sb)], sortColumn.substr(sb + 1));
      }
    } else {
      return x[sortColumn];
    }
  }

  public static typeSort(x: any, sortColumn: string): any {
    const prop = this.checkInternalValue(x, sortColumn);
    switch ((typeof prop)) {
      case 'number':
        return prop === null || prop === undefined ? 0 : prop;
      case 'string':
        return prop === null || prop === undefined ? '' : prop;
      case 'object':
        return prop === null || prop === undefined ? '' : prop;
      case 'undefined':
        return prop === null || prop === undefined ? '' : prop;
      default:
        return prop;
    }
  }

  public static dateValidator(control: FormControl): Promise<any> | Observable<any> {
    return of(control.value).pipe(
      map((x) => {
        if (!_.trim(x) || new Date(x).toISOString()) {
          return null;
        } else {
          return {dateIsNotValid: true};
        }
      }),
      catchError(() => {
        return of({dateIsNotValid: true});
      })
    );
  }

  public static createUuidv4 = (): string =>
    'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      // tslint:disable-next-line:no-bitwise
      const r = Math.random() * 16 | 0;
      // tslint:disable-next-line:no-bitwise
      const v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    })

  public static getDeviceId = (): string => {
    let deviceId = localStorage.getItem('deviceId');
    if (_.isEmpty(deviceId)) {
      deviceId = Functions.createUuidv4();
    }
    return deviceId;
  }
}

export type FormGroupConfig<T> = {
  [P in keyof T]: [
    T[P] | { value: T[P]; disabled: boolean },
    (AbstractControlOptions | ValidatorFn | ValidatorFn[])?
  ];
};
