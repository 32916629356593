import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AdminService } from './api/admin.service';
import { AdminAccountService } from './api/adminAccount.service';
import { AffiliatesService } from './api/affiliates.service';
import { CouponAdminService } from './api/couponAdmin.service';
import { CourseDiagramsService } from './api/courseDiagrams.service';
import { FeatureAdminService } from './api/featureAdmin.service';
import { FinanceService } from './api/finance.service';
import { LogsService } from './api/logs.service';
import { MarketingChannelsService } from './api/marketingChannels.service';
import { MeetAdminService } from './api/meetAdmin.service';
import { PlanAdminService } from './api/planAdmin.service';
import { PromoCodesService } from './api/promoCodes.service';
import { RaceWagerTypeService } from './api/raceWagerType.service';
import { ReportingService } from './api/reporting.service';
import { ToteEventsService } from './api/toteEvents.service';
import { TrackDiagramsService } from './api/trackDiagrams.service';
import { TracksService } from './api/tracks.service';
import { UserAdminCommandService } from './api/userAdminCommand.service';
import { UsersService } from './api/users.service';
import { WagerTypeTextsService } from './api/wagerTypeTexts.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AdminService,
    AdminAccountService,
    AffiliatesService,
    CouponAdminService,
    CourseDiagramsService,
    FeatureAdminService,
    FinanceService,
    LogsService,
    MarketingChannelsService,
    MeetAdminService,
    PlanAdminService,
    PromoCodesService,
    RaceWagerTypeService,
    ReportingService,
    ToteEventsService,
    TrackDiagramsService,
    TracksService,
    UserAdminCommandService,
    UsersService,
    WagerTypeTextsService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
