<ng-template #refunded>refunded.</ng-template>
<div class="container-fluid">
  <div class="user-header">
    <h2 class="user-title text-center position-relative">
      <ng-container *ngIf="userForm?.controls?.id?.value">
        User update № {{userForm?.controls?.id?.value}}
      </ng-container>
      <ng-container *ngIf="!userForm?.controls?.id?.value">
        Create new User
      </ng-container>
      <button class="btn btn-outline-dark position-absolute right-0" routerLink="/users">
        Back to users list
      </button>
    </h2>
  </div>
  <div class="user-body">
    <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
      <div class="form-row">
        <input type="hidden" formControlName="id">
        <div class="form-group col">
          <label for="firstName">First Name</label>
          <div class="input-group">
            <input id="firstName" class="form-control  form-control-sm" type="text" formControlName="firstName"
                   [ngClass]="{'is-invalid':checkError('firstName'),'is-valid':checkValid('firstName')}">
          </div>
        </div>
        <div class="form-group col">
          <label for="lastName">Last Name</label>
          <div class="input-group">
            <input id="lastName" class="form-control  form-control-sm" type="text" formControlName="lastName"
                   [ngClass]="{'is-invalid':checkError('lastName'),'is-valid':checkValid('lastName')}">
          </div>
        </div>
        <div class="form-group col">
          <label for="email">Email</label>
          <div class="input-group">
            <input id="email" class="form-control  form-control-sm" type="email" formControlName="email"
                   [ngClass]="{'is-invalid':checkError('email'),'is-valid':checkValid('email')}">
          </div>
        </div>
        <div class="form-group col">
          <label for="isActive">isActive</label>
          <div class="input-group">
            <input id="isActive" class="form-control  form-control-sm" type="checkbox" formControlName="isActive"
                   [ngClass]="{'is-invalid':checkError('isActive'),'is-valid':checkValid('isActive')}">
          </div>
        </div>
        <div class="form-group col">
          <label for="authorizeNetId">authorizeNetId</label>
          <div class="input-group">
            <input id="authorizeNetId" class="form-control  form-control-sm" type="text"
                   formControlName="authorizeNetId"
                   [ngClass]="{'is-invalid':checkError('authorizeNetId'),'is-valid':checkValid('authorizeNetId')}">
          </div>
        </div>
      </div>
      <div class="form-row mb-4">
        <div class="col-1 pt-2 font-weight-bold">Actions:
        </div>
        <div class="col-2 pt-2" [ngClass]="{'text-danger':currentUser?.isBlocked}">
          <ng-container *ngIf="!currentUser?.isBlocked">
            User is not blocked.
          </ng-container>
          <ng-container *ngIf="currentUser?.isBlocked">
            User is blocked.
          </ng-container>
        </div>
        <div class="col-1 text-left">
          <ng-container *ngIf="!currentUser?.isBlocked">
            <button class="btn btn-danger" (click)="blockUser()">Block User</button>
          </ng-container>
          <ng-container *ngIf="currentUser?.isBlocked">
            <button class="btn btn-success" (click)="unBlockUser()">UnBlock User</button>
          </ng-container>
        </div>
        <div class="col">
          <button class="btn btn-success" (click)="open(content)">Add User Credit</button>
        </div>
      </div>
      <div class="form-group">
        <!--        <button type="button" class="btn btn-outline-success btn-sm m-2" (click)="addUserSubscription()">Add-->
        <!--          Subscription-->
        <!--        </button>-->
        <table class="table table-sm">
          <thead>
          <tr>
            <th colspan="8" class="text-center">
              <b class="font-weight-bold">Subscriptions</b>
            </th>
          </tr>
          <tr>
            <th>Id</th>
            <th>Name</th>
            <th>Status</th>
            <th>SubscriptionCreated</th>
            <th>PeriodStarted</th>
            <th>PeriodEnds</th>
            <th>Reason</th>
            <th>edit</th>
            <th>remove</th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let item of currentUser?.subscriptions; let i=index;">
            <tr>
              <td class="width-60">
                {{item?.id}}
              </td>
              <td class="width-230">
                {{item?.name}}
              </td>
              <td class="width-100">
                {{item?.status}}
              </td>
              <td>
                {{item?.subscriptionCreated | date:'MM/dd/yy'}}
              </td>
              <td>
                {{item?.currentPeriodStarted | date:'MM/dd/yy'}}
              </td>
              <td>
                {{item?.currentPeriodEnds | date:'MM/dd/yy'}}
              </td>
              <td>
                {{item?.cancellationReason}}
              </td>
              <td>
                <button type="button" class="btn btn-link" (click)="openSubscription(item)">edit
                </button>
              </td>
              <td>
                <button type="button" class="btn btn-link" (click)="removeSubscription(item?.id)">x
                </button>
              </td>
            </tr>
          </ng-container>
          </tbody>
        </table>
        <table class="table table-sm" *ngIf="userPaymentHistory&&userPaymentHistory.length!==0">
          <thead>
          <tr>
            <th colspan="9" class="text-center">
              <b class="font-weight-bold">Payment History</b>
            </th>
          </tr>
          <tr>
            <th>id</th>
            <th>submitTime</th>
            <th>amount</th>
            <th>status</th>
            <th>transactionId</th>
            <th>customerPaymentProfileId</th>
            <th>refundReferenceTransactionId</th>
            <th>userId</th>
            <th>user</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let item of userPaymentHistory">
            <td>{{item?.id}}</td>
            <td>{{item?.submitTime | date : 'MM/dd/yy'}}</td>
            <td>{{item?.amount}}</td>
            <td>{{item?.status}}</td>
            <td>{{item?.transactionId}}
            </td>
            <td>{{item?.customerPaymentProfileId}}</td>
            <td>
              <ng-container *ngIf="item?.status === 'settledSuccessfully'">
                <ng-container *ngIf="!checkRefunded(item); else refunded">
                  <button type="button" class="btn btn-link" (click)="refund(item?.transactionId)">refund
                  </button>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="item?.status !== 'settledSuccessfully'">
                {{item?.refundReferenceTransactionId}}
              </ng-container>
            </td>
            <td>{{item?.userId}}</td>
            <td>{{item?.user}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </form>
    <div class="text-center">
      <button class="btn" (click)="onSubmit()"
              [ngClass]="{'btn-outline-dark':userForm.invalid,'btn-outline-success':userForm.valid }">Save
      </button>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">User credit</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="addCreditForm" (keydown.enter)="addCreditToUser()" (ngSubmit)="addCreditToUser()"
          autocomplete="off">
      <div class="form-row pb-2">
        <div class="col">
          <label for="amount">Amount</label>
        </div>
        <div class="col">
          <input type="number" class="form-control" id="amount" placeholder="amount" name="amount"
                 formControlName="amount"
                 autocomplete="off"
                 [ngClass]="{'is-valid':isValid('amount'), 'is-invalid':isInValid('amount')}">
        </div>
      </div>
      <div class="form-row pb-2">
        <div class="col">
          <label for="type">Type of credit</label>
        </div>
        <div class="col">
          <select class="form-control" id="type" formControlName="type"
                  [ngClass]="{'is-valid':isValid('type'), 'is-invalid':isInValid('type')}">
            <ng-container *ngFor="let item of userCreditTypes">
              <option [value]="item">{{item}}</option>
            </ng-container>
          </select>
        </div>
      </div>
      <div class="form-row pb-2">
        <div class="col">
          <label for="reason">Reason for credit</label>
        </div>
        <div class="col">
          <input type="text" class="form-control" id="reason" placeholder="reason" name="reason"
                 formControlName="reason"
                 autocomplete="off"
                 [ngClass]="{'is-valid':isValid('reason'), 'is-invalid':isInValid('reason')}">
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn  btn-success" (click)="addCreditToUser()"
            [ngClass]="{'disabled':!addCreditForm.valid}">Save
    </button>
  </div>
</ng-template>
