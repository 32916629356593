import {Component, OnInit} from '@angular/core';
import {MarketingChannel, MarketingChannelsService} from '../swagger-codegen';
import {TakeUntilDestroy} from '../services/take-until-destroy.decorator';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {Observable} from 'rxjs';
import {finalize, takeUntil} from 'rxjs/operators';

@TakeUntilDestroy
@Component({
  selector: 'app-marketing-channels',
  templateUrl: './marketing-channels.component.html',
  styleUrls: ['./marketing-channels.component.scss']
})
export class MarketingChannelsComponent implements OnInit {
  @BlockUI()
  blockUI: NgBlockUI;
  selected: MarketingChannel;
  componentDestroy: () => Observable<boolean>;
  all: MarketingChannel[];

  constructor(private mService: MarketingChannelsService) {
  }

  ngOnInit(): void {
    this.getAll();
  }

  getAll(): void {
    this.blockUI.start();
    this.mService.apiAdminMarketingChannelsAllGet().pipe(finalize(this.blockUI.stop), takeUntil(this.componentDestroy()))
      .subscribe((result) => {
        this.all = result;
      });
  }

  editItem(e: MarketingChannel): void {
    this.selected = e;
    if (!e) {
      this.getAll();
    }
  }
}
