import {Component, OnInit} from '@angular/core';
import {PromoCode, PromoCodeRedemptionsModel, PromoCodeRedemptionsResult, UsersService} from '../swagger-codegen';
import {TakeUntilDestroy} from '../services/take-until-destroy.decorator';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {Observable} from 'rxjs';
import {ColumnSortedEvent, SortService} from '../sortable-column/sort-service';
import {finalize, takeUntil} from 'rxjs/operators';
import _ from 'lodash';
import {Functions} from '../services/functions';
import {saveAs} from 'file-saver';
import {ToastService} from '../services/toast.service';

@TakeUntilDestroy
@Component({
  selector: 'app-promo-code-redemptions',
  templateUrl: './promo-code-redemptions.component.html',
  styleUrls: ['./promo-code-redemptions.component.scss']
})
export class PromoCodeRedemptionsComponent implements OnInit {
  @BlockUI()
  blockUI: NgBlockUI;
  selected: PromoCode;
  componentDestroy: () => Observable<boolean>;
  public current: PromoCodeRedemptionsResult;
  sortEvent: ColumnSortedEvent = {sortColumn: 'Id', sortDirection: 'asc'};
  pageSize = 1000;
  previousPage = 1;
  currentPage = 1;
  totalPages = 0;
  sorted: PromoCodeRedemptionsModel[] = [];
  colectionSize = 0;

  constructor(private us: UsersService, private sortService: SortService, private toast: ToastService) {
  }

  ngOnInit(): void {
    this.blockUI.start();
    this.us.apiAdminUsersPromoCodeRedemptionsGet().pipe(finalize(this.blockUI.stop), takeUntil(this.componentDestroy()))
      .subscribe((result) => {
        this.current = result;
        this.colectionSize = result.count;
        this.sortEvent = {sortColumn: 'Id', sortDirection: 'asc'};
        this.sortByEvent();
      });
  }

  pageChanged(newPage: number): void {
    if (newPage !== this.previousPage) {
      this.previousPage = newPage;
      this.sortByEvent(newPage);
    }
  }


  public onSorted(event: ColumnSortedEvent): void {
    this.sortEvent = event;
    this.sortByEvent();
  }

  sortByEvent(currentPage: number = 1): void {
    if (this.sortEvent) {
      const startItem = (currentPage - 1) * this.pageSize;
      this.sorted = _.chain(this.current.redemptions)
        .orderBy(this.sortEvent.sortColumn, this.sortEvent.sortDirection)
        .slice(startItem, startItem + this.pageSize).value();
      this.sortService.lastSortEvent = this.sortEvent;
    } else if (
      !this.sortEvent &&
      this.sortService.lastSortEvent
    ) {
      this.sortEvent = this.sortService.lastSortEvent;
      this.sortByEvent();
    }
  }

  export(): void {
    const header = ['firstName', 'lastName', 'email', 'promoCode'];
    const headerTitles = ['First Name', 'Last Name', 'Email', 'PromoCode'];
    if (this.current.redemptions.length) {
      const csv = _.map(_.orderBy(this.current.redemptions, this.sortEvent.sortColumn, this.sortEvent.sortDirection),
        (row) => header.map(fieldName => {
          return Functions.typeSort(row, fieldName);
        }).join(','));
      csv.unshift(headerTitles.join(','));
      const csvArray = csv.join('\r\n');
      const blob = new Blob([csvArray], {type: 'text/csv'});
      saveAs(blob, `EquinEdgePromoCodeRedemptions.csv`);
    } else {
      this.toast.error('you do not have any data.');
    }
  }
}
