import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {LoginService} from '../services/login.service';
import {NgbModal, NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [NgbModalConfig, NgbModal]
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  isRemeberMe = false;

  resetEmail: string;

  constructor(public authService: LoginService, config: NgbModalConfig, private modalService: NgbModal) {
    // customize default values of modals used by this component tree
    config.backdrop = 'static';
    config.keyboard = false;
  }

  open(content): void {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      if (result.valid) {
        this.authService.changePassword(this.resetEmail);
        this.resetEmail = '';
      }
    }, () => {
      this.resetEmail = '';
    });
  }

  ngOnInit(): void {
    this.loginForm = new FormGroup({
      email: new FormControl('', {
        validators: [Validators.required, Validators.email]
      }),
      password: new FormControl('', {validators: [Validators.required]})
    });
  }

  onSubmit(): void {
    // rememberMe = localStorage.getItem("remeber_me");
    this.authService.customLogin(
      this.loginForm.value.email,
      this.loginForm.value.password
    );
  }

  onRememberMe(): void {
    this.isRemeberMe = !this.isRemeberMe;
    if (!this.isRemeberMe) {
      localStorage.removeItem('remeber_me');
    } else {
      localStorage.setItem('remeber_me', 'true');
    }
  }
}
