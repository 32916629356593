import {Component, OnInit} from '@angular/core';
import {TakeUntilDestroy} from '../services/take-until-destroy.decorator';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {PromoCode, PromoCodePagination, PromoCodesService} from '../swagger-codegen';
import {Observable} from 'rxjs';
import {ColumnSortedEvent, SortService} from '../sortable-column/sort-service';
import {finalize, takeUntil} from 'rxjs/operators';

@TakeUntilDestroy
@Component({
  selector: 'app-promo-codes',
  templateUrl: './promo-codes.component.html',
  styleUrls: ['./promo-codes.component.scss']
})
export class PromoCodesComponent implements OnInit {
  @BlockUI()
  blockUI: NgBlockUI;
  selected: PromoCode;
  componentDestroy: () => Observable<boolean>;
  public currentPromoCodes: PromoCodePagination;
  sortEvent: ColumnSortedEvent = {sortColumn: 'Id', sortDirection: 'asc'};
  pageSize = 100;
  previousPage = 1;
  currentPage = 1;
  totalPages = 0;

  constructor(private pc: PromoCodesService, private sortService: SortService) {
  }

  ngOnInit(): void {
    this.sortEvent = {sortColumn: 'Id', sortDirection: 'asc'};
    this.sortByEvent();
  }

  public onSorted(event: ColumnSortedEvent): void {
    this.sortEvent = event;
    this.sortByEvent();
  }

  sortByEvent(currentPage: number = 1): void {
    if (this.sortEvent) {
      this.blockUI.start();
      this.pc.apiAdminPromoCodesAllGet(this.sortEvent.sortColumn,
        this.sortEvent.sortDirection === 'desc', currentPage, this.pageSize)
        .pipe(finalize(this.blockUI.stop), takeUntil(this.componentDestroy()))
        .subscribe((result) => {
          // console.log(result);
          this.currentPromoCodes = result;
          this.currentPage = result.currentPage;
          this.pageSize = result.pageSize;
          this.totalPages = result.totalPages;
          this.sortService.lastSortEvent = this.sortEvent;
        });
    } else if (
      !this.sortEvent &&
      this.sortService.lastSortEvent
    ) {
      this.sortEvent = this.sortService.lastSortEvent;
      this.sortByEvent();
    }
  }

  editItem(e: PromoCode): void {
    this.selected = e;
    if (!e) {
      this.sortByEvent();
    }
  }

  pageChanged(newPage: number): void {
    if (newPage !== this.previousPage) {
      this.previousPage = newPage;
      this.sortByEvent(newPage);
    }
  }

}
