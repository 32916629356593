/**
 * Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import {  HttpHeaders, HttpParams,
          HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CancelSubscriptionImmediatelyArguments } from '../model/cancelSubscriptionImmediatelyArguments';
import { CurrentMonthlyMembersResult } from '../model/currentMonthlyMembersResult';
import { PromoCodeRedemptionsResult } from '../model/promoCodeRedemptionsResult';
import { PublicUser } from '../model/publicUser';
import { RefundArguments } from '../model/refundArguments';
import { SetSubscriptionStatusArguments } from '../model/setSubscriptionStatusArguments';
import { SortDto } from '../model/sortDto';
import { TransactionSummary } from '../model/transactionSummary';
import { Unit } from '../model/unit';
import { User } from '../model/user';
import { UserDto } from '../model/userDto';
import { UserDtoListPaginationGrid } from '../model/userDtoListPaginationGrid';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


import { JtHttp, HttpResponse } from '../../JtHttp';

@Injectable()
export class UsersService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: JtHttp, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminUsersCancelSubscriptionImmediatelyPost(body?: CancelSubscriptionImmediatelyArguments, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiAdminUsersCancelSubscriptionImmediatelyPost(body?: CancelSubscriptionImmediatelyArguments, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiAdminUsersCancelSubscriptionImmediatelyPost(body?: CancelSubscriptionImmediatelyArguments, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiAdminUsersCancelSubscriptionImmediatelyPost(body?: CancelSubscriptionImmediatelyArguments, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Admin/Users/CancelSubscriptionImmediately`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminUsersClearUserAuthGet(observe?: 'body', reportProgress?: boolean): Observable<Unit>;
    public apiAdminUsersClearUserAuthGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Unit>>;
    public apiAdminUsersClearUserAuthGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Unit>>;
    public apiAdminUsersClearUserAuthGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Unit>('get',`${this.basePath}/api/Admin/Users/clear-user-auth`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminUsersCurrentMonthlyMembersGet(observe?: 'body', reportProgress?: boolean): Observable<CurrentMonthlyMembersResult>;
    public apiAdminUsersCurrentMonthlyMembersGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CurrentMonthlyMembersResult>>;
    public apiAdminUsersCurrentMonthlyMembersGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CurrentMonthlyMembersResult>>;
    public apiAdminUsersCurrentMonthlyMembersGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CurrentMonthlyMembersResult>('get',`${this.basePath}/api/Admin/Users/CurrentMonthlyMembers`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param filterBy 
     * @param filterValue 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminUsersFetchUsersGet(filterBy?: string, filterValue?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<UserDto>>;
    public apiAdminUsersFetchUsersGet(filterBy?: string, filterValue?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<UserDto>>>;
    public apiAdminUsersFetchUsersGet(filterBy?: string, filterValue?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<UserDto>>>;
    public apiAdminUsersFetchUsersGet(filterBy?: string, filterValue?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filterBy !== undefined && filterBy !== null) {
            queryParameters = queryParameters.set('FilterBy', <any>filterBy);
        }
        if (filterValue !== undefined && filterValue !== null) {
            queryParameters = queryParameters.set('FilterValue', <any>filterValue);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<UserDto>>('get',`${this.basePath}/api/Admin/Users/FetchUsers`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param count 
     * @param limit 
     * @param offset 
     * @param sortColumns 
     * @param searchTerm 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminUsersGet(count?: number, limit?: number, offset?: number, sortColumns?: Array<SortDto>, searchTerm?: string, observe?: 'body', reportProgress?: boolean): Observable<UserDtoListPaginationGrid>;
    public apiAdminUsersGet(count?: number, limit?: number, offset?: number, sortColumns?: Array<SortDto>, searchTerm?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserDtoListPaginationGrid>>;
    public apiAdminUsersGet(count?: number, limit?: number, offset?: number, sortColumns?: Array<SortDto>, searchTerm?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserDtoListPaginationGrid>>;
    public apiAdminUsersGet(count?: number, limit?: number, offset?: number, sortColumns?: Array<SortDto>, searchTerm?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (count !== undefined && count !== null) {
            queryParameters = queryParameters.set('Count', <any>count);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('Limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('Offset', <any>offset);
        }
        if (sortColumns) {
            sortColumns.forEach((element) => {
                queryParameters = queryParameters.append('SortColumns', <any>element);
            })
        }
        if (searchTerm !== undefined && searchTerm !== null) {
            queryParameters = queryParameters.set('SearchTerm', <any>searchTerm);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<UserDtoListPaginationGrid>('get',`${this.basePath}/api/Admin/Users`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminUsersPromoCodeRedemptionsGet(observe?: 'body', reportProgress?: boolean): Observable<PromoCodeRedemptionsResult>;
    public apiAdminUsersPromoCodeRedemptionsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PromoCodeRedemptionsResult>>;
    public apiAdminUsersPromoCodeRedemptionsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PromoCodeRedemptionsResult>>;
    public apiAdminUsersPromoCodeRedemptionsGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PromoCodeRedemptionsResult>('get',`${this.basePath}/api/Admin/Users/PromoCodeRedemptions`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminUsersRefundPost(body?: RefundArguments, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiAdminUsersRefundPost(body?: RefundArguments, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiAdminUsersRefundPost(body?: RefundArguments, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiAdminUsersRefundPost(body?: RefundArguments, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Admin/Users/Refund`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param search 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminUsersSearchGet(search?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<User>>;
    public apiAdminUsersSearchGet(search?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<User>>>;
    public apiAdminUsersSearchGet(search?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<User>>>;
    public apiAdminUsersSearchGet(search?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (search !== undefined && search !== null) {
            queryParameters = queryParameters.set('search', <any>search);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<User>>('get',`${this.basePath}/api/Admin/Users/Search`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminUsersSetSubscriptionStatusPost(body?: SetSubscriptionStatusArguments, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiAdminUsersSetSubscriptionStatusPost(body?: SetSubscriptionStatusArguments, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiAdminUsersSetSubscriptionStatusPost(body?: SetSubscriptionStatusArguments, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiAdminUsersSetSubscriptionStatusPost(body?: SetSubscriptionStatusArguments, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Admin/Users/SetSubscriptionStatus`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminUsersUpdateSubscriptionEventsGet(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiAdminUsersUpdateSubscriptionEventsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiAdminUsersUpdateSubscriptionEventsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiAdminUsersUpdateSubscriptionEventsGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Admin/Users/UpdateSubscriptionEvents`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminUsersUserIdGet(userId: number, observe?: 'body', reportProgress?: boolean): Observable<PublicUser>;
    public apiAdminUsersUserIdGet(userId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PublicUser>>;
    public apiAdminUsersUserIdGet(userId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PublicUser>>;
    public apiAdminUsersUserIdGet(userId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling apiAdminUsersUserIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PublicUser>('get',`${this.basePath}/api/Admin/Users/${encodeURIComponent(String(userId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminUsersUserIdPaymentHistoryGet(userId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<TransactionSummary>>;
    public apiAdminUsersUserIdPaymentHistoryGet(userId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TransactionSummary>>>;
    public apiAdminUsersUserIdPaymentHistoryGet(userId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TransactionSummary>>>;
    public apiAdminUsersUserIdPaymentHistoryGet(userId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling apiAdminUsersUserIdPaymentHistoryGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<TransactionSummary>>('get',`${this.basePath}/api/Admin/Users/${encodeURIComponent(String(userId))}/PaymentHistory`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
