<ng-container *ngIf="!selected">
  <table class="table table-hover" sortable-table (sorted)="onSorted($event)" id="excel-table">
    <thead>
    <tr>
      <th>
        <button class="btn btn-info" (click)="editItem({})">Add new</button>
      </th>
      <th>
        <label for="raceId"> Find By RaceId</label>
      </th>
      <th colspan="3">
        <input type="number" class="form-control" id="raceId" aria-describedby="raceId" placeholder="Enter raceId"
               [(ngModel)]="findRaceId" (keyup.enter)="searchByButton()">
      </th>
      <th colspan="3" class="text-left">
        <button class="btn btn-success mr-2" (click)="searchByButton()">Search</button>
        <button class="btn btn-info" (click)="clear()">Clear</button>
      </th>
    </tr>
    <tr>
      <th scope="col" sortable-column="Id" class="pointer">Id</th>
      <th scope="col" sortable-column="MinimumWagerDenomination" class="pointer">
        MinimumWagerDenomination
      </th>
      <th scope="col" sortable-column="PoolTotal" class="pointer">
        PoolTotal
      </th>
      <th scope="col" sortable-column="RaceId" class="pointer">
        RaceId
      </th>
      <th scope="col" sortable-column="SlotCount" class="pointer">
        SlotCount
      </th>
      <th scope="col" sortable-column="Type" class="pointer">
        Type
      </th>
      <th scope="col">Wager Name</th>
      <th>
        Action
      </th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let item of currentWagers?.result">
      <tr>
        <td class="width-60">{{item.id}}</td>
        <td>{{item.minimumWagerDenomination}}</td>
        <td>{{item.poolTotal}}</td>
        <td>{{item.raceId}}</td>
        <td>{{item.slotCount}}</td>
        <td>{{item.type}}</td>
        <td>
          {{getWagerName(item)}}
        </td>
        <td class="py-2">
          <button class="btn btn-success btn-sm mr-2" (click)="editItem(item)">Edit</button>
          <button class="btn btn-info btn-sm mr-2" (click)="searchByRaceId(item.raceId)">Find</button>
          <button class="btn btn-warning btn-sm" (click)="editItem({raceId:item.raceId})">Add new
            for {{item.raceId}}</button>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
  <ng-container *ngIf="currentWagers">
    <ngb-pagination [pageSize]="pageSize" [collectionSize]="currentWagers.totalItems"
                    class="d-flex justify-content-center"
                    (pageChange)="pageChanged($event)" [rotate]="true" [(page)]="currentPage" [maxSize]="6"
                    [boundaryLinks]="true">
    </ngb-pagination>
  </ng-container>
</ng-container>
<ng-container *ngIf="selected">
  <app-race-wager-type-edit [editRaceWagerText]="selected" (editFinished)="editItem(null)"></app-race-wager-type-edit>
</ng-container>
