import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TakeUntilDestroy} from '../../services/take-until-destroy.decorator';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {CreateEditMeetCommand, Meet, MeetAdminService, PublicTrack, Track, TracksService} from '../../swagger-codegen';
import {concat, Observable, of, Subject} from 'rxjs';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastService} from '../../services/toast.service';
import * as _ from 'lodash';
import {catchError, debounceTime, distinctUntilChanged, filter, finalize, map, switchMap, takeUntil, tap} from 'rxjs/operators';
import {Functions} from '../../services/functions';

@TakeUntilDestroy
@Component({
  selector: 'app-meet-edit',
  templateUrl: './meet-edit.component.html',
  styleUrls: ['./meet-edit.component.scss']
})
export class MeetEditComponent implements OnInit {
  @BlockUI()
  blockUI: NgBlockUI;
  componentDestroy: () => Observable<boolean>;
  @Input()
  editMeet: Meet;
  @Output()
  editFinished: EventEmitter<boolean> = new EventEmitter<boolean>();
  meetForm: FormGroup;
  tracks$: Observable<PublicTrack[]>;
  tracksLoading = false;
  tracksInput$ = new Subject<string>();

  constructor(private meetService: MeetAdminService, private toast: ToastService, private tracksService: TracksService) {
  }

  private loadTracks(): void {
    this.tracks$ = concat(
      of(_.chain(this.editMeet?.track).filter((x) => x).value()), // default items
      this.tracksInput$.pipe(
        map(x => _.trim(x)),
        distinctUntilChanged(),
        filter(x => x && x.length > 2),
        debounceTime(1000),
        tap(() => this.tracksLoading = true),
        switchMap(term => {
            return this.tracksService.apiAdminTracksSearchGet(term).pipe(
              catchError(() => of([])), // empty list on error
              tap(() => this.tracksLoading = false)
            );
          }
        ),
        takeUntil(this.componentDestroy())
      ));
  }

  ngOnInit(): void {
    if (this.editMeet?.id || this.editMeet?.id === 0) {
      if (this.editMeet?.id === 0) {
        if (this.editMeet?.pricePerDay === 0) {
          this.editMeet.pricePerDay = 3.95;
        }
        if (this.editMeet?.sellDurationDays === 0) {
          this.editMeet.sellDurationDays = 14;
        }
      }
      this.meetForm = new FormGroup(
        {
          id: new FormControl(this.editMeet?.id),
          name: new FormControl(this.editMeet?.name, [Validators.required]),
          trackName: new FormControl(this.editMeet?.track?.name),
          trackId: new FormControl(this.editMeet?.trackId, [Validators.required]),
          startDate: new FormControl(this.editMeet?.startDate, [], Functions.dateValidator.bind(this)),
          endDate: new FormControl(this.editMeet?.endDate, [], Functions.dateValidator.bind(this)),
          sellDurationDays: new FormControl(this.editMeet?.sellDurationDays),
          pricePerDay: new FormControl(this.editMeet?.pricePerDay),
          isDeleted: new FormControl(this.editMeet?.isDeleted)
        }
      );
    } else {
      this.meetForm = new FormGroup(
        {
          id: new FormControl(0),
          name: new FormControl('', [Validators.required]),
          trackName: new FormControl(this.editMeet?.track?.name),
          trackId: new FormControl('', [Validators.required]),
          startDate: new FormControl((new Date().toISOString()), [], Functions.dateValidator.bind(this)),
          endDate: new FormControl((new Date().toISOString()), [], Functions.dateValidator.bind(this)),
          sellDurationDays: new FormControl(14),
          pricePerDay: new FormControl(3.95),
          isDeleted: new FormControl(false)
        }
      );
    }

    this.loadTracks();
  }

  setTrack(v: PublicTrack): void {
    this.meetForm.controls.trackId.patchValue(v?.id);
  }

  trackByFn(item: Track): any {
    return item.name;
  }

  cancel(): void {
    this.editFinished.emit(true);
  }

  save(): void {
    if (this.meetForm.valid) {
      this.blockUI.start();
      const newMeet: CreateEditMeetCommand = {
        id: this.meetForm.value.id,
        name: this.meetForm.value.name,
        trackId: this.meetForm.value.trackId,
        startDate: new Date(this.meetForm.value.startDate).toISOString(),
        endDate: new Date(this.meetForm.value.endDate).toISOString(),
        sellDurationDays: this.meetForm.value.sellDurationDays,
        pricePerDay: this.meetForm.value.pricePerDay,
        isDeleted: this.meetForm.value.isDeleted
      };
      this.meetService.apiAdminMeetPost(newMeet)
        .pipe(
          finalize(() => {
            this.blockUI.stop();
            this.editFinished.emit(true);
          }),
          takeUntil(this.componentDestroy()))
        .subscribe(data => {
          this.blockUI.stop();
        });
    } else {
      this.toast.error('Please, set all fields');
    }
  }
}
