<h1 class="text-center">Users Current Monthly Members</h1>
<div class="container-fluid">
  <table class="table table-hover" sortable-table (sorted)="onSorted($event)" id="excel-table" *ngIf="current">
    <thead>
    <tr>
      <th colspan="99" class="text-right">
        <a style="cursor: pointer" class="text-info" (click)="export()">
          Export to csv
        </a>
      </th>
    </tr>
    <tr>
      <th scope="col" sortable-column="id">Id</th>
      <th scope="col" sortable-column="firstName" class="pointer">
        First Name
      </th>
      <th scope="col" sortable-column="lastName" class="pointer">
        Last Name
      </th>
      <th scope="col" sortable-column="email" class="pointer">Email</th>
      <th scope="col" sortable-column="code" class="pointer">Code</th>

      <th scope="col" sortable-column="authProfile" class="pointer">AuthProfile</th>
      <th scope="col" sortable-column="status" class="pointer">Status</th>
      <th scope="col" sortable-column="currentPeriodStarted" class="pointer">CurrentPeriodStarted
      </th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let user of sorted">
      <tr>
        <td class="width-60">{{user.id}}</td>
        <td>{{user.firstName}}</td>
        <td>{{user.lastName}}</td>
        <td>{{user.email}}</td>
        <td>{{user?.code}}</td>
        <td>{{user?.authProfile}}</td>
        <td>{{user?.status}}</td>
        <td>{{user?.currentPeriodStarted}}</td>
      </tr>
    </ng-container>
    </tbody>
  </table>
  <ngb-pagination [pageSize]="pageSize" [collectionSize]="+colectionSize" class="d-flex justify-content-center"
                  (pageChange)="pageChanged($event)" [rotate]="true" [(page)]="currentPage" [maxSize]="6"
                  [boundaryLinks]="true">
  </ngb-pagination>
</div>
