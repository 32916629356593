<ng-container *ngIf="meetForm&&editMeet">
  <form class="container-fluid" [formGroup]="meetForm">
    <div class="card-header text-center">
      <ng-container *ngIf="!editMeet?.id">
        <h1>
          Create new Meet
        </h1>
      </ng-container>
      <ng-container *ngIf="editMeet?.id">
        <h1>Meet Id: {{ editMeet?.id }}</h1>
      </ng-container>
    </div>
    <div class="text-center mt-3">
      <div class="w-50 d-inline-block">
        <div class="mb-2 row">
          <label for="Name" class="col-sm-6 col-form-label">Name</label>
          <div class="col-sm-6">
            <input type="text" class="form-control" id="Name" formControlName="name">
          </div>
        </div>
        <div class="mb-2 row">
          <label for="track" class="col-sm-6 col-form-label">Search track</label>
          <div class="col-sm-6">

            <ng-select id="track" [items]="tracks$ | async" class="border-1"
                       bindLabel="name"
                       [hideSelected]="true"
                       [trackByFn]="trackByFn"
                       bindValue="id"
                       [minTermLength]="3"
                       [loading]="tracksLoading"
                       typeToSearchText="Please enter 3 or more characters"
                       [typeahead]="tracksInput$"
                       formControlName="trackName"
                       (change)="setTrack($event)" autocomplete="false">
            </ng-select>

          </div>
        </div>
        <div class="mb-2 row">
          <label for="trackId" class="col-sm-6 col-form-label">TrackId</label>
          <div class="col-sm-6">
            <input type="number" class="form-control" id="trackId" formControlName="trackId" readonly>
          </div>
        </div>
        <div class="mb-2 row ">
          <label class="col-sm-6 col-form-label">StartDate
          </label>
          <div class="col-sm-6">
            <div>
              <ng-container *ngIf="meetForm?.controls?.startDate.valid">
                {{meetForm?.value?.startDate | date :'short'}}
              </ng-container>
            </div>
            <input type="text" class="form-control" id="startDate" formControlName="startDate">
          </div>
        </div>
        <div class="mb-2 row">
          <label class="col-sm-6 col-form-label">EndDate
          </label>
          <div class="col-sm-6">
            <div>
              <ng-container *ngIf="meetForm?.controls?.endDate.valid">
                {{meetForm?.value?.endDate | date :'short'}}
              </ng-container>
            </div>
            <input type="text" class="form-control" id="endDate" formControlName="endDate">
          </div>
        </div>
        <div class="mb-2 row">
          <label for="sellDurationDays" class="col-sm-6 col-form-label">SellDurationDays</label>
          <div class="col-sm-6">
            <input type="number" class="form-control" id="sellDurationDays" formControlName="sellDurationDays">
          </div>
        </div>
        <div class="mb-2 row">
          <label for="pricePerDay" class="col-sm-6 col-form-label">PricePerDay</label>
          <div class="col-sm-6">
            <input type="number" class="form-control" id="pricePerDay" formControlName="pricePerDay">
          </div>
        </div>
        <div class="mb-2 row">
          <label for="isDeleted" class="col-sm-6  col-form-label">IsDeleted</label>
          <div class="col-sm-6">
            <input type="checkbox" class="form-control" id="isDeleted" formControlName="isDeleted">
          </div>
        </div>
      </div>
    </div>
    <div class="text-center py-3">
      <button *ngIf="editMeet" type="button" class="toggleButton btn btn-secondary mr-3" (click)="cancel()">
        Cancel
      </button>
      <button type="button" class="toggleButton btn btn-primary" (click)="save()">
        Save
      </button>
    </div>
  </form>
</ng-container>

