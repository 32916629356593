<h1 class="text-center">Users</h1>
<div class="container-fluid">
  <table class="table table-hover" sortable-table (sorted)="onSorted($event)" id="excel-table">
    <thead>
    <tr>
      <th colspan="3" class="py-0"></th>
      <th colspan="2" class="py-0">
        <div class="input-group my-2">
          <div class="input-group-prepend">
            <div class="input-group-text"><i class="fa fa-search"></i></div>
          </div>
          <input type="text" class="form-control" [formControl]="filterControl" autocomplete="off" placeholder="Search">
        </div>
      </th>
      <th colspan="2" class="py-0">
        <div class="input-group my-2">
          <select class="form-control" id="bundleFilter" [(ngModel)]="bundleFilter" (change)="filter()">
            <option *ngFor="let item of bundleFilterItems">{{item}}</option>
          </select>
        </div>
      </th>
      <th colspan="3"></th>
    </tr>
    <tr>
      <th colspan="4">
        <a (click)="open(content)" class="text-info">Create New User</a>
      </th>
      <th colspan="1" class="py-0">
        <div class="form-group  my-2">
          <select class="form-control" id="startWithFilter" [(ngModel)]="startWithFilter" (change)="filter()">
            <option *ngFor="let item of startWithItems">{{item}}</option>
          </select>
        </div>
      </th>
      <th colspan="1" class="py-0">
        <div class="form-group  my-2">
          <select class="form-control" id="hasPurchasedFilter" [(ngModel)]="hasPurchasedFilter"
                  (change)="filter()">
            <option *ngFor="let item of hasPurchasedItems">{{item}}</option>
          </select>
        </div>
      </th>
      <th colspan="1" class="py-0">
        <div class="form-group  my-2">
          <select class="form-control" id="withCurrentPlanFilter" [(ngModel)]="withCurrentPlanFilter"
                  (change)="filter()">
            <option *ngFor="let item of withCurrentPlanItems">{{item}}</option>
          </select>
        </div>
      </th>
      <th colspan="1" class="py-0">
        <div class="form-group  my-2">
          <select class="form-control" id="planFilter" [(ngModel)]="canQualifyForFilter" (change)="filter()">
            <option *ngFor="let item of canQualifyForItems">{{item}}</option>
          </select>
        </div>
      </th>
      <th colspan="2" class="text-right">
        <a style="cursor: pointer" class="text-info" (click)="export()">
          Export to csv
        </a>
      </th>
    </tr>
    <tr>
      <th scope="col" sortable-column="id">Id</th>
      <th scope="col" sortable-column="firstName" class="pointer">
        First Name
      </th>
      <th scope="col" sortable-column="lastName" class="pointer">
        Last Name
      </th>
      <th scope="col" sortable-column="email" class="pointer">Email</th>
      <th scope="col" sortable-column="startedWithPlan" class="pointer">StartedWith</th>
      <th scope="col" sortable-column="hasPurchasedPlans" class="pointer">HasPurchased</th>
      <th scope="col" sortable-column="withCurrentPlan" class="pointer">WithCurrentPlan</th>
      <th scope="col" sortable-column="canQualifyFor" class="pointer">CanQualifyFor
      </th>
      <th scope="col" sortable-column="authorizeNetId" class="pointer">
        Authorize.NET ID
      </th>
      <th scope="col" sortable-column="authorizeNetId" class="pointer">
        Paying?
      </th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let user of usersSorted">
      <tr class="pointer" [ngClass]="{'table-danger': !user.isActive}"
          [routerLink]="'/users/'+user.id">
        <td class="width-60">{{user.id}}</td>
        <td>{{user.firstName}}</td>
        <td>{{user.lastName}}</td>
        <td>{{user.email}}</td>
        <td>{{user?.startedWithPlan}}</td>
        <td>
          <ng-container *ngIf="user?.hasPurchasedPlans">
            <ng-container *ngFor="let item of user?.hasPurchasedPlans; let ind=index">
              {{item}}
              <ng-container *ngIf="ind!==user.hasPurchasedPlans?.length-1">,</ng-container>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!user?.hasPurchasedPlans?.length">
            None
          </ng-container>
        </td>
        <td>
          {{user?.withCurrentPlan}}
        </td>
        <td>
          <ng-container *ngIf="user?.canQualifyFor">
            <ng-container *ngFor="let item of user?.canQualifyFor; let indd=index">
              {{item?.upgrade}}
              <ng-container *ngIf="indd!==user.canQualifyFor.length-1">,</ng-container>
            </ng-container>
          </ng-container>
        </td>
        <td>{{user.authorizeNetId}}</td>
        <td>{{(user.authorizeNetId) ? "True" : "False"}}</td>
      </tr>
    </ng-container>
    </tbody>
  </table>
  <ngb-pagination [pageSize]="pageSize" [collectionSize]="+colectionSize" class="d-flex justify-content-center"
                  (pageChange)="pageChanged($event)" [rotate]="true" [(page)]="currentPage" [maxSize]="6"
                  [boundaryLinks]="true">
  </ngb-pagination>
</div>
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Create New User</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="accountForm" (keydown.enter)="validateForm()" (ngSubmit)="validateForm()" autocomplete="off">
      <div class="form-row">
        <div class="col">
          <input type="text" class="form-control" placeholder="First name" name="firstName" formControlName="firstName"
                 autocomplete="off"
                 [ngClass]="{'is-valid':isValid('firstName'), 'is-invalid':isInValid('firstName')}">
        </div>
        <div class="col">
          <input type="text" class="form-control" placeholder="Last name" name="lastName" formControlName="lastName"
                 autocomplete="off"
                 [ngClass]="{'is-valid':isValid('lastName'), 'is-invalid':isInValid('lastName')}">
        </div>
      </div>
      <div class="form-row">
        <div class="col">
          <input type="text" class="form-control" placeholder="Email" name="email" formControlName="email"
                 autocomplete="off"
                 [ngClass]="{'is-valid':isValid('email'), 'is-invalid':isInValid('email')}">
          <div class="warning-tooltip" *ngIf="isEmailInUseShowModal">
            <div class="tooltip-body">
              There is already an account with that email address. <br> Would you like to log in instead?
              <div class="text-center py-2">
                <button class="btn btn-success" (click)="clearEmail()">Use another email</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col">
          <input type="text" class="form-control" placeholder="Password" name="password" formControlName="password"
                 autocomplete="off"
                 [ngClass]="{'is-valid':isValid('password'), 'is-invalid':isInValid('password')}">
          <div class="invalid-feedback">
            <ng-container *ngIf="accountForm.controls['password']?.errors?.customValidator">
              {{accountForm.controls['password']?.errors['customValidator']}}
            </ng-container>
            <ng-container *ngIf="!accountForm.controls['password']?.errors?.customValidator">
              The password must be at least 8 characters long.
            </ng-container>
          </div>
        </div>
        <div class="col">
          <input type="text" class="form-control" placeholder="Confirm Password" name="confirmPassword"
                 formControlName="confirmPassword" autocomplete="off"
                 [ngClass]="{'is-valid':isValid('confirmPassword'), 'is-invalid':isInValid('confirmPassword')}">
          <div class="invalid-feedback">
            The confirm password needs to be the same as the password.
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col pt-2 font-weight-bold">
          Plan Code
        </div>
        <div class="col-9">
          <select class="form-control" name="planCode" formControlName="planCode"
                  [ngClass]="{'is-invalid':isInValid('planCode')}">
            <ng-container *ngFor="let item of planCodes">
              <option>{{item}}</option>
            </ng-container>
          </select>
        </div>
      </div>
      <div class="form-row">
        <div class="col pt-2 font-weight-bold">
          Account Type
        </div>
        <div class="col-9">
          <select class="form-control" name="accountType" formControlName="accountType">
            <option>FREE</option>
            <option>PAID</option>
            <option>AFFILIATE</option>
          </select>
        </div>
      </div>
      <div class="form-row">
        <div class="col pt-2 font-weight-bold">
          Start Date
        </div>
        <div class="col-9 input-group pointer">
          <input type="text" readonly="readonly" class="form-control" placeholder="yyyy-mm-dd" name="startDate"
                 formControlName="startDate" (click)="dp.toggle()"
                 autocomplete="off" ngbDatepicker #dp="ngbDatepicker"
                 [ngClass]="{'is-valid':isValid('startDate'), 'is-invalid':isInValid('startDate')}">
          <div class="input-group-append" (click)="dp.toggle()">
            <button class="btn btn-outline-secondary calendar" type="button">
              <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-calendar-event" fill="currentColor"
                   xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                      d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"></path>
                <path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z"></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn  btn-success" (click)="validateForm()"
            [ngClass]="{'disabled':!accountForm.valid}">Create
    </button>
  </div>
</ng-template>
