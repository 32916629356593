import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TakeUntilDestroy} from '../../services/take-until-destroy.decorator';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {forkJoin, Observable, zip} from 'rxjs';
import {MarketingChannel, MarketingChannelsService, PromoCode, PromoCodesService} from '../../swagger-codegen';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastService} from '../../services/toast.service';
import * as _ from 'lodash';
import {Functions} from '../../services/functions';
import {finalize, takeUntil} from 'rxjs/operators';

@TakeUntilDestroy
@Component({
  selector: 'app-promo-code-edit',
  templateUrl: './promo-code-edit.component.html',
  styleUrls: ['./promo-code-edit.component.scss']
})
export class PromoCodeEditComponent implements OnInit {
  @BlockUI()
  blockUI: NgBlockUI;
  componentDestroy: () => Observable<boolean>;
  @Input()
  editPromoCode: PromoCode;
  @Output()
  editFinished: EventEmitter<boolean> = new EventEmitter<boolean>();
  promoCodeForm: FormGroup;
  claimTypes: string[] = ['Credit', 'CommitmentPeriod', 'CommitmentTerm', 'PlanId', 'Track', 'Filter', 'RaceNumber', 'CreditCardRequired', 'PurchaseDate'];
  codeClaimsLineForEdit?: number = null;
  allMarketingChannels: MarketingChannel[] = [];

  constructor(private pcService: PromoCodesService, private toast: ToastService, private marketingService: MarketingChannelsService) {
  }

  ngOnInit(): void {
    if (this.editPromoCode?.id) {
      this.promoCodeForm = new FormGroup(
        {
          id: new FormControl(this.editPromoCode?.id),
          isDeleted: new FormControl(this.editPromoCode?.isDeleted),
          code: new FormControl(this.editPromoCode?.code, [Validators.required]),
          startDate: new FormControl(this.editPromoCode?.startDate, [], Functions.dateValidator.bind(this)),
          endDate: new FormControl(this.editPromoCode?.endDate, [], Functions.dateValidator.bind(this)),
          name: new FormControl(this.editPromoCode?.name, [Validators.required]),
          description: new FormControl(this.editPromoCode?.description, [Validators.required]),
          setPriceTo: new FormControl(this.editPromoCode?.setPriceTo),
          maxRedemptions: new FormControl(this.editPromoCode?.maxRedemptions, [Validators.required]),
          maxRedemptionsPerUser: new FormControl(this.editPromoCode?.maxRedemptionsPerUser, [Validators.required]),
          promoCodeClaims: new FormArray([])
        }
      );
      _.forEach(this.editPromoCode.promoCodeClaims, (x) => {
        (this.promoCodeForm.controls.promoCodeClaims as FormArray).push(
          new FormGroup(
            {
              id: new FormControl(x.id, [Validators.required]),
              isDeleted: new FormControl(x.isDeleted, [Validators.required]),
              claimType: new FormControl(x.claimType, [Validators.required]),
              claimValue: new FormControl(x.claimValue, [Validators.required]),
              claimInt: new FormControl(x.claimInt),
              claimDecimal: new FormControl(x.claimDecimal)
            }
          )
        );
      });
    } else {
      this.blockUI.start();
      this.marketingService.apiAdminMarketingChannelsAllGet().pipe(finalize(this.blockUI.stop), takeUntil(this.componentDestroy()))
        .subscribe((result) => {
          this.allMarketingChannels = result;
          this.promoCodeForm = new FormGroup(
            {
              id: new FormControl(0),
              isDeleted: new FormControl(false),
              code: new FormControl('', [Validators.required]),
              startDate: new FormControl((new Date().toISOString()), [], Functions.dateValidator.bind(this)),
              endDate: new FormControl((new Date().toISOString()), [], Functions.dateValidator.bind(this)),
              name: new FormControl('', [Validators.required]),
              description: new FormControl('', [Validators.required]),
              setPriceTo: new FormControl(null),
              maxRedemptions: new FormControl(null, [Validators.required]),
              maxRedemptionsPerUser: new FormControl(null, [Validators.required]),
              promoCodeClaims: new FormArray([]),
              channels: new FormArray([]),
            }
          );
          _.forEach(this.allMarketingChannels, (x) => {
            if (!x.isDeleted) {
              (this.promoCodeForm.controls.channels as FormArray).push(
                new FormGroup(
                  {
                    select: new FormControl(true),
                    name: new FormControl(x.name),
                    code: new FormControl(x.code)
                  }
                )
              );
            }
          });
        });
    }
  }


  addNewClaim(): void {
    (this.promoCodeForm.controls.promoCodeClaims as FormArray).push(
      new FormGroup(
        {
          id: new FormControl(0, [Validators.required]),
          isDeleted: new FormControl(false, [Validators.required]),
          claimType: new FormControl('', [Validators.required]),
          claimValue: new FormControl('', [Validators.required]),
          claimInt: new FormControl(null),
          claimDecimal: new FormControl(null)
        }
      )
    );
    this.codeClaimsLineForEdit = (this.promoCodeForm.controls.promoCodeClaims as FormArray).length - 1;
  }

  deleteClaim(i: number): void {
    (this.promoCodeForm.controls.promoCodeClaims as FormArray).removeAt(i);
    this.codeClaimsLineForEdit = null;
  }

  selectClaim(n: number): void {
    if (!n) {
      if ((this.promoCodeForm.controls.promoCodeClaims as FormArray)?.controls[this.codeClaimsLineForEdit].valid) {
        this.codeClaimsLineForEdit = n;
      } else {
        this.toast.error('Please,  set valid value');
      }
    } else {
      this.codeClaimsLineForEdit = n;
    }
  }

  cancel(): void {
    this.editFinished.emit(true);
  }

  save(): void {
    if (this.promoCodeForm.valid) {
      const promo = {
        id: this.promoCodeForm.value.id,
        isDeleted: this.promoCodeForm.value.isDeleted,
        code: this.promoCodeForm.value.code,
        startDate: (_.trim(this.promoCodeForm.value.startDate) ? new Date(this.promoCodeForm.value.startDate).toISOString() : ''),
        endDate: (_.trim(this.promoCodeForm.value.endDate) ? new Date(this.promoCodeForm.value.endDate).toISOString() : ''),
        name: this.promoCodeForm.value.name,
        description: this.promoCodeForm.value.description,
        setPriceTo: this.promoCodeForm.value.setPriceTo,
        maxRedemptions: this.promoCodeForm.value.maxRedemptions,
        maxRedemptionsPerUser: this.promoCodeForm.value.maxRedemptionsPerUser,
        // redemptions: this.editPromoCode.redemptions,
        promoCodeClaims: this.promoCodeForm.value.promoCodeClaims
      } as PromoCode;
      if (this.allMarketingChannels.length && _.chain(this.promoCodeForm.value.channels).filter(x => x.select).value()?.length) {
        const arrayRequest = [];
        _.forEach(this.promoCodeForm.value.channels, (x) => {
          if (x?.select) {
            const newPromo = _.cloneDeep(promo);
            newPromo.code = promo.code + '_' + x?.code;
            arrayRequest.push(this.pcService.apiAdminPromoCodesPost(newPromo));
          }
        });
        this.blockUI.start();
        const requests = forkJoin(arrayRequest);
        requests.subscribe(() => {
          this.blockUI.stop();
          this.editFinished.emit(true);
          this.toast.success('PromoCode is success saved');
        });
      } else {
        this.blockUI.start();
        this.pcService.apiAdminPromoCodesPost(promo).pipe(
          finalize(() => {
            this.blockUI.stop();
            this.editFinished.emit(true);
          }),
          takeUntil(this.componentDestroy()))
          .subscribe(data => {
            this.blockUI.stop();
            this.toast.success('PromoCode is success saved');
          });
      }
    } else {
      this.toast.error('PromoCode is not valid');
    }
  }
}
