<ng-container *ngIf="featureForm&&editFeature">
  <form class="container-fluid" [formGroup]="featureForm">
    <div class="card-header text-center">
      <ng-container *ngIf="!editFeature?.id">
        <h1>
          Create new Feature
        </h1>
      </ng-container>
      <ng-container *ngIf="editFeature?.id">
        <h1>Feature Id: {{ editFeature?.id }}</h1>
      </ng-container>
    </div>
    <div class="text-center mt-3">
      <div class="w-50 d-inline-block">
        <div class="mb-2 row">
          <label for="Name" class="col-sm-6 col-form-label">Name</label>
          <div class="col-sm-6">
            <input type="text" class="form-control" id="Name" formControlName="name">
          </div>
        </div>
        <div class="mb-2 row">
          <label for="description" class="col-sm-6 col-form-label">Description</label>
          <div class="col-sm-6">
            <input type="text" class="form-control" id="description" formControlName="description">
          </div>
        </div>
        <div class="mb-2 row">
          <label for="price" class="col-sm-6 col-form-label">Price</label>
          <div class="col-sm-6">
            <input type="number" class="form-control" id="price" formControlName="price">
          </div>
        </div>
        <div class="mb-2 row">
          <label for="isDeleted" class="col-sm-6  col-form-label">IsDeleted</label>
          <div class="col-sm-6">
            <input type="checkbox" class="form-control" id="isDeleted" formControlName="isDeleted">
          </div>
        </div>
      </div>
    </div>
    <div class="text-center py-3">
      <button *ngIf="editFeature" type="button" class="toggleButton btn btn-secondary mr-3" (click)="cancel()">
        Cancel
      </button>
      <button type="button" class="toggleButton btn btn-primary" (click)="save()">
        Save
      </button>
    </div>
  </form>
</ng-container>

