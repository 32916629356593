<ng-container *ngIf="!selected">
  <table class="table table-hover" sortable-table (sorted)="onSorted($event)" id="excel-table">
    <thead>
    <tr>
      <th colspan="3">
        <button class="btn btn-success" (click)="editItem({})" *ngIf="!showPotentialMeets">Add Custom</button>
        <input type="text" class="form-control" [(ngModel)]="filterByTrack" *ngIf="showPotentialMeets" placeholder="Filter By Track" >
      </th>
      <th colspan="2">
        <div class="btn-group btn-group-toggle" data-toggle="buttons">
          <label class="btn btn-outline-success btn-sm" [ngClass]="{'active':showPotentialMeets === true}">
            <input type="radio" name="options" id="op"
                   [checked]="showPotentialMeets === true"
                   [(ngModel)]="showPotentialMeets"
                   [value]="true"> Show Potential Meets
          </label>
          <label class="btn btn-outline-success btn-sm" [ngClass]="{'active':showPotentialMeets === false}">
            <input type="radio" name="options" id="op2"
                   [checked]="showPotentialMeets === false"
                   [(ngModel)]="showPotentialMeets"
                   [value]="false">Show Current Meets
          </label>
        </div>
      </th>
      <th colspan="3">
        <h2 *ngIf="showPotentialMeets">Potential Meets</h2>
        <h2 *ngIf="!showPotentialMeets">Current Meets</h2>
      </th>
      <th colspan="2" class="right">
        <div class="btn-group btn-group-toggle" data-toggle="buttons">
          <label class="btn btn-outline-info btn-sm" [ngClass]="{'active':filterByIsDeleted === true}">
            <input type="radio" name="options" id="option1"
                   [checked]="filterByIsDeleted === true"
                   [(ngModel)]="filterByIsDeleted"
                   (ngModelChange)="choose($event)"
                   [value]="true"> Show Deleted
          </label>
          <label class="btn btn-outline-info btn-sm" [ngClass]="{'active':filterByIsDeleted === false}">
            <input type="radio" name="options" id="option2"
                   [checked]="filterByIsDeleted === false"
                   [(ngModel)]="filterByIsDeleted"
                   (ngModelChange)="choose($event)"
                   [value]="false"> Hide Deleted
          </label>
        </div>
      </th>
    </tr>
    <tr>
      <th scope="col" sortable-column="Id" class="pointer">Id</th>
      <th scope="col" sortable-column="Name" class="pointer">
        name
      </th>
      <th scope="col">
        trackAbbreviation
      </th>
      <th scope="col">
        trackName
      </th>
      <th scope="col" sortable-column="TrackId" class="pointer">
        trackId
      </th>
      <th scope="col" sortable-column="StartDate" class="pointer">
        startDate
      </th>
      <th scope="col" sortable-column="EndDate" class="pointer">
        endDate
      </th>
      <th scope="col" sortable-column="SellDurationDays" class="pointer">
        sellDurationDays
      </th>
      <th scope="col" sortable-column="PricePerDay" class="pointer">
        pricePerDay
      </th>
      <th scope="col" sortable-column="IsDeleted" class="pointer">
        isDeleted
      </th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let item of meets">
      <tr (click)="editItem(item)" [ngClass]="{'bg-danger':item.isDeleted}">
        <td class="width-60">{{item.id}}</td>
        <td>{{item.name}}</td>
        <td>{{item?.track?.abbreviation}}</td>
        <td>{{item?.track?.name}}</td>
        <td>{{item.trackId}}</td>
        <td>{{item.startDate | date:'short'}}</td>
        <td>{{item.endDate | date:'short'}}</td>
        <td>{{item.sellDurationDays}}</td>
        <td>{{item.pricePerDay}}</td>
        <td>{{item.isDeleted}}</td>
      </tr>
    </ng-container>
    </tbody>
  </table>
  <ng-container *ngIf="!showPotentialMeets&&currentMeets">
    <ngb-pagination [pageSize]="pageSize" [collectionSize]="currentMeets.totalItems"
                    class="d-flex justify-content-center"
                    (pageChange)="pageChanged($event)" [rotate]="true" [(page)]="currentPage" [maxSize]="30"
                    [boundaryLinks]="true">
    </ngb-pagination>
  </ng-container>
</ng-container>
<ng-container *ngIf="selected">
  <app-meet-edit [editMeet]="selected" (editFinished)="editItem(null)"></app-meet-edit>
</ng-container>
