<h1 class="text-center">PromoCode Redemptions</h1>
<div class="container-fluid">
  <table class="table table-hover" sortable-table (sorted)="onSorted($event)" id="excel-table" *ngIf="current">
    <thead>
    <tr>
      <th colspan="99" class="text-right">
        <a style="cursor: pointer" class="text-info" (click)="export()">
          Export to csv
        </a>
      </th>
    </tr>
    <tr>
      <th scope="col" sortable-column="firstName" class="pointer">
        First Name
      </th>
      <th scope="col" sortable-column="lastName" class="pointer">
        Last Name
      </th>
      <th scope="col" sortable-column="email" class="pointer">Email</th>
      <th scope="col" sortable-column="promoCode" class="pointer">PromoCode</th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let user of sorted">
      <tr>
        <td>{{user.firstName}}</td>
        <td>{{user.lastName}}</td>
        <td>{{user.email}}</td>
        <td>{{user.promoCode}}</td>
      </tr>
    </ng-container>
    </tbody>
  </table>
  <ngb-pagination [pageSize]="pageSize" [collectionSize]="+colectionSize" class="d-flex justify-content-center"
                  (pageChange)="pageChanged($event)" [rotate]="true" [(page)]="currentPage" [maxSize]="6"
                  [boundaryLinks]="true">
  </ngb-pagination>
</div>
