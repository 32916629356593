import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-overflow-text',
  templateUrl: './overflow-text.component.html',
  styleUrls: ['./overflow-text.component.scss']
})
export class OverflowTextComponent {
  @Input()
  show = false;

  _text = '';
  @Input()
  maxLength = this.text.length;

  @Input()
  set text(value: string) {
    if (value) {
      value = value.replace('Canceled', '<span class="text-danger font-b">CANCELED</span>');
    }
    this._text = value;
  }

  get text(): string {
    return this._text;
  }

  constructor() {
  }

  getShortText(): string {
    return '<u>' + this.text.substring(0, this.maxLength - 3) + '...</u>';
  }
}
