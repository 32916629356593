<table class="table table-hover" sortable-table (sorted)="onSorted($event)" id="excel-table">
  <thead>
  <tr>
    <th colspan="2">
      <label for="code"> Search by code</label>
    </th>
    <th>
      <input type="text" class="form-control" id="code" aria-describedby="code" placeholder="Enter code"
             [(ngModel)]="searchCode" (keyup.enter)="searchByButton()">
    </th>
    <th>
      <button class="btn btn-success" (click)="searchByButton()">Search</button>
    </th>
  </tr>
  <tr>
    <th scope="col" sortable-column="Id" class="pointer">Id</th>
    <th scope="col" sortable-column="Code" class="pointer">code</th>
    <th scope="col" sortable-column="Message" class="pointer">message</th>
    <th scope="col" sortable-column="MessageJson" class="pointer">messageJson</th>
    <th scope="col" sortable-column="Time" class="pointer">time</th>
  </tr>
  </thead>
  <tbody>
  <ng-container *ngFor="let item of currentLogs?.result">
    <tr>
      <td class="width-60">{{item.id}}</td>
      <td class="text-nowrap">{{item.code}}</td>
      <td>
        <app-overflow-text [text]="item.message" [maxLength]="200"
                           [show]="item.message?.length>200"></app-overflow-text>
      </td>
      <td>
        <app-overflow-text [text]="item.messageJson" [maxLength]="200"
                           [show]="item.messageJson?.length>200"></app-overflow-text>
      </td>
      <td class="text-nowrap">{{item.time | date : 'short'}}</td>
    </tr>
  </ng-container>
  </tbody>
</table>
<ng-container *ngIf="currentLogs">
  <ngb-pagination [pageSize]="pageSize" [collectionSize]="currentLogs.totalItems"
                  class="d-flex justify-content-center"
                  (pageChange)="pageChanged($event)" [rotate]="true" [(page)]="currentPage" [maxSize]="10"
                  [boundaryLinks]="true">
  </ngb-pagination>
</ng-container>
