<ng-container *ngIf="!selected">
  <table class="table table-hover">
    <thead>
    <tr>
      <th colspan="2">
        <button class="btn btn-success" (click)="editItem({})">Add New</button>
      </th>
      <th colspan="6">
        <h2>Marketing Channels</h2>
      </th>
    </tr>
    <tr>
      <th scope="col">Id</th>
      <th scope="col">Code</th>
      <th scope="col">Name</th>
      <th scope="col">Created At</th>
      <th scope="col">
        isDeleted
      </th>
      <th scope="col">
        Actions
      </th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let item of all">
      <tr (click)="editItem(item)">
        <td class="width-60">{{item.id}}</td>
        <td>{{item.code}}</td>
        <td>{{item.name}}</td>
        <td>{{item.createdAt | date :'short'}}</td>
        <td>{{item.isDeleted}}</td>
        <td></td>
      </tr>
    </ng-container>
    </tbody>
  </table>
</ng-container>
<ng-container *ngIf="selected">
  <app-marketing-channel-edit [editMarketingChannel]="selected" [all]="all"
                              (editFinished)="editItem(null)"></app-marketing-channel-edit>
</ng-container>

