import {Component, ElementRef, HostListener, Input, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {SortService} from './sort-service';
import {Subscription} from 'rxjs';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[sortable-column]',
  templateUrl: './sortable-column.component.html'
})
export class SortableColumnComponent implements OnInit, OnDestroy {
  // tslint:disable-next-line:no-input-rename
  @Input('sortable-column')
  columnName: string;
  // tslint:disable-next-line:no-input-rename
  @Input('sort-direction')
  sortDirection: boolean | 'asc' | 'desc' = null;
  private columnSortedSubscription: Subscription;

  constructor(private sortService: SortService, private el: ElementRef,
              private renderer: Renderer2) {
  }

  @HostListener('click')
  sort(): void {
    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    this.sortService.columnSorted({sortColumn: this.columnName, sortDirection: this.sortDirection});
  }

  ngOnInit(): void {
    // subscribe to sort changes so we can react when other columns are sorted
    this.columnSortedSubscription = this.sortService.columnSorted$.subscribe(event => {
      // reset this column's sort direction to hide the sort icons
      if (this.columnName !== event.sortColumn) {
        this.sortDirection = null;
        this.renderer.removeClass(this.el.nativeElement, 'isSorted');
      } else {
        this.renderer.addClass(this.el.nativeElement, 'isSorted');
      }
    });
  }

  ngOnDestroy(): void {
    this.columnSortedSubscription.unsubscribe();
  }
}
