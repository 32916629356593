import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {
  BlockUserCommand,
  PublicSubscription,
  PublicUser,
  TransactionSummary,
  UnblockUserCommand,
  UserAdminCommandService,
  UsersService
} from '../../swagger-codegen';
import {UserSubscriptionComponent} from '../user-subscription/user-subscription.component';
import {TakeUntilDestroy} from '../../services/take-until-destroy.decorator';
import {forkJoin, Observable} from 'rxjs';
import {finalize, takeUntil} from 'rxjs/operators';
import {ToastService} from '../../services/toast.service';
import {ActivatedRoute} from '@angular/router';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import _ from 'lodash';

@TakeUntilDestroy
@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {
  userForm: FormGroup = new FormGroup({
    id: new FormControl({value: '', disabled: true}, Validators.required),
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    email: new FormControl(
      null,
      [
        Validators.required,
        Validators.pattern('[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]{1,}@.{2,}[.]{1}[a-zA-Z]{2,}')
      ],
      //  this.checkEmail.bind(this)
    ),
    isActive: new FormControl(''),
    authorizeNetId: new FormControl(''),
    // subscriptions: new FormArray([])
  });
  addCreditForm: FormGroup = new FormGroup(
    {
      userId: new FormControl(0),
      amount: new FormControl(0, Validators.required),
      type: new FormControl('', Validators.required),
      reason: new FormControl('', Validators.required),
    }
  );
  userCreditTypes: string[] = [];
  userPaymentHistory: TransactionSummary[] = [];
  currentUser: PublicUser = null;
  componentDestroy: () => Observable<boolean>;
  @BlockUI()
  blockUI: NgBlockUI;
  id: number;

  constructor(private modalService: NgbModal, private userService: UsersService, private userAdminCommand: UserAdminCommandService,
              private toast: ToastService, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.route.params.pipe(takeUntil(this.componentDestroy()))
      .subscribe(param => {
        this.id = +param['id'];
        this.getUser(this.id);
      });
  }

  checkError(control: string): boolean {
    return this.userForm.controls[control].invalid && this.userForm.controls[control].touched;
  }

  checkValid(control: string): boolean {
    return !this.userForm.controls[control].valid && this.userForm.controls[control].touched;
  }

  openSubscription(sub: PublicSubscription): void {
    const subModalRef = this.modalService.open(UserSubscriptionComponent, {size: 'xs'});
    subModalRef.result.catch((c) => console.log(c)).then((t) => {
      if (t) {
        setTimeout(() => {
          this.getUser(this.id);
        }, 3000);
      }
    });
    subModalRef.componentInstance.editSubModel = sub;
    subModalRef.componentInstance.currentUser = this.currentUser;
    subModalRef.componentInstance.subForm.controls['status'].patchValue(subModalRef.componentInstance.editSubModel.status);
  }

  onSubmit(): void {

  }

  addUserSubscription(): void {

  }

  refund(tranId: string): void {
    this.blockUI.start();
    this.userService.apiAdminUsersRefundPost({
      userId: this.currentUser?.id,
      transactionId: tranId,
      reason: 'return by admin'
    }).pipe(
      finalize(() => this.blockUI.stop()),
      takeUntil(this.componentDestroy())
    ).subscribe(() => {
      this.blockUI.stop();
      this.toast.success('refunded');
      const tr = _.find(this.userPaymentHistory, x => x.transactionId === tranId);
      tr.refundReferenceTransactionId = tranId;
    });

  }

  checkRefunded(t: TransactionSummary): boolean {
    if (this.userPaymentHistory && this.userPaymentHistory.length > 0
      && _.find(this.userPaymentHistory, x => x.refundReferenceTransactionId === t.transactionId)) {
      return true;
    }
    return false;
  }

  removeSubscription(subscriptionId: number): void {
    this.userService.apiAdminUsersCancelSubscriptionImmediatelyPost({
      userId: this.currentUser.id,
      subscriptionId,
      reason: 'cancel by admin'
    }).pipe(
      takeUntil(this.componentDestroy())
    ).subscribe(() => {
      this.toast.success('The Subscription is canceled');
      setTimeout(() => {
        this.getUser(this.id);
      }, 3000);
    });
  }

  getUser(id: number): void {
    if (id) {
      this.blockUI.start('loading user details');
      forkJoin([
        this.userService.apiAdminUsersUserIdGet(id),
        this.userService.apiAdminUsersUserIdPaymentHistoryGet(id)
      ]).pipe(
        finalize(() => {
          this.blockUI.stop();
        }),
        takeUntil(this.componentDestroy()))
        .subscribe((result) => {
          this.blockUI.stop();
          if (result && result.length > 0) {
            this.currentUser = result[0];
            this.userForm.patchValue(this.currentUser);
            this.userPaymentHistory = result[1];
          }
        });
    }
  }


  blockUser(): void {
    if (this.currentUser?.email) {
      const command = {email: this.currentUser.email} as BlockUserCommand;
      this.blockUI.start();
      this.userAdminCommand.apiCommandAdminUserBlockPost(command)
        .pipe(finalize(() => this.blockUI.stop()),
          takeUntil(this.componentDestroy()))
        .subscribe((result) => {
          this.blockUI.stop();
          if (result?.message) {
            this.toast.warning(result.message);
          }
          this.currentUser.isBlocked = result?.isUserBlocked;
        });
    } else {
      this.toast.error('You do not have user email!');
    }

  }

  unBlockUser(): void {
    if (this.currentUser?.email) {
      const command = {email: this.currentUser.email} as UnblockUserCommand;
      this.blockUI.start();
      this.userAdminCommand.apiCommandAdminUserUnblockPost(command)
        .pipe(finalize(() => this.blockUI.stop()),
          takeUntil(this.componentDestroy()))
        .subscribe((result) => {
          this.blockUI.stop();
          if (result?.message) {
            this.toast.warning(result.message);
          }
          this.currentUser.isBlocked = !result?.isUserUnblocked;
        });
    } else {
      this.toast.error('You do not have user email!');
    }
  }

  addCreditToUser(): void {
    if (this.currentUser?.id && this.addCreditForm.valid) {

    } else {
      this.toast.error('You do not have user id');
    }
  }

  isValid(controlName: string): boolean {
    return (this.addCreditForm.controls[controlName].valid && (this.addCreditForm.controls[controlName].dirty ||
        this.addCreditForm.controls[controlName].touched)
    );
  }

  isInValid(controlName: string): boolean {
    return (this.addCreditForm.controls[controlName].invalid && (this.addCreditForm.controls[controlName].dirty ||
        this.addCreditForm.controls[controlName].touched)
    );
  }

  open(content): void {
    if (this.currentUser?.id) {
    } else {
      this.toast.error('The current user not has id');
    }

  }
}
