import {Component, OnInit} from '@angular/core';
import {RaceWagerTypePagination, RaceWagerType, RaceWagerTypeService} from '../swagger-codegen';
import {TakeUntilDestroy} from '../services/take-until-destroy.decorator';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {Observable} from 'rxjs';
import {finalize, takeUntil} from 'rxjs/operators';
import {EntryWagerCustom} from '../wager-type-texts/wager-type-texts.component';
import {ColumnSortedEvent, SortService} from '../sortable-column/sort-service';
import * as _ from 'lodash';

@TakeUntilDestroy
@Component({
  selector: 'app-race-wager-type',
  templateUrl: './race-wager-type.component.html',
  styleUrls: ['./race-wager-type.component.scss']
})
export class RaceWagerTypeComponent implements OnInit {
  @BlockUI()
  blockUI: NgBlockUI;
  selected: RaceWagerType;
  componentDestroy: () => Observable<boolean>;
  public wagerTypes: EntryWagerCustom[];
  currentWagers: RaceWagerTypePagination;
  sortEvent: ColumnSortedEvent = {sortColumn: 'Id', sortDirection: 'desc'};
  pageSize = 100;
  previousPage = 1;
  currentPage = 1;
  totalPages = 0;
  findRaceId = '';

  constructor(private rwtService: RaceWagerTypeService, private sortService: SortService) {
  }

  ngOnInit(): void {
    this.init();
    this.sortEvent = {sortColumn: 'Id', sortDirection: 'desc'};
    this.sortByEvent();
  }

  init(): void {
    this.wagerTypes = [
      {name: 'Exacta', active: false, type: 'Vertical', slotCount: 2},
      {name: 'Trifecta', active: false, type: 'Vertical', slotCount: 3},
      {name: 'Superfecta', active: false, type: 'Vertical', slotCount: 4},
      {name: 'Super High 5', active: false, type: 'Vertical', slotCount: 5},
      {name: 'Daily Double', active: false, type: 'Horizontal', slotCount: 2},
      {name: 'Pick 3', active: false, type: 'Horizontal', slotCount: 3},
      {name: 'Pick 4', active: false, type: 'Horizontal', slotCount: 4},
      {name: 'Pick 5', active: false, type: 'Horizontal', slotCount: 5},
      {name: 'Pick 6', active: false, type: 'Horizontal', slotCount: 6},
    ];
  }

  getWagerName(e: RaceWagerType): string {
    return _.chain(this.wagerTypes).findLast((x) => x.slotCount === e.slotCount && x.type === e.type).value().name;
  }

  public onSorted(event: ColumnSortedEvent): void {
    this.sortEvent = event;
    this.sortByEvent();
  }

  sortByEvent(currentPage: number = 1): void {
    if (this.sortEvent) {
      this.blockUI.start();
      this.rwtService.apiAdminRaceWagerTypeAllGet(this.sortEvent.sortColumn,
          this.sortEvent.sortDirection === 'desc', currentPage, this.pageSize, _.chain(this.findRaceId).trim().toNumber().value())
        .pipe(finalize(this.blockUI.stop), takeUntil(this.componentDestroy()))
        .subscribe((result) => {
          this.currentWagers = result;
          this.currentPage = result.currentPage;
          this.pageSize = result.pageSize;
          this.totalPages = result.totalPages;
          this.sortService.lastSortEvent = this.sortEvent;
        });
    } else if (
      !this.sortEvent &&
      this.sortService.lastSortEvent
    ) {
      this.sortEvent = this.sortService.lastSortEvent;
      this.sortByEvent();
    }
  }

  editItem(e: RaceWagerType): void {
    this.selected = e;
    if (!e) {
      this.sortByEvent();
    }
  }

  pageChanged(newPage: number): void {
    if (newPage !== this.previousPage) {
      this.previousPage = newPage;
      this.sortByEvent(newPage);
    }
  }

  searchByRaceId(raceId: number): void {
    this.findRaceId = _.toString(raceId);
    this.searchByButton();
  }

  clear(): void {
    this.findRaceId = '';
    this.searchByButton();
  }

  searchByButton(): void {
    this.sortEvent = {sortColumn: 'Id', sortDirection: 'desc'};
    this.sortByEvent();
  }
}
