import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {Observable} from 'rxjs';
import {Feature, FeatureAdminService, Meet, PublicTrack} from '../../swagger-codegen';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastService} from '../../services/toast.service';
import {finalize, takeUntil} from 'rxjs/operators';
import {TakeUntilDestroy} from '../../services/take-until-destroy.decorator';

@TakeUntilDestroy
@Component({
  selector: 'app-feature-edit',
  templateUrl: './feature-edit.component.html',
  styleUrls: ['./feature-edit.component.scss']
})
export class FeatureEditComponent implements OnInit {

  @BlockUI()
  blockUI: NgBlockUI;
  componentDestroy: () => Observable<boolean>;
  @Input()
  editFeature: Feature;
  @Output()
  editFinished: EventEmitter<boolean> = new EventEmitter<boolean>();
  featureForm: FormGroup;

  constructor(private featureService: FeatureAdminService, private toast: ToastService) { }

  ngOnInit(): void {
    this.featureForm = (this.editFeature?.id)
      ? new FormGroup({
        id: new FormControl(this.editFeature?.id),
        name: new FormControl(this.editFeature?.name, [Validators.required]),
        description: new FormControl(this.editFeature?.description, [Validators.required]),
        price: new FormControl(this.editFeature?.price),
        isDeleted: new FormControl(this.editFeature?.isDeleted)
      })
      : new FormGroup({
        id: new FormControl(0),
        name: new FormControl('', [Validators.required]),
        description: new FormControl('', [Validators.required]),
        price: new FormControl(0.00),
        isDeleted: new FormControl(false)
      });
  }

  cancel(): void {
    this.editFinished.emit(true);
  }

  save(): void {
    if (this.featureForm.valid) {
      this.blockUI.start();
      // @ts-ignore
      const newFeature: Feature = {
        id: this.featureForm.value.id,
        name: this.featureForm.value.name,
        description: this.featureForm.value.description,
        price: this.featureForm.value.price,
        isDeleted: this.featureForm.value.isDeleted,
      };
      const apiCall = (newFeature.id === 0)
        ? this.featureService.apiAdminFeaturePost(newFeature)
        : this.featureService.apiAdminFeaturePut(newFeature);
      apiCall.pipe(
          finalize(() => {
            this.blockUI.stop();
            this.editFinished.emit(true);
          }),
          takeUntil(this.componentDestroy()))
        .subscribe(data => {
          this.blockUI.stop();
        });
    } else {
      this.toast.error('Please, set all fields');
    }
  }
}
