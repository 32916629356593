<div class="container-fluid">
  <div class="card-header text-center">
    <ng-container *ngIf="!editRaceWagerText?.id">
      <h1>
        Create new RaceWagerText for Race {{editRaceWagerText?.raceId}}
      </h1>
    </ng-container>
    <ng-container *ngIf="editRaceWagerText?.id">
      <h1>Id: {{ editRaceWagerText?.id }}</h1>
      <h1>raceId: {{ editRaceWagerText?.raceId }}</h1>
    </ng-container>
  </div>
  <div class="text-center mt-3">
    <div class="w-50 d-inline-block">
      <div class="mb-3 row" *ngIf="!editRaceWagerText?.id">
        <label for="raceId" class="col-sm-6 offset-2 col-form-label">raceId</label>
        <div class="col-sm-2">
          <input type="number" class="form-control" id="raceId" [(ngModel)]="editRaceWagerText.raceId">
        </div>
      </div>
      <div class="mb-3 row">
        <label for="minimumWagerDenomination" class="offset-2 col-sm-6 col-form-label">MinimumWagerDenomination</label>
        <div class="col-sm-2">
          <select class="form-control" id="minimumWagerDenomination"
                  [(ngModel)]="editRaceWagerText.minimumWagerDenomination">
            <ng-container *ngFor="let item of possibleDenominations">
              <option [value]="item.value">{{item.display}}</option>
            </ng-container>
          </select>
        </div>
      </div>
      <div class="mb-3 row">
        <label for="poolTotal" class="col-sm-6 offset-2 col-form-label">poolTotal</label>
        <div class="col-sm-2">
          <input type="number" class="form-control" id="poolTotal" [(ngModel)]="editRaceWagerText.poolTotal">
        </div>
      </div>
    </div>
  </div>
  <div class="text-center pt-3">
    <ng-container *ngFor="let typeItem of wagerTypes">
      <button
        type="button"
        class="toggleButton btn m-1"
        [ngClass]="{ 'btn-primary': typeItem.active, 'btn-default': !typeItem.active }"
        (click)="setActive(typeItem)">
        {{ typeItem.name }}
      </button>
    </ng-container>
  </div>
  <div class="text-center pt-3">
    <button *ngIf="editRaceWagerText" type="button" class="toggleButton btn btn-secondary mr-3" (click)="cancel()">
      Cancel
    </button>
    <button type="button" class="toggleButton btn btn-info mr-3" (click)="delete()" *ngIf="editRaceWagerText?.id">
      Delete
    </button>
    <button type="button" class="toggleButton btn btn-primary" (click)="save()">
      Save
    </button>
  </div>
</div>
