/**
 * Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import {  HttpHeaders, HttpParams,
          HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AuthTokenResponse } from '../model/authTokenResponse';
import { BasicInfoUpdateArguments } from '../model/basicInfoUpdateArguments';
import { CancelSubscriptionArguments } from '../model/cancelSubscriptionArguments';
import { CurrentSubscriptionStatusResponse } from '../model/currentSubscriptionStatusResponse';
import { ForgotPasswordArguments } from '../model/forgotPasswordArguments';
import { IsEmailInUseResponse } from '../model/isEmailInUseResponse';
import { LoginDto } from '../model/loginDto';
import { LoginUserCommand } from '../model/loginUserCommand';
import { MyAccountResponse } from '../model/myAccountResponse';
import { PasswordUpdateArguments } from '../model/passwordUpdateArguments';
import { PaymentInfoUpdateArguments } from '../model/paymentInfoUpdateArguments';
import { PreUpgradeDto } from '../model/preUpgradeDto';
import { PreUpgradeQuery } from '../model/preUpgradeQuery';
import { PublicProfile } from '../model/publicProfile';
import { ReactivateSubscriptionnArguments } from '../model/reactivateSubscriptionnArguments';
import { RegisterCommand } from '../model/registerCommand';
import { ResetPasswordArgs } from '../model/resetPasswordArgs';
import { SubscribePlanArgs } from '../model/subscribePlanArgs';
import { SubscriptionDowngradeCommand } from '../model/subscriptionDowngradeCommand';
import { SubscriptionUpgradeCommand } from '../model/subscriptionUpgradeCommand';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


import { JtHttp, HttpResponse } from '../../JtHttp';

@Injectable()
export class AdminAccountService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: JtHttp, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminAccountAllActiveSubscriptionsGet(observe?: 'body', reportProgress?: boolean): Observable<Array<CurrentSubscriptionStatusResponse>>;
    public apiAdminAccountAllActiveSubscriptionsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CurrentSubscriptionStatusResponse>>>;
    public apiAdminAccountAllActiveSubscriptionsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CurrentSubscriptionStatusResponse>>>;
    public apiAdminAccountAllActiveSubscriptionsGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<CurrentSubscriptionStatusResponse>>('get',`${this.basePath}/api/Admin/Account/AllActiveSubscriptions`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminAccountBasicInfoPost(body?: BasicInfoUpdateArguments, observe?: 'body', reportProgress?: boolean): Observable<MyAccountResponse>;
    public apiAdminAccountBasicInfoPost(body?: BasicInfoUpdateArguments, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MyAccountResponse>>;
    public apiAdminAccountBasicInfoPost(body?: BasicInfoUpdateArguments, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MyAccountResponse>>;
    public apiAdminAccountBasicInfoPost(body?: BasicInfoUpdateArguments, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MyAccountResponse>('post',`${this.basePath}/api/Admin/Account/BasicInfo`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminAccountCancelSubscriptionPost(body?: CancelSubscriptionArguments, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiAdminAccountCancelSubscriptionPost(body?: CancelSubscriptionArguments, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiAdminAccountCancelSubscriptionPost(body?: CancelSubscriptionArguments, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiAdminAccountCancelSubscriptionPost(body?: CancelSubscriptionArguments, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Admin/Account/CancelSubscription`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminAccountCurrentSubscriptionStatusGet(observe?: 'body', reportProgress?: boolean): Observable<CurrentSubscriptionStatusResponse>;
    public apiAdminAccountCurrentSubscriptionStatusGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CurrentSubscriptionStatusResponse>>;
    public apiAdminAccountCurrentSubscriptionStatusGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CurrentSubscriptionStatusResponse>>;
    public apiAdminAccountCurrentSubscriptionStatusGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CurrentSubscriptionStatusResponse>('get',`${this.basePath}/api/Admin/Account/CurrentSubscriptionStatus`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminAccountDayPassCheckGet(observe?: 'body', reportProgress?: boolean): Observable<PreUpgradeDto>;
    public apiAdminAccountDayPassCheckGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PreUpgradeDto>>;
    public apiAdminAccountDayPassCheckGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PreUpgradeDto>>;
    public apiAdminAccountDayPassCheckGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PreUpgradeDto>('get',`${this.basePath}/api/Admin/Account/DayPassCheck`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminAccountForgotPasswordPost(body?: ForgotPasswordArguments, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiAdminAccountForgotPasswordPost(body?: ForgotPasswordArguments, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiAdminAccountForgotPasswordPost(body?: ForgotPasswordArguments, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiAdminAccountForgotPasswordPost(body?: ForgotPasswordArguments, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Admin/Account/ForgotPassword`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param email 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminAccountIsEmailInUseGet(email?: string, observe?: 'body', reportProgress?: boolean): Observable<IsEmailInUseResponse>;
    public apiAdminAccountIsEmailInUseGet(email?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IsEmailInUseResponse>>;
    public apiAdminAccountIsEmailInUseGet(email?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IsEmailInUseResponse>>;
    public apiAdminAccountIsEmailInUseGet(email?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (email !== undefined && email !== null) {
            queryParameters = queryParameters.set('email', <any>email);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<IsEmailInUseResponse>('get',`${this.basePath}/api/Admin/Account/IsEmailInUse`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminAccountLogoutPost(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiAdminAccountLogoutPost(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiAdminAccountLogoutPost(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiAdminAccountLogoutPost(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/Admin/Account/Logout`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminAccountMyAccountGet(observe?: 'body', reportProgress?: boolean): Observable<MyAccountResponse>;
    public apiAdminAccountMyAccountGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MyAccountResponse>>;
    public apiAdminAccountMyAccountGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MyAccountResponse>>;
    public apiAdminAccountMyAccountGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<MyAccountResponse>('get',`${this.basePath}/api/Admin/Account/MyAccount`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminAccountPasswordLoginPost(body?: LoginUserCommand, observe?: 'body', reportProgress?: boolean): Observable<AuthTokenResponse>;
    public apiAdminAccountPasswordLoginPost(body?: LoginUserCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AuthTokenResponse>>;
    public apiAdminAccountPasswordLoginPost(body?: LoginUserCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AuthTokenResponse>>;
    public apiAdminAccountPasswordLoginPost(body?: LoginUserCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<AuthTokenResponse>('post',`${this.basePath}/api/Admin/Account/PasswordLogin`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminAccountPasswordPut(body?: PasswordUpdateArguments, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiAdminAccountPasswordPut(body?: PasswordUpdateArguments, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiAdminAccountPasswordPut(body?: PasswordUpdateArguments, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiAdminAccountPasswordPut(body?: PasswordUpdateArguments, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/Admin/Account/Password`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminAccountPaymentInfoDelete(observe?: 'body', reportProgress?: boolean): Observable<MyAccountResponse>;
    public apiAdminAccountPaymentInfoDelete(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MyAccountResponse>>;
    public apiAdminAccountPaymentInfoDelete(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MyAccountResponse>>;
    public apiAdminAccountPaymentInfoDelete(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<MyAccountResponse>('delete',`${this.basePath}/api/Admin/Account/PaymentInfo`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminAccountPaymentInfoPut(body?: PaymentInfoUpdateArguments, observe?: 'body', reportProgress?: boolean): Observable<MyAccountResponse>;
    public apiAdminAccountPaymentInfoPut(body?: PaymentInfoUpdateArguments, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MyAccountResponse>>;
    public apiAdminAccountPaymentInfoPut(body?: PaymentInfoUpdateArguments, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MyAccountResponse>>;
    public apiAdminAccountPaymentInfoPut(body?: PaymentInfoUpdateArguments, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MyAccountResponse>('put',`${this.basePath}/api/Admin/Account/PaymentInfo`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminAccountPreUpgradeCheckPost(body?: PreUpgradeQuery, observe?: 'body', reportProgress?: boolean): Observable<PreUpgradeDto>;
    public apiAdminAccountPreUpgradeCheckPost(body?: PreUpgradeQuery, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PreUpgradeDto>>;
    public apiAdminAccountPreUpgradeCheckPost(body?: PreUpgradeQuery, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PreUpgradeDto>>;
    public apiAdminAccountPreUpgradeCheckPost(body?: PreUpgradeQuery, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<PreUpgradeDto>('post',`${this.basePath}/api/Admin/Account/PreUpgradeCheck`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminAccountProfileGet(observe?: 'body', reportProgress?: boolean): Observable<PublicProfile>;
    public apiAdminAccountProfileGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PublicProfile>>;
    public apiAdminAccountProfileGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PublicProfile>>;
    public apiAdminAccountProfileGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PublicProfile>('get',`${this.basePath}/api/Admin/Account/Profile`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminAccountReactivateSubscriptionPost(body?: ReactivateSubscriptionnArguments, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiAdminAccountReactivateSubscriptionPost(body?: ReactivateSubscriptionnArguments, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiAdminAccountReactivateSubscriptionPost(body?: ReactivateSubscriptionnArguments, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiAdminAccountReactivateSubscriptionPost(body?: ReactivateSubscriptionnArguments, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Admin/Account/ReactivateSubscription`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminAccountRegisterPost(body?: RegisterCommand, observe?: 'body', reportProgress?: boolean): Observable<LoginDto>;
    public apiAdminAccountRegisterPost(body?: RegisterCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LoginDto>>;
    public apiAdminAccountRegisterPost(body?: RegisterCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LoginDto>>;
    public apiAdminAccountRegisterPost(body?: RegisterCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<LoginDto>('post',`${this.basePath}/api/Admin/Account/Register`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminAccountResetPasswordPost(body?: ResetPasswordArgs, observe?: 'body', reportProgress?: boolean): Observable<AuthTokenResponse>;
    public apiAdminAccountResetPasswordPost(body?: ResetPasswordArgs, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AuthTokenResponse>>;
    public apiAdminAccountResetPasswordPost(body?: ResetPasswordArgs, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AuthTokenResponse>>;
    public apiAdminAccountResetPasswordPost(body?: ResetPasswordArgs, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<AuthTokenResponse>('post',`${this.basePath}/api/Admin/Account/ResetPassword`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminAccountSubscribePost(body?: SubscribePlanArgs, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiAdminAccountSubscribePost(body?: SubscribePlanArgs, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiAdminAccountSubscribePost(body?: SubscribePlanArgs, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiAdminAccountSubscribePost(body?: SubscribePlanArgs, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Admin/Account/Subscribe`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminAccountSubscriptionDowngradePost(body?: SubscriptionDowngradeCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiAdminAccountSubscriptionDowngradePost(body?: SubscriptionDowngradeCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiAdminAccountSubscriptionDowngradePost(body?: SubscriptionDowngradeCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiAdminAccountSubscriptionDowngradePost(body?: SubscriptionDowngradeCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Admin/Account/SubscriptionDowngrade`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminAccountSubscriptionUpgradePost(body?: SubscriptionUpgradeCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiAdminAccountSubscriptionUpgradePost(body?: SubscriptionUpgradeCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiAdminAccountSubscriptionUpgradePost(body?: SubscriptionUpgradeCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiAdminAccountSubscriptionUpgradePost(body?: SubscriptionUpgradeCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Admin/Account/SubscriptionUpgrade`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
