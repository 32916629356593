<h1 class="text-center">Active Users Report</h1>
<div class="container-fluid">
  <table class="table table-bordered table-striped" sortable-table (sorted)="onSorted($event)" id="excel-table">
    <thead>
    <tr>
      <th colspan="4" class="py-0">
        <div class="input-group my-2">
          <div class="input-group-prepend">
            <div class="input-group-text"><i class="fa fa-search"></i></div>
          </div>
          <input type="text" class="form-control" [formControl]="filterControl" autocomplete="off" placeholder="Search">
        </div>
      </th>
      <th colspan="3" class="text-right">
        <a style="cursor: pointer" class="text-info" (click)="export()">
          Export to csv
        </a>
      </th>
    </tr>
    <tr>
      <th scope="col" sortable-column="id">Id</th>
      <th scope="col" sortable-column="firstName" class="pointer">
        First Name
      </th>
      <th scope="col" sortable-column="lastName" class="pointer">
        Last Name
      </th>
      <th scope="col" sortable-column="email" class="pointer">Email</th>
      <th scope="col" sortable-column="authorizeNetId" class="pointer">
        Authorize.NET ID
      </th>
      <th scope="col" sortable-column="subscriptions.0.name" class="pointer">Subscriptions</th>
      <th scope="col" sortable-column="transactionSummaries.0.amount">Transaction Summaries</th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let user of usersSorted">
      <tr class="pointer" [ngClass]="{'table-danger': user.isActive}">
        <td class="py-1">{{user.id}}</td>
        <td class="py-1">{{user.firstName}}</td>
        <td class="py-1">{{user.lastName}}</td>
        <td class="py-1">{{user.email}}</td>
        <td class="py-1">{{user.authorizeNetId}}</td>
        <td class="py-1">
          <ng-container *ngFor="let sub of user.subscriptions">
            <div class="text-nowrap">
              {{sub.subscriptionCreated |  date: 'MM/dd/yy'}}, {{sub.name}}, {{sub.status}},
              from {{sub.currentPeriodStarted |  date: 'MM/dd/yy' }} to {{sub.currentPeriodEnds |  date: 'MM/dd/yy' }}
            </div>
            <div *ngIf="sub.cancellationReason">
              CancellationReason:"{{sub.cancellationReason}}"
            </div>
          </ng-container>
        </td>
        <td class="py-1">
          <div *ngFor="let tran of user.transactionSummaries" class="text-nowrap">
            {{tran.submitTime | date : 'MM/dd/yy'}}, {{tran.amount | currency}}, {{tran.status}}
          </div>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
  <ngb-pagination [pageSize]="pageSize" [collectionSize]="+colectionSize" class="d-flex justify-content-center"
                  (pageChange)="pageChanged($event)" [rotate]="true" [(page)]="currentPage" [maxSize]="6"
                  [boundaryLinks]="true">
  </ngb-pagination>
</div>
