/**
 * Admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface EntryWagerTypeDto { 
    type?: EntryWagerTypeDto.TypeEnum;
    slotCount?: number;
}
export namespace EntryWagerTypeDto {
    export type TypeEnum = 'Horizontal' | 'Vertical' | 'Stronach5' | 'GoldenHourPick4' | 'GoldenHourDd' | 'CoastToCoast' | 'Win' | 'Place' | 'AllTurfPick3' | 'AllTurfPick4' | 'CrossCountryPick4';
    export const TypeEnum = {
        Horizontal: 'Horizontal' as TypeEnum,
        Vertical: 'Vertical' as TypeEnum,
        Stronach5: 'Stronach5' as TypeEnum,
        GoldenHourPick4: 'GoldenHourPick4' as TypeEnum,
        GoldenHourDd: 'GoldenHourDd' as TypeEnum,
        CoastToCoast: 'CoastToCoast' as TypeEnum,
        Win: 'Win' as TypeEnum,
        Place: 'Place' as TypeEnum,
        AllTurfPick3: 'AllTurfPick3' as TypeEnum,
        AllTurfPick4: 'AllTurfPick4' as TypeEnum,
        CrossCountryPick4: 'CrossCountryPick4' as TypeEnum
    };
}