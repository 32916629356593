<h1 class="text-center">Track Diagrams</h1>
<div class="container">
  <div class="mb-5" *ngFor="let missing of missingTrackDiagrams">

    <div class="row">
      <div style="margin-left: 20px;">
        {{missing.trackName}}
      </div>
      <div style="margin-left: 20px;">
        {{missing.raceDate | date: 'shortDate'}}
      </div>
      <div style="margin-left: 20px;">
        Race {{missing.raceNumber}}
      </div>
      <div style="margin-left: 20px;">
        {{missing.surface}}
      </div>
      <div style="margin-left: 20px;">
        {{missing.distance}}
      </div>
    </div>
    <div class="row">
      <ng-container *ngFor="let diagram of missing.diagramChoices">
        <div class="col pointer diagram" [innerHTML]="diagram.svg | safe: 'html'"
             (click)="selectDiagram(missing, diagram)"
             [ngClass]="{'active':diagram.id===missing.selectedDiagram?.id}"></div>
      </ng-container>
    </div>
    <div class="row">
      <button class="btn btn-success" [disabled]="!missing.selectedDiagram" (click)="save(missing)">Save</button>
    </div>
  </div>
  <div class="row" *ngIf="missingTrackDiagrams.length === 0">
    <h3 class="text-center">All done!</h3>
  </div>
</div>
