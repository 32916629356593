<h1>Settled Transactions Report</h1>
<div class="container-fluid">
  <div class="row">
    <ng-container *ngFor="let record of report?.batchStatistics">

      <div class="card" style="width: 24rem;margin-right:10px;">
        <div class="card-header">
          <div class="row">
            <span
            class="col-md-12 text-center">{{ record?.startSettlementTime | date: 'MM/dd/yyyy' }} - {{ record?.endSettlementTime | date: 'MM/dd/yyyy' }}</span>
          </div>
        </div>
        <div class="card-body">
          <ng-container *ngFor="let statistic of record?.statistics">
            <div class="row">
              <span class="col-md-6">Total Items Sold:&nbsp;</span><span
              class="col-md-6">{{ statistic?.chargeCount | number: '1.0-0' }}</span>
            </div>
            <div class="row">
              <span class="col-md-6">Total Amount:&nbsp;</span><span
              class="col-md-6">${{ statistic?.chargeAmount | number: '1.0-2' }}</span>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</div>
