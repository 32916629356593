import {Component, OnInit} from '@angular/core';
import {ReportingService, ReportUserDto} from 'src/app/swagger-codegen';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {ColumnSortedEvent, SortService} from 'src/app/sortable-column/sort-service';
import {TakeUntilDestroy} from '../../../services/take-until-destroy.decorator';
import {Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, finalize, takeUntil} from 'rxjs/operators';
import {FormControl} from '@angular/forms';
import * as _ from 'lodash';
import {formatDate} from '@angular/common';
import {Functions} from '../../../services/functions';
import {saveAs} from 'file-saver';

@TakeUntilDestroy
@Component({
  selector: 'app-active-users',
  templateUrl: './active-users.component.html',
  styleUrls: ['./active-users.component.scss']
})
export class ActiveUsersComponent implements OnInit {
  @BlockUI()
  blockUI: NgBlockUI;
  previousPage = 1;
  currentPage = 1;
  pageSize = 10;
  users: ReportUserDto[];
  usersSorted: ReportUserDto[];
  usersSortedFiltered: ReportUserDto[];
  sortEvent: ColumnSortedEvent = {sortColumn: 'id', sortDirection: 'asc'};
  componentDestroy: () => Observable<boolean>;
  filterControl = new FormControl();
  colectionSize = 0;
  fileName = 'EquinEdgeReportUser.csv';
  currentUser: ReportUserDto = null;

  constructor(
    private reportingService: ReportingService,
    private sortService: SortService) {
  }

  ngOnInit(): void {
    this.updateUsersList();
    this.filterControl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        takeUntil(this.componentDestroy())
      ).subscribe(() => {
      this.sort(this.currentPage);
    });
  }

  updateUsersList(): void {
    this.blockUI.start();
    this.reportingService.apiAdminReportingUserPaymentHistoryGet().pipe(
      finalize(() => {
        this.blockUI.stop();
      }),
      takeUntil(this.componentDestroy())).subscribe((results) => {
      this.users = results;
      this.sort(1);
    });
  }

  export(): void {
    const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
    const header = Object.keys(this.users[0]);
    const csv = this.users.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');
    const blob = new Blob([csvArray], {type: 'text/csv'});
    saveAs(blob, this.fileName);
  }

  sort(newPage: number): void {
    const startItem = (newPage - 1) * this.pageSize;
    this.usersSorted = _.chain(this.users).filter((x) => {
      if (!this.filterControl?.value?.trim()) {
        return true;
      } else {
        let temp = x.id + ' ' + x.firstName + ' ' + x.lastName + ' ' + x.email + ' ' + x.authorizeNetId;
        if (x.subscriptions && x.subscriptions.length > 0) {
          temp += ' ' + x.subscriptions[0]?.name;
          temp += ' ' + x.subscriptions[0]?.status;
          temp += ' ' + formatDate(x.subscriptions[0]?.subscriptionCreated, 'yyyy-MM-dd', 'en');
          temp += ' ' + formatDate(x.subscriptions[0]?.currentPeriodStarted, 'yyyy-MM-dd', 'en');
          temp += ' ' + formatDate(x.subscriptions[0]?.currentPeriodEnds, 'yyyy-MM-dd', 'en');
        }
        return _.toLower(temp).indexOf(_.toLower(this.filterControl?.value?.trim())) !== -1;
      }
    }).tap((x) => {
      this.colectionSize = x?.length;
    }).orderBy((x) => Functions.typeSort(x, this.sortEvent.sortColumn),
      this.sortEvent.sortDirection).slice(startItem, startItem + this.pageSize).value();
  }

  public onSorted(event: ColumnSortedEvent): void {
    this.sortEvent = event;
    this.sortByEvent();
  }

  sortByEvent(currentPage: number = 1): void {
    if (this.sortEvent && this.users) {
      this.usersSorted = this.usersSortedFiltered = _.chain(this.usersSortedFiltered)
        .orderBy([this.sortEvent.sortColumn], [this.sortEvent.sortDirection])
        .value();
      this.sortService.lastSortEvent = this.sortEvent;
    } else if (
      !this.sortEvent &&
      this.users &&
      this.sortService.lastSortEvent
    ) {
      this.sortEvent = this.sortService.lastSortEvent;
      this.sortByEvent();
    }
    this.currentPage = currentPage;
    this.sort(this.currentPage);
  }

  pageChanged(newPage: number): void {
    if (newPage !== this.previousPage) {
      this.previousPage = newPage;
      this.sort(newPage);
    }
  }
}
