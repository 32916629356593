import {Subject} from 'rxjs';
// How to use it...
// 1. Add "import {TakeUntilDestroy} from '../services/take-until-destroy.decorator';"
//    and   "@TakeUntilDestroy" before your @Component
// 2. Add  field in your class "componentDestroy: () => Observable<boolean>;"
// 3. Add it for all your subscriptions    ".pipe(takeUntil(this.componentDestroy())).subscribe(..."
// 4. important!!! use takeUntil(this.componentDestroy()) in last your pipe.

export function TakeUntilDestroy(constructor: any): any {
  const originalNgDestroy = constructor.prototype.ngOnDestroy;
  constructor.prototype.componentDestroy = function(): any {
    this._takeUntilDestroy = this._takeUntilDestroy || new Subject();
    return this._takeUntilDestroy.asObservable();
  };
  constructor.prototype.ngOnDestroy = function(): any {
    if (this._takeUntilDestroy) {
      this._takeUntilDestroy.next(true);
      this._takeUntilDestroy.complete();
    }
    if (originalNgDestroy && typeof originalNgDestroy === 'function') {
      originalNgDestroy.apply(this, arguments);
    }
  };
}
