<ng-container *ngIf="mForm&&editMarketingChannel">
  <form class="container-fluid" [formGroup]="mForm">
    <div class="card-header text-center">
      <ng-container *ngIf="!editMarketingChannel?.id">
        <h1>
          Create new MarketingChannel
        </h1>
      </ng-container>
      <ng-container *ngIf="editMarketingChannel?.id">
        <h1>MarketingChannel Id: {{ editMarketingChannel?.id }}</h1>
      </ng-container>
    </div>
    <div class="text-center mt-3">
      <div class="w-50 d-inline-block">
        <div class="mb-2 row">
          <label for="code" class="col-sm-6 col-form-label">Code</label>
          <div class="col-sm-6">
            <input type="text" class="form-control" id="code" formControlName="code">
          </div>
        </div>
        <div class="mb-2 row">
          <label for="name" class="col-sm-6 col-form-label">Name</label>
          <div class="col-sm-6">
            <input type="text" class="form-control" id="name" formControlName="name">
          </div>
        </div>
        <div class="mb-2 row" *ngIf="editMarketingChannel?.createdAt">
          <label for="name" class="col-sm-6 col-form-label">CreatedAt</label>
          <div class="col-sm-6">
            {{editMarketingChannel?.createdAt | date : 'short'}}
          </div>
        </div>
        <div class="mb-2 row">
          <label for="isDeleted" class="col-sm-6  col-form-label">IsDeleted</label>
          <div class="col-sm-6">
            <input type="checkbox" class="form-control" id="isDeleted" formControlName="isDeleted">
          </div>
        </div>
      </div>
    </div>
    <div class="text-center py-3">
      <button *ngIf="editMarketingChannel" type="button" class="toggleButton btn btn-secondary mr-3" (click)="cancel()">
        Cancel
      </button>
      <button type="button" class="toggleButton btn btn-primary" (click)="save()">
        Save
      </button>
    </div>
  </form>
</ng-container>

