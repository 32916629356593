import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class SortService {

  public lastSortEvent: ColumnSortedEvent;
  private columnSortedSource = new Subject<ColumnSortedEvent>();
  columnSorted$ = this.columnSortedSource.asObservable();

  constructor() {
  }

  columnSorted(event: ColumnSortedEvent): void {
    this.columnSortedSource.next(event);
  }
}

export interface ColumnSortedEvent {
  sortColumn: string;
  sortDirection?: boolean | 'asc' | 'desc';
}
