<block-ui [delayStop]="200">
  <nav class="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
    <a class="navbar-brand" [routerLink]="['/']">Dashboard</a>
    <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarCollapse"
            aria-controls="navbarCollapse" [attr.aria-expanded]="!isCollapsed" aria-label="Toggle navigation"
            (click)="isCollapsed = !isCollapsed">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="navbar-collapse collapse" id="navbarCollapse" [ngbCollapse]="isCollapsed">
      <ul class="navbar-nav mr-auto">
        <ng-container *ngIf="loginService.isAuthenticated()">
          <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" [routerLink]="['/tracks']">Tracks
              <span class="badge badge-light" *ngIf="dataLeftService?.needsAttentionLeft">{{
                dataLeftService?.needsAttentionLeft - 1
                }}</span>
            </a>
          </li>
          <li class="nav-item">
            <div ngbDropdown class="d-inline-block">
              <a class="nav-link" id="Wager1" ngbDropdownToggle>Wagers</a>
              <div ngbDropdownMenu aria-labelledby="Wager1" class="dropdown-menu">
                <a ngbDropdownItem routerLinkActive="active" [routerLink]="['/wager-type-texts']" class="dropdown-item">Wager-Type-Texts
                  <span class="badge badge-secondary"
                        *ngIf="dataLeftService?.wagerTypeTextsLeft">{{dataLeftService?.wagerTypeTextsLeft - 1}}</span>
                </a>
                <a ngbDropdownItem routerLinkActive="active" [routerLink]="['/wager-type-text-mappings']"
                   class="dropdown-item">
                  Wager-Type-Text-Mappings
                </a>
                <a ngbDropdownItem routerLinkActive="active" [routerLink]="['/race-wager-type']" class="dropdown-item">Race-Wager-Type
                </a>
              </div>
            </div>
          </li>
          <li class="nav-item">
            <div ngbDropdown class="d-inline-block">
              <a class="nav-link" id="track-diagrams" ngbDropdownToggle>Track-Diagrams</a>
              <div ngbDropdownMenu aria-labelledby="track-diagrams" class="dropdown-menu">
                <a ngbDropdownItem routerLinkActive="active" [routerLink]="['/track-diagrams']" class="dropdown-item">Track-Diagrams
                  <span class="badge badge-secondary" *ngIf="dataLeftService?.needsTrackDiagramsLeft">{{
                    dataLeftService?.needsTrackDiagramsLeft
                    }}</span>
                </a>
                <a ngbDropdownItem routerLinkActive="active" [routerLink]="['/course-diagrams']"
                   class="dropdown-item">
                  Course-Diagrams
                </a>
              </div>
            </div>
          </li>
          <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" [routerLink]="['/tote-tracks']">Tote-Tracks
              <span class="badge badge-light"
                    *ngIf="dataLeftService?.needsToteTracksLeft">{{ dataLeftService?.needsToteTracksLeft }}</span>
            </a>
          </li>
          <li class="nav-item">
            <div ngbDropdown class="d-inline-block">
              <a class="nav-link" id="Users" ngbDropdownToggle>Users</a>
              <div ngbDropdownMenu aria-labelledby="Users" class="dropdown-menu">
                <a ngbDropdownItem routerLinkActive="active" class="dropdown-item"
                   [routerLink]="['/users']">Users</a>
                <a ngbDropdownItem routerLinkActive="active" class="dropdown-item" [routerLink]="['/affiliate']">Affiliate</a>
                <a ngbDropdownItem routerLinkActive="active" class="dropdown-item" [routerLink]="['/groups']">Groups</a>
              </div>
            </div>
          </li>
          <li class="nav-item">
            <div ngbDropdown class="d-inline-block">
              <a class="nav-link" id="Marketing" ngbDropdownToggle>Marketing</a>
              <div ngbDropdownMenu aria-labelledby="Marketing" class="dropdown-menu">
                <a ngbDropdownItem routerLinkActive="active" class="dropdown-item"
                   [routerLink]="['/marketing/channels']">MarketingChannels</a>
                <a ngbDropdownItem routerLinkActive="active" class="dropdown-item" [routerLink]="['/promo-codes']">PromoCodes</a>
              </div>
            </div>
          </li>
          <li class="nav-item">
            <div ngbDropdown class="d-inline-block">
              <a class="nav-link" id="Reporting" ngbDropdownToggle>Reporting</a>
              <div ngbDropdownMenu aria-labelledby="Reporting" class="dropdown-menu">
                <a ngbDropdownItem routerLinkActive="active" class="dropdown-item"
                   [routerLink]="['/reporting/users/active-users']">Active Users</a>
                <a ngbDropdownItem routerLinkActive="active" class="dropdown-item"
                   [routerLink]="['/reporting/users/active-users-by-plan']">Active Users By Plan</a>
                <a ngbDropdownItem routerLinkActive="active" [routerLink]="['/promo-code-redemption']"
                   class="dropdown-item">PromoCode Redemptions</a>
                <a ngbDropdownItem routerLinkActive="active" [routerLink]="['/user-current-monthly']"
                   class="dropdown-item">Current Monthly Members</a>
                <a ngbDropdownItem routerLinkActive="active" [routerLink]="['/reporting/finance/settled-transactions']"
                   class="dropdown-item">Settled Transactions</a>
              </div>
            </div>
          </li>
          <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" [routerLink]="['/meets']">Meets</a>
          </li>
          <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" [routerLink]="['/plans']">Plans</a>
          </li>
          <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" [routerLink]="['/plan']">Plan2</a>
          </li>
          <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" [routerLink]="['/features']">Features</a>
          </li>
          <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" [routerLink]="['/logs']">Logs</a>
          </li>
        </ng-container>
      </ul>
      <button class="btn btn-outline-info my-2 my-sm-0" *ngIf="loginService.isAuthenticated()"
              (click)="loginService.logout()">
        LogOut
      </button>
      <button class="btn btn-outline-success my-2 my-sm-0" *ngIf="!loginService.isAuthenticated()"
              [routerLink]="['/login']">Login
      </button>
    </div>
  </nav>
  <main role="main" class="main-container">
    <block-ui>
      <router-outlet></router-outlet>
    </block-ui>
  </main>
</block-ui>
