import {Component} from '@angular/core';
import {LoginService} from './services/login.service';
import {DataLeftService} from './services/data-left.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app';
  public isCollapsed = true;

  constructor(public loginService: LoginService,
              public dataLeftService: DataLeftService) {
  }
}
