<table class="table table-hover" sortable-table (sorted)="onSorted($event)" id="excel-table">
  <thead>
  <tr>
    <th colspan="1">
      <label for="userId"> UserId</label>
    </th>
    <th colspan="2">
      <input type="number" class="form-control" id="userId" aria-describedby="userId" placeholder="Enter userId"
             [(ngModel)]="userId" (keyup.enter)="searchByButton()">
    </th>
    <th colspan="2">
      <label for="userId"> ResponseStatusCode</label>
    </th>
    <th colspan="2">
      <input type="number" class="form-control" id="responseStatusCode" aria-describedby="responseStatusCode"
             placeholder="Enter responseStatusCode"
             [(ngModel)]="responseStatusCode" (keyup.enter)="searchByButton()">
    </th>
    <th colspan="2">
      <button class="btn btn-success" (click)="searchByButton()">Search</button>
    </th>
  </tr>
  <tr>
    <th scope="col" sortable-column="Id" class="pointer">Id</th>
    <th scope="col" sortable-column="Action" class="pointer">action</th>
    <th scope="col" sortable-column="DurationMilliseconds" class="pointer">durationMs</th>
    <th scope="col" sortable-column="Method" class="pointer">method</th>
    <th scope="col" sortable-column="RequestContent" class="pointer">requestContent</th>
    <th scope="col" sortable-column="RequestHeaders" class="pointer">requestHeaders</th>
    <th scope="col" sortable-column="ResponseContent" class="pointer">responseContent</th>
    <th scope="col" sortable-column="ResponseHeaders" class="pointer">responseHeaders</th>
    <th scope="col" sortable-column="ResponseStatusCode" class="pointer">responseStatusCode</th>
    <th scope="col" sortable-column="Time" class="pointer">time</th>
    <th scope="col" sortable-column="UserId" class="pointer">userId</th>
  </tr>
  </thead>
  <tbody>
  <ng-container *ngFor="let item of currentLogs?.result">
    <tr>
      <td class="text-nowrap">{{item.id}}</td>
      <td>{{item.action}}</td>
      <td class="text-nowrap">{{item?.durationMilliseconds  | number : '1.0-0' }}</td>
      <td>{{item.method}}</td>
      <td>
        <app-overflow-text [text]="item.requestContent" [maxLength]="100"
                           [show]="item.requestContent?.length>100"></app-overflow-text>
      </td>
      <td>
        <app-overflow-text [text]="item.requestHeaders" [maxLength]="100"
                           [show]="item.requestHeaders?.length>100"></app-overflow-text>
      </td>
      <td>
        <app-overflow-text [text]="item.responseContent" [maxLength]="100"
                           [show]="item.responseContent?.length>100"></app-overflow-text>
      </td>
      <td>
        <app-overflow-text [text]="item.responseHeaders" [maxLength]="100"
                           [show]="item.responseHeaders?.length>100"></app-overflow-text>
      </td>
      <td class="text-nowrap">{{item.responseStatusCode}}</td>
      <td class="text-nowrap">{{item.time | date : 'short'}}</td>
      <td class="text-nowrap">
        <ng-container *ngIf="item?.user">
          <app-modal [attr.link]="'<u>'+item?.userId+'</u>'">
            <div>
              <table class="table">
                <tbody>
                <tr>
                  <td>id</td>
                  <td>{{item?.user?.id}}</td>
                </tr>
                <tr>
                  <td>email</td>
                  <td>{{item?.user?.email}}</td>
                </tr>
                <tr>
                  <td>firstName</td>
                  <td>{{item?.user?.firstName}}</td>
                </tr>
                <tr>
                  <td>lastName</td>
                  <td>{{item?.user?.lastName}}</td>
                </tr>
                <tr>
                  <td>userName</td>
                  <td>{{item?.user?.userName}}</td>
                </tr>
                <tr>
                  <td>accountType</td>
                  <td>{{item?.user?.accountType}}</td>
                </tr>
                <tr>
                  <td>authorizeNetCustomerProfileId</td>
                  <td>{{item?.user?.authorizeNetCustomerProfileId}}</td>
                </tr>
                <tr>
                  <td>emailConfirmed</td>
                  <td>{{item?.user?.emailConfirmed}}</td>
                </tr>
                <tr>
                  <td>horseNotes</td>
                  <td>{{item?.user?.horseNotes | json}}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </app-modal>
        </ng-container>
        <ng-container *ngIf="!item?.user">
          {{item.userId}}
        </ng-container>
      </td>
    </tr>
  </ng-container>
  </tbody>
</table>
<ng-container *ngIf="currentLogs">
  <ngb-pagination [pageSize]="pageSize" [collectionSize]="currentLogs.totalItems"
                  class="d-flex justify-content-center"
                  (pageChange)="pageChanged($event)" [rotate]="true" [(page)]="currentPage" [maxSize]="10"
                  [boundaryLinks]="true">
  </ngb-pagination>
</ng-container>
