<div class="container-fluid">
  <div class="card-header text-center">
    <ng-container *ngIf="!editEntryWagerText">
      <h1>Remaining: {{ entryWagerText?.length }}</h1>
    </ng-container>
    <ng-container *ngIf="editEntryWagerText?.id!==0">
      <h1 *ngIf="editEntryWagerText?.id">Id: {{ editEntryWagerText?.id }}</h1>
      <h3>Race Number: {{ selected?.raceNumber }}</h3>
      <h3 [innerHTML]="selected?.text"></h3>
    </ng-container>
    <ng-container *ngIf="editEntryWagerText?.id===0">
      <label for="raceNumber">Race Number: </label>
      <input type="number" id="raceNumber" [(ngModel)]="selected.raceNumber" class="form-control">
      <br>
      <label for="text">Text: </label>
      <textarea id="text" class="form-control" [(ngModel)]="selected.text" cols="30" rows="5"></textarea>
    </ng-container>
  </div>
  <div class="text-center pt-3">
    <ng-container *ngFor="let typeItem of wagerTypes">
      <button
        type="button"
        class="toggleButton btn m-1"
        [ngClass]="{ 'btn-primary': typeItem.active, 'btn-default': !typeItem.active }"
        (click)="setActive(typeItem)">
        {{ typeItem.name }}
      </button>
    </ng-container>
  </div>
  <div class="text-center pt-3">
    <button *ngIf="editEntryWagerText" type="button" class="toggleButton btn btn-secondary mr-3" (click)="cancel()">
      Cancel
    </button>
    <button type="button" class="toggleButton btn btn-primary" (click)="save()">
      Save
    </button>
  </div>
</div>
