<ng-container *ngIf="!selected">
  <table class="table table-hover" sortable-table (sorted)="onSorted($event)" id="excel-table">
    <thead>
    <tr>
      <th colspan="4">
        <button class="btn btn-success" (click)="editItem({text:'', entryWagerTypes:[], id:0})">Add New</button>
      </th>
    </tr>
    <tr>
      <th scope="col" sortable-column="Id" class="pointer">Id</th>
      <th scope="col" sortable-column="Text" class="pointer">
        text
      </th>
      <th scope="col" sortable-column="RaceNumber" class="pointer">
        raceNumber
      </th>
      <th scope="col">entryWagerTypes</th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let item of currentMapping?.result">
      <tr (click)="editItem(item)">
        <td class="width-60">{{item.id}}</td>
        <td>{{item.text}}</td>
        <td>{{item.raceNumber}}</td>
        <td>
          <ng-container *ngFor="let w of item.entryWagerTypes">
            <button type="button" class="btn btn-success btn-sm mr-2">{{getWagerName(w)}}</button>
          </ng-container>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
  <ng-container *ngIf="currentMapping">
    <ngb-pagination [pageSize]="pageSize" [collectionSize]="currentMapping.totalItems"
                    class="d-flex justify-content-center"
                    (pageChange)="pageChanged($event)" [rotate]="true" [(page)]="currentPage" [maxSize]="6"
                    [boundaryLinks]="true">
    </ngb-pagination>
  </ng-container>
</ng-container>
<ng-container *ngIf="selected">
  <app-wager-type-texts [editEntryWagerText]="selected" (editFinished)="editItem(null)"></app-wager-type-texts>
</ng-container>
