/* tslint:disable:no-string-literal */
import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs-compat';
import {Router} from '@angular/router';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {ToastService} from './toast.service';
import {AdminAccountService, Configuration, LoginUserCommand} from '../swagger-codegen';
import {JtHttp} from '../JtHttp';


@Injectable()
export class LoginService {
  // Create Auth0 web auth instance
  public loggedIn: boolean;
  public loggedIn$ = new BehaviorSubject<boolean>(false);
  @BlockUI()
  blockUI: NgBlockUI;
  public loginError = '';

  constructor(public router: Router, private toast: ToastService, public accountService: AdminAccountService,
              private apiConfiguration: Configuration, private jtHttp: JtHttp) {
  }

  public login(): void {
    this.router.navigate(['/login']);
  }

  public customLogin(username, password, code: string = null): void {
    this.blockUI.start();
    // const newUser: RegisterWithPasswordArguments = {password: password, email: username, firstName: 'Max', lastName: 'Maltsev'};
    // this.authService.apiAuthRegisterWithPasswordPost(newUser).subscribe((result) => {
    //   console.log(result);
    // });
    this.loginError = '';
    this.jtHttp.clearCache();
    code = code || window.location.href.split('//')[1];
    const arg: LoginUserCommand = {email: username, password, code};
    this.accountService.apiAdminAccountPasswordLoginPost(arg, 'response')
      .subscribe((result) => {
        //  console.log(result.successData.firstName);
        this.blockUI.stop();
        if (result && result.isSuccess && result.successData.authBearerToken) {
          localStorage.removeItem('blocknavigation');
          this.setSession(result.successData.authBearerToken);
          localStorage['email'] = username;
          if (localStorage.getItem('callback_url')) {
            const url = JSON.parse(localStorage.getItem('callback_url'));
            localStorage.removeItem('callback_url');
            this.router.navigate(url['route'], {
              queryParams: url['queryParams']
            });
            return;
          } else {
            this.router.navigate(['/']);
          }
        } else {
          this.loginError = result.errorHumanReadableMessage;
        }
      });
  }

  loginWithGoogle(): void {
  }

  loginWithTwitter(): void {
  }

  loginWithFacebook(): void {
  }


  public logout(): void {
    this.accountService.apiAdminAccountLogoutPost().subscribe((result) => {
      this.clerLocalStorage();
      this.deleteCookie('access_token');
      this.router.navigate(['/login']);
    });
  }

  public clerLocalStorage(): void {
    // Remove tokens and expiry time from localStorage
    localStorage.removeItem('access_token');
    localStorage.removeItem('blocknavigation');
    // Go back to the home route
    this._setLoggedIn(false);
  }

  public isAuthenticated(): boolean {
    // Check whether the current time is past the
    // access token's expiry time
    if (localStorage.getItem('access_token')) {
      return true;
    }
    return false;
  }


  public changePassword(email): void {
  }

  public IsEmptyObject(obj): boolean {
    return !(!!obj ? !Object.keys(obj).length : true);
  }

  private _setLoggedIn(value: boolean): void {
    // Update login status subject
    this.loggedIn$.next(value);
    this.loggedIn = value;
  }

  private setSession(authResult: string): void {
    localStorage.setItem('access_token', authResult);
    this.apiConfiguration.apiKeys = {['Authorization']: 'Bearer ' + authResult};
    this._setLoggedIn(true);
  }

  deleteCookie(name: string): void {
    const expires = 'expires=Thu, 01 Jan 1970 00:00:00 GMT';
    document.cookie = `${name}=;${expires};`;
  }
}
