import {Component, OnInit} from '@angular/core';
import {MissingTrackDiagrams, PublicCourseDiagram, SaveArguments, TrackDiagramsService} from '../swagger-codegen';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {DataLeftService} from '../services/data-left.service';
import {TakeUntilDestroy} from '../services/take-until-destroy.decorator';
import {Observable} from 'rxjs';
import {finalize, takeUntil} from 'rxjs/operators';

@TakeUntilDestroy
@Component({
  selector: 'app-track-diagrams',
  templateUrl: './track-diagrams.component.html',
  styleUrls: ['./track-diagrams.component.scss']
})
export class TrackDiagramsComponent implements OnInit {
  missingTrackDiagrams: MissingTrackDiagrams[] = [];
  @BlockUI()
  blockUI: NgBlockUI;
  componentDestroy: () => Observable<boolean>;

  constructor(public trackDiagramsService: TrackDiagramsService, public dataLeftService: DataLeftService) {
  }

  ngOnInit(): void {
    this.updateTracks();
  }

  updateTracks(): void {
    this.blockUI.start();
    this.trackDiagramsService.apiAdminTrackDiagramsMissingGet()
      .pipe(
        finalize(() => {
          this.blockUI.stop();
        }),
        takeUntil(this.componentDestroy()))
      .subscribe(missings => {
        this.missingTrackDiagrams = missings;
        this.dataLeftService.needsTrackDiagramsLeft = this.missingTrackDiagrams.length;
        for (const missing of this.missingTrackDiagrams) {
          missing.diagramChoices = [];
          this.trackDiagramsService
            .apiAdminTrackDiagramsCourseDiagramsGet(missing.courseType)
            .pipe(
              finalize(() => {
                this.blockUI.stop();
              }),
              takeUntil(this.componentDestroy()))
            .subscribe(diagrams => {
              missing.diagramChoices = diagrams;
            });
        }
      });
  }

  selectDiagram(missing: MissingTrackDiagrams, diagram: PublicCourseDiagram): void {
    missing.selectedDiagram = diagram;
  }

  save(missing: MissingTrackDiagrams): void {
    this.blockUI.start('Saving');
    const data: SaveArguments = {
      trackId: missing.trackId,
      courseType: missing.courseType,
      distanceUnit: missing.distanceUnit,
      distanceId: missing.distanceId,
      courseDiagramId: missing.selectedDiagram.id
    };
    this.trackDiagramsService.apiAdminTrackDiagramsSavePost(data)
      .pipe(
        finalize(() => {
          this.blockUI.stop();
        }),
        takeUntil(this.componentDestroy()))
      .subscribe(() => {
        this.missingTrackDiagrams = this.missingTrackDiagrams
          .filter(d => d !== missing);
        this.dataLeftService.needsTrackDiagramsLeft = this.missingTrackDiagrams.length;
      });
  }
}


declare module '../swagger-codegen/model/missingTrackDiagrams' {
  interface MissingTrackDiagrams {
    diagramChoices?: PublicCourseDiagram[];
    selectedDiagram?: PublicCourseDiagram;
  }
}
