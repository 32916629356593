<h1 class="text-center"> Tracks</h1>
<div class="container-fluid">
  <table class="table table-hover" sortable-table (sorted)="onSorted($event)">
    <thead>
    <tr>
      <th colspan="4">
      </th>
      <th colspan="9">
        <div class="input-group mb-2">
          <div class="input-group-prepend">
            <div class="input-group-text"><i class="fa fa-search"></i></div>
          </div>
          <input type="text" class="form-control" [(ngModel)]="filter" placeholder="Search by Abbreviation and Name"
                 (ngModelChange)="search()">
        </div>
      </th>
    </tr>
    <tr>
      <th scope="col" sortable-column="id">Id</th>
      <th scope="col" sortable-column="equibaseCountry">Country</th>
      <th scope="col" sortable-column="abbreviation">Abbreviation</th>
      <th scope="col" sortable-column="name">Name</th>
      <th scope="col" sortable-column="timezoneId">Timezone</th>
      <th scope="col" sortable-column="shouldShow">Should Show</th>
      <th scope="col" sortable-column="minimumDenominations">minimumDenominations</th>
      <th scope="col" sortable-column="trackAddress">trackAddress</th>
      <th scope="col" sortable-column="trackCity">trackCity</th>
      <th scope="col" sortable-column="trackState">trackState</th>
      <th scope="col" sortable-column="trackCountry">trackCountry</th>
      <th scope="col" sortable-column="weatherUrl">weatherUrl</th>
      <th scope="col" sortable-column="checkGeolocation">checkGeolocation</th>
      <th scope="col" sortable-column="latitude">latitude</th>
      <th scope="col" sortable-column="longitude">longitude</th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let item of tracksSorted">
      <tr [ngClass]="{'table-danger': item.needsAttention}" (click)="editTrack(item.id)">
        <td>{{item.id}}</td>
        <td>{{item.equibaseCountry}}</td>
        <td>{{item.abbreviation}}</td>
        <td>{{item.name}}</td>
        <td>{{item.timezoneId}}</td>
        <td>{{item.shouldShow ? 'Yes' : 'No'}}</td>
        <td [innerHTML]="getWagerString(item.minimumDenominations)">
        </td>
        <td>{{item.trackAddress}}</td>
        <td>{{item.trackCity}}</td>
        <td>{{item.trackState}}</td>
        <td>{{item.trackCountry}}</td>
        <td>{{item.weatherUrl}}</td>
        <td>{{item.checkGeolocation}}</td>
        <td>{{item.latitude}}</td>
        <td>{{item.longitude}}</td>
      </tr>
    </ng-container>
    </tbody>
  </table>
  <ngb-pagination [pageSize]="pageSize" [collectionSize]="tracks?.length" class="d-flex justify-content-center"
                  (pageChange)="pageChanged($event)" [rotate]="true" [(page)]="currentPage" [maxSize]="6"
                  [boundaryLinks]="true"></ngb-pagination>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Track update № {{trackForm?.controls?.id?.value}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="trackForm" (ngSubmit)="onSubmit()" autocomplete="off">
      <input type="hidden" formControlName="id">
      <div class="form-group">
        <label for="equibaseCountry">equibaseCountry</label>
        <div class="input-group">
          <input id="equibaseCountry" class="form-control  form-control-sm" type="text" readonly
                 formControlName="equibaseCountry"
                 [ngClass]="{'is-invalid':checkError('equibaseCountry')}">
        </div>
      </div>
      <div class="form-group">
        <label for="equibaseTrackId">equibaseTrackId</label>
        <div class="input-group">
          <input id="equibaseTrackId" class="form-control  form-control-sm" type="text" readonly
                 formControlName="equibaseTrackId"
                 [ngClass]="{'is-invalid':checkError('equibaseTrackId')}">
        </div>
      </div>
      <div class="form-group">
        <label for="name">name</label>
        <div class="input-group">
          <input id="name" class="form-control  form-control-sm" type="text" formControlName="name"
                 [ngClass]="{'is-invalid':checkError('name')}" autocomplete="chrome-off">
        </div>
      </div>
      <div class="form-group">
        <label for="abbreviation">abbreviation</label>
        <div class="input-group">
          <input id="abbreviation" class="form-control  form-control-sm" type="text" formControlName="abbreviation"
                 [ngClass]="{'is-invalid':checkError('abbreviation')}" autocomplete="chrome-off">
        </div>
      </div>
      <div class="form-group">
        <label for="timezoneId">timezone</label>
        <div class="input-group">
          <select class="form-control  form-control-sm" id="timezoneId" formControlName="timezoneId"
                  [ngClass]="{'is-invalid':checkError('timezoneId')}">
            <option value=""></option>
            <ng-container *ngFor="let item of timeZones">
              <option [value]="item.id">{{item.display}}</option>
            </ng-container>
          </select>
        </div>
      </div>
      <div class="form-group">
        <label for="trackAddress">trackAddress</label>
        <div class="input-group">
          <input id="trackAddress" class="form-control  form-control-sm" type="text" formControlName="trackAddress"
                 [ngClass]="{'is-invalid':checkError('trackAddress')}" autocomplete="chrome-off">
        </div>
      </div>
      <div class="form-group">
        <label for="trackCity">trackCity</label>
        <div class="input-group">
          <input id="trackCity" class="form-control  form-control-sm" type="text" formControlName="trackCity"
                 [ngClass]="{'is-invalid':checkError('trackCity')}" autocomplete="chrome-off">
        </div>
      </div>
      <div class="form-group">
        <label for="trackState">trackState</label>
        <div class="input-group">
          <input id="trackState" class="form-control  form-control-sm" type="text" formControlName="trackState"
                 [ngClass]="{'is-invalid':checkError('trackState')}" autocomplete="chrome-off">
        </div>
      </div>
      <div class="form-group">
        <label for="trackCountry">trackCountry</label>
        <div class="input-group">
          <input id="trackCountry" class="form-control  form-control-sm" type="text" formControlName="trackCountry"
                 [ngClass]="{'is-invalid':checkError('trackCountry')}" autocomplete="chrome-off">
        </div>
      </div>
      <div class="form-group">
        <label for="weatherUrl">weatherUrl</label>
        <a href="https://weatherwidget.io/" class="ml-2" target="_blank">for url search use
          https://weatherwidget.io/</a>
        <div class="input-group">
          <input id="weatherUrl" class="form-control  form-control-sm" type="text" formControlName="weatherUrl"
                 [ngClass]="{'is-invalid':checkError('weatherUrl')}" autocomplete="chrome-off">
        </div>
      </div>
      <div class="form-group form-check">
        <input type="checkbox" id="checkGeolocation" class="form-check-input" formControlName="checkGeolocation"
               [ngClass]="{'is-invalid':checkError('checkGeolocation')}">
        <label class="form-check-label pointer" for="checkGeolocation">checkGeolocation</label>
      </div>
      <div class="form-group">
        <label for="latitude">latitude</label>
        <div class="input-group">
          <input id="latitude" class="form-control  form-control-sm" type="text" formControlName="latitude"
                 [ngClass]="{'is-invalid':checkError('latitude')}" autocomplete="chrome-off">
        </div>
      </div>
      <div class="form-group">
        <label for="longitude">longitude</label>
        <div class="input-group">
          <input id="longitude" class="form-control  form-control-sm" type="text" formControlName="longitude"
                 [ngClass]="{'is-invalid':checkError('longitude')}" autocomplete="chrome-off">
        </div>
      </div>
      <div class="form-group form-check">
        <input type="checkbox" id="shouldShow" class="form-check-input" formControlName="shouldShow"
               [ngClass]="{'is-invalid':checkError('shouldShow')}">
        <label class="form-check-label pointer" for="shouldShow">Should Show</label>
      </div>
      <input type="hidden" formControlName="needsAttention">
    </form>
    <div class="form-group">
      <div class="row">
        <ng-container *ngFor="let typeItem of wagerTypes;">
          <div class="col-6 text-right col-md-3">
            <div class="d-inline-block alert alert-dark  p-2 mb-2">
              {{typeItem.name}}
            </div>
          </div>
          <div class="col-6 text-left col-md-3">
            <select [(ngModel)]="typeItem.minimumWager" class="form-control p-2">
              <option value="null" *ngIf="!typeItem.active"></option>
              <ng-container *ngFor="let item of minimumWagers">
                <option [value]="item.value">{{item.text}}</option>
              </ng-container>
            </select>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="text-center">
      <button class="btn" (click)="onSubmit()"
              [ngClass]="{'btn-outline-dark':trackForm.invalid,'btn-outline-success':trackForm.valid }">Save
      </button>
    </div>
  </div>
</ng-template>
