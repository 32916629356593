<ng-container *ngIf="!selected">
  <h1 class="text-center">Course Diagrams</h1>
  <table class="table table-hover" sortable-table (sorted)="onSorted($event)" id="excel-table">
    <thead>
    <tr>
      <th colspan="4">
        <button class="btn btn-success" (click)="editItem({})">Add New</button>
      </th>
    </tr>
    <tr>
      <th scope="col" sortable-column="id" class="pointer">id</th>
      <th scope="col" sortable-column="surface" class="pointer">
        surface
      </th>
      <th scope="col" sortable-column="sortOrder" class="pointer">
        sortOrder
      </th>
      <th scope="col" sortable-column="svg" class="pointer">
        svg
      </th>
      <th>actions</th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let item of list">
      <tr>
        <td class="width-60">{{item.id}}</td>
        <td>{{item.surface}}</td>
        <td>{{item.sortOrder}}</td>
        <td>
          <div *ngIf="item.svg" [innerHTML]="item.svg | safe: 'html'" class="diagram"></div>
        </td>
        <td>
          <button class="btn btn-success" (click)="editItem(item)">Edit</button>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
</ng-container>
<ng-container *ngIf="selected">
  <app-course-diagram-edit [editItem]="selected" (editFinished)="finishedEdit($event)"></app-course-diagram-edit>
</ng-container>
