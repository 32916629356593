import {WagerTypeTextsService} from './../swagger-codegen/api/wagerTypeTexts.service';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {EntryWagerText, EntryWagerTypeDto, WagerTypeTextMapping} from '../swagger-codegen';
import {ToastService} from '../services/toast.service';
import {Router} from '@angular/router';
import {DataLeftService} from '../services/data-left.service';
import {TakeUntilDestroy} from '../services/take-until-destroy.decorator';
import {Observable} from 'rxjs';
import {finalize, takeUntil} from 'rxjs/operators';
import * as _ from 'lodash';

@TakeUntilDestroy
@Component({
  selector: 'app-wager-type-texts',
  templateUrl: './wager-type-texts.component.html',
  styleUrls: ['./wager-type-texts.component.scss'],
})
export class WagerTypeTextsComponent implements OnInit {
  @BlockUI()
  blockUI: NgBlockUI;
  selected: EntryWagerText;
  entryWagerText: EntryWagerText[];
  componentDestroy: () => Observable<boolean>;
  entryWager: Array<EntryWagerTypeDto>;
  public wagerTypes: EntryWagerCustom[];
  @Input()
  editEntryWagerText: WagerTypeTextMapping;
  @Output()
  editFinished: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private wtService: WagerTypeTextsService,
    private toast: ToastService,
    public router: Router,
    public dataLeftService: DataLeftService
  ) {
  }

  init(): void {
    this.wagerTypes = [
      {name: 'Exacta', active: false, type: EntryWagerTypeDto.TypeEnum.Vertical, slotCount: 2},
      {name: 'Trifecta', active: false, type: EntryWagerTypeDto.TypeEnum.Vertical, slotCount: 3},
      {name: 'Superfecta', active: false, type: EntryWagerTypeDto.TypeEnum.Vertical, slotCount: 4},
      {name: 'Super High 5', active: false, type: EntryWagerTypeDto.TypeEnum.Vertical, slotCount: 5},
      {name: 'Daily Double', active: false, type: EntryWagerTypeDto.TypeEnum.Horizontal, slotCount: 2},
      {name: 'Pick 3', active: false, type: EntryWagerTypeDto.TypeEnum.Horizontal, slotCount: 3},
      {name: 'Pick 4', active: false, type: EntryWagerTypeDto.TypeEnum.Horizontal, slotCount: 4},
      {name: 'Pick 5', active: false, type: EntryWagerTypeDto.TypeEnum.Horizontal, slotCount: 5},
      {name: 'Pick 6', active: false, type: EntryWagerTypeDto.TypeEnum.Horizontal, slotCount: 6},
    ];
  }

  setActive(r: EntryWagerCustom): void {
    r.active = !r.active;
    r.minimumWager = null;
  }

  ngOnInit(): void {
    if (this.editEntryWagerText) {
      this.entryWagerText = [{raceNumber: this.editEntryWagerText.raceNumber, text: this.editEntryWagerText.text}];
      this.showNext();
      this.init();
      this.editEntryWagerText.entryWagerTypes.forEach((x) => {
        this.wagerTypes.forEach((b) => {
          if (b.slotCount === x.slotCount && b.type === x.type) {
            b.active = true;
          }
        });
      });
    } else {
      this.blockUI.start();
      this.wtService.apiAdminWagerTypeTextsGet('response')
        .pipe(
          finalize(() => {
            this.blockUI.stop();
          }),
          takeUntil(this.componentDestroy()))
        .subscribe(result => {
          if (result.isSuccess) {
            this.entryWagerText = result.successData;
            this.showNext();
            this.init();
          } else {
            if (result.errorCode === 'Unauthenticated' || result.errorCode === 'Unauthorized') {
              this.toast.error(result.errorHumanReadableMessage);
              this.router.navigate(['/login']);
              return;
            }
            if (result.errorHumanReadableMessage) {
              this.toast.error(result.errorHumanReadableMessage);
            } else {
              this.toast.error('unknown server error. You\'ll try later');
            }
            return;
          }
        });
    }
  }

  showNext(): void {
    if (this.entryWagerText && this.entryWagerText.length > 0) {
      const next = this.entryWagerText.pop();
      next.text = this.chunkString(next.text, 55).join('<br/>');
      this.selected = next;
      if (!this.editEntryWagerText) {
        this.dataLeftService.wagerTypeTextsLeft = this.entryWagerText.length;
      }
    } else {
      if (this.editEntryWagerText) {
        this.editFinished.emit(true);
      }
      this.selected = null;
    }
  }

  cancel(): void {
    this.editFinished.emit(true);
  }

  save(): void {
    if (this.selected) {
      this.selected.mappedEntryWagerTypes = [];
      this.wagerTypes.forEach(element => {
        if (element.active) {
          this.selected.mappedEntryWagerTypes.push({
            type: element.type,
            slotCount: element.slotCount,
          });
        }
      });
      if (!_.trim(this.selected.text)) {
        this.toast.error('Please, fill the text');
        return;
      }
      if (this.editEntryWagerText && this.editEntryWagerText?.id === 0) {
        this.selected.text = _.trim(this.selected.text);
      }
      this.blockUI.start();
      this.selected.text = this.selected.text.replace(/\<br\/\>/g, ' ');
      this.wtService.apiAdminWagerTypeTextsPost(this.selected)
        .pipe(
          finalize(() => {
            this.blockUI.stop();
          }),
          takeUntil(this.componentDestroy()))
        .subscribe(data => {
          this.blockUI.stop();
          this.showNext();
          this.init();
        });
    }
  }

  chunkString(str: string, len: number): string[] {
    const input = str.trim().split(' ');
    // tslint:disable-next-line:prefer-const
    let [index, output] = [0, []];
    output[index] = '';
    input.forEach(word => {
      const temp = `${output[index]} ${word}`.trim();
      if (temp.length <= len) {
        output[index] = temp;
      } else {
        index++;
        output[index] = word;
      }
    });
    return output;
  }
}

export interface EntryWagerCustom {
  name: string;
  active: boolean;
  type?: EntryWagerTypeDto.TypeEnum;
  slotCount?: number;
  minimumWager?: number;
}
