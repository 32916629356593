<ng-container *ngIf="planForm&&editPlan">
  <form class="container-fluid" [formGroup]="planForm">
    <div class="card-header text-center">
      <div class="right">
        <button type="button" class="toggleButton btn btn-secondary mr-3" (click)="cancel()">Back To List</button>
      </div>
      <ng-container *ngIf="!editPlan?.id">
        <h1>
          Create new Plan
        </h1>
      </ng-container>
      <ng-container *ngIf="editPlan?.id">
        <h1>Plan Id: {{ editPlan?.id }}</h1>
      </ng-container>
    </div>
    <div class="text-center mt-3">
      <div class="w-50 d-inline-block">
        <div class="mb-2 row">
          <label for="Code" class="col-sm-6 col-form-label">Code</label>
          <div class="col-sm-6">
            <input type="text" class="form-control" id="Code" formControlName="code">
          </div>
        </div>
        <div class="mb-2 row">
          <label for="Name" class="col-sm-6 col-form-label">Name</label>
          <div class="col-sm-6">
            <input type="text" class="form-control" id="Name" formControlName="name">
          </div>
        </div>
        <div class="mb-2 row">
          <label for="Description" class="col-sm-6 col-form-label">Description</label>
          <div class="col-sm-6">
            <input type="text" class="form-control" id="Description" formControlName="description">
          </div>
        </div>
        <div class="mb-2 row">
          <label for="Amount" class="col-sm-6 col-form-label">Amount</label>
          <div class="col-sm-6">
            <input type="number" class="form-control" id="Amount" formControlName="amount">
          </div>
        </div>
        <div class="mb-2 row">
          <label for="Type" class="col-sm-6 col-form-label">Type</label>
          <div class="col-sm-6">
            <select class="form-control" id="Type" formControlName="type">
              <ng-container *ngFor="let type of planTypes">
                <option value="{{type}}">{{type}}</option>
              </ng-container>
            </select>
          </div>
        </div>
        <td>

        </td>
        <div class="mb-2 row">
          <label for="DaysBeforeRenewalOrExpiration" class="col-sm-6 col-form-label">DaysBeforeRenewalOrExpiration</label>
          <div class="col-sm-6">
            <input type="number" class="form-control" id="DaysBeforeRenewalOrExpiration" formControlName="daysBeforeRenewalOrExpiration">
          </div>
        </div>
        <div class="mb-2 row">
          <label for="ShouldAutoRenew" class="col-sm-6  col-form-label">Should AutoRenew?</label>
          <div class="col-sm-6">
            <input type="checkbox" class="form-control" id="ShouldAutoRenew" formControlName="shouldAutoRenew">
          </div>
        </div>
        <div class="mb-2 row">
          <label for="CanSell" class="col-sm-6  col-form-label">Can Sell?</label>
          <div class="col-sm-6">
            <input type="checkbox" class="form-control" id="CanSell" formControlName="canSell">
          </div>
        </div>
        <div class="mb-2 row">
          <label for="IsDeleted" class="col-sm-6  col-form-label">Is Deleted?</label>
          <div class="col-sm-6">
            <input type="checkbox" class="form-control" id="IsDeleted" formControlName="isDeleted">
          </div>
        </div>
        <div class="mb-2 row">
          <label for="Meet" class="col-sm-6 col-form-label">Search meet</label>
          <div class="col-sm-6">

            <ng-select id="Meet" [items]="meets$ | async" class="border-1"
                       bindLabel="name"
                       [hideSelected]="true"
                       [trackByFn]="meetByFn"
                       bindValue="m"
                       [minTermLength]="3"
                       [loading]="meetsLoading"
                       typeToSearchText="Please enter 3 or more characters"
                       [typeahead]="meetsInput$"
                       (change)="setMeet($event)">
            </ng-select>

          </div>
        </div>
        <div class="mb-2 row">
          <label for="MeetId" class="col-sm-6 col-form-label">MeetId</label>
          <div class="col-sm-6">
            <input type="number" class="form-control" id="MeetId" formControlName="meetId" readonly>
          </div>
        </div>
        <div class="text-center py-3">
          <button *ngIf="editPlan" type="button" class="toggleButton btn btn-secondary mr-3" (click)="cancel()">
            Cancel
          </button>
          <button type="button" class="toggleButton btn btn-primary" (click)="save()">
            Save
          </button>
        </div>
      </div>
      <br/>
      <div class="form-group">
        <div class="left">
          <button type="button" class="btn btn-outline-success" (click)="addPlanClaim()">Add Claim</button>
        </div>
        <br/>
        <div class="row">
          <div class="col-md-1 text-center"></div>
          <div class="col-md-2 text-center">Type</div>
          <div class="col-md-2 text-center">Value</div>
          <div class="col-md-2 text-center">Name</div>
          <div class="col-md-1 text-center">Price</div>
          <div class="col-md-1 text-center">Int Val</div>
          <div class="col-md-1 text-center">Decimal Val</div>
          <div class="col-md-1 text-center">Bool Val</div>
        </div>
        <div formArrayName="claims" *ngFor="let claim of planClaims.controls; let i = index">
          <ng-container [formGroupName]="i">
            <div class="row">
              <div class="col-md-1">
                <button type="button" class="btn btn-outline-danger" (click)="removePlanClaim(i)">X</button>
              </div>
              <div class="col-md-2">
                <select class="form-control-sm" formControlName="claimType">
                  <option *ngFor="let opt of planClaimTypes" [value]="opt">{{opt}}</option>
                </select>
              </div>
              <ng-container *ngIf="getClaimType(i) === 'Feature'">
                <div class="col-md-2">
                  <ng-select #select class="form-control p-0 pointer custom" formControlName="claimValue"
                           [searchable]="false" [clearable]="false" [items]="features">
                  </ng-select>
                </div>
              </ng-container>
              <ng-container *ngIf="getClaimType(i) !== 'Feature'">
                <div class="col-md-2">
                  <input type="text" class="form-control" id="ClaimValue" formControlName="claimValue">
                </div>
              </ng-container>
              <div class="col-md-2">
                <input type="text" class="form-control" id="DisplayName" formControlName="displayName">
              </div>
              <div class="col-md-1">
                <input type="number" class="form-control" id="Price" formControlName="price">
              </div>
              <div class="col-md-1">
                <input type="number" class="form-control" id="ClaimInt" formControlName="claimInt">
              </div>
              <div class="col-md-1">
                <input type="number" class="form-control" id="ClaimDecimal" formControlName="claimDecimal">
              </div>
              <div class="col-md-1">
                <input type="checkbox" class="form-control" id="ClaimBool" formControlName="claimBool">
              </div>
            </div>
            <br/>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="text-center py-3">
      <button *ngIf="editPlan" type="button" class="toggleButton btn btn-secondary mr-3" (click)="cancel()">
        Cancel
      </button>
      <button type="button" class="toggleButton btn btn-primary" (click)="save()">
        Save
      </button>
    </div>
  </form>
</ng-container>

