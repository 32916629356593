import {Component, OnInit} from '@angular/core';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {Observable} from 'rxjs';
import {LogsService, LogPagination} from '../../swagger-codegen';
import {ColumnSortedEvent, SortService} from '../../sortable-column/sort-service';
import {finalize, takeUntil} from 'rxjs/operators';
import {TakeUntilDestroy} from '../../services/take-until-destroy.decorator';

@TakeUntilDestroy
@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.scss']
})
export class LogComponent implements OnInit {
  @BlockUI()
  blockUI: NgBlockUI;
  componentDestroy: () => Observable<boolean>;
  public currentLogs: LogPagination;
  sortEvent: ColumnSortedEvent;
  pageSize = 100;
  previousPage = 1;
  currentPage = 1;
  totalPages = 0;
  searchCode = null;

  constructor(private ls: LogsService, private sortService: SortService) {
  }

  ngOnInit(): void {
    this.sortEvent = {sortColumn: 'Id', sortDirection: 'desc'};
    this.sortByEvent();
  }

  searchByButton(): void {
    this.sortEvent = {sortColumn: 'Id', sortDirection: 'desc'};
    this.sortByEvent();
  }

  public onSorted(event: ColumnSortedEvent): void {
    this.sortEvent = event;
    this.sortByEvent();
  }

  sortByEvent(currentPage: number = 1): void {
    if (this.sortEvent) {
      this.blockUI.start();
      if (this.searchCode && this.searchCode.trim()) {
        this.searchCode = this.searchCode.trim();
      } else {
        this.searchCode = null;
      }
      this.ls.apiAdminLogsLogsAllGet(this.sortEvent.sortColumn,
        this.sortEvent.sortDirection === 'desc', currentPage, this.pageSize, this.searchCode).pipe(
        finalize(this.blockUI.stop),
        takeUntil(this.componentDestroy()))
        .subscribe((result) => {
          this.currentLogs = result;
          this.currentPage = result.currentPage;
          this.pageSize = result.pageSize;
          this.totalPages = result.totalPages;
          this.sortService.lastSortEvent = this.sortEvent;
        });

    } else if (
      !this.sortEvent &&
      this.sortService.lastSortEvent
    ) {
      this.sortEvent = this.sortService.lastSortEvent;
      this.sortByEvent();
    }
  }

  pageChanged(newPage: number): void {
    if (newPage !== this.previousPage) {
      this.previousPage = newPage;
      this.sortByEvent(newPage);
    }
  }
}
