import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {Observable, of} from 'rxjs';
import {TakeUntilDestroy} from '../../services/take-until-destroy.decorator';
import {MarketingChannel, MarketingChannelsService} from '../../swagger-codegen';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {finalize, takeUntil} from 'rxjs/operators';
import {ToastService} from '../../services/toast.service';
import _ from 'lodash';

@TakeUntilDestroy
@Component({
  selector: 'app-marketing-channel-edit',
  templateUrl: './marketing-channel-edit.component.html',
  styleUrls: ['./marketing-channel-edit.component.scss']
})
export class MarketingChannelEditComponent implements OnInit {
  @BlockUI()
  blockUI: NgBlockUI;
  componentDestroy: () => Observable<boolean>;
  @Input()
  editMarketingChannel: MarketingChannel;
  @Input()
  all: MarketingChannel[];
  @Output()
  editFinished: EventEmitter<boolean> = new EventEmitter<boolean>();
  mForm: FormGroup;

  constructor(private mService: MarketingChannelsService, private toast: ToastService) {
  }

  ngOnInit(): void {
    if (this.editMarketingChannel?.id) {
      this.mForm = new FormGroup(
        {
          id: new FormControl(this.editMarketingChannel?.id),
          isDeleted: new FormControl(this.editMarketingChannel?.isDeleted),
          code: new FormControl(this.editMarketingChannel?.code, [Validators.required]),
          name: new FormControl(this.editMarketingChannel?.name, [Validators.required]),
          createdAt: new FormControl(this.editMarketingChannel?.createdAt),
        }
      );
    } else {
      this.mForm = new FormGroup(
        {
          id: new FormControl(0),
          isDeleted: new FormControl(false),
          code: new FormControl(null, [Validators.required, this.uniqCode.bind(this)]),
          name: new FormControl(null, [Validators.required, this.uniqName.bind(this)]),
          createdAt: new FormControl(null)
        }
      );
    }
  }

  cancel(): void {
    this.editFinished.emit(true);
  }

  save(): void {
    if (this.mForm.valid) {
      this.blockUI.start();
      const newMC: MarketingChannel = {
        id: this.mForm.value.id,
        name: this.mForm.value.name,
        code: this.mForm.value.code,
        createdAt: new Date().toISOString(),
        isDeleted: this.mForm.value.isDeleted,
      };
      this.mService.apiAdminMarketingChannelsPost(newMC)
        .pipe(
          finalize(() => {
            this.blockUI.stop();
            this.editFinished.emit(true);
          }),
          takeUntil(this.componentDestroy()))
        .subscribe(data => {
          this.blockUI.stop();
        });
    } else {
      this.toast.error('Please, set all fields');
    }
  }


  public uniqName(control: FormControl): Promise<any> | Observable<any> {
    if (!_.trim(control.value) ||
      !_.includes(_.chain(this.all).map((y) => y?.name).toUpper().split(',').value(), _.chain(control.value).trim().toUpper().value())) {
      return null;
    } else {
      return of({nameIsNotValid: true});
    }
  }

  public uniqCode(control: FormControl): Promise<any> | Observable<any> {
    if (!_.trim(control.value) ||
      !_.includes(_.chain(this.all).map((y) => y?.code).toUpper().split(',').value(), _.chain(control.value).trim().toUpper().value())) {
      return null;
    } else {
      return of({codeIsNotValid: true});
    }
  }
}
