<div class="px-3">
  <ul ngbNav #nav="ngbNav" class="nav-tabs">
    <li ngbNavItem>
      <a ngbNavLink>Logs</a>
      <ng-template ngbNavContent>
        <app-log></app-log>
      </ng-template>
    </li>
    <li ngbNavItem>
      <a ngbNavLink>TgLogs</a>
      <ng-template ngbNavContent>
        <app-tg-log></app-tg-log>
      </ng-template>
    </li>
    <li ngbNavItem>
      <a ngbNavLink>HttpLogs</a>
      <ng-template ngbNavContent>
        <app-http-log></app-http-log>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav"></div>
</div>


