<ng-container>
  <h1 class="text-center">Dashboard</h1>
  <br />
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-4">
        <h1 class="text-center">Active Users Financials</h1>
        <br />
        <select class="custom-select" (change)="onMonthYearChange($event)">
          <option *ngFor="let opt of monthYearOptions" [value]="opt" [selected]="(opt==currentMonthYear) == true">
            {{ (opt.length > 4) ? opt.slice(0,2) + '-' + opt.slice(2) : opt}}
          </option>
        </select>
        <table class="table table-hover">
          <thead>
            <tr>
              <th>Plan</th>
              <th>Status</th>
              <th>Count</th>
              <th>Predicted</th>
              <th>Actual</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let user of activeUsers;" (click)="onRowClick(user.plan, user.status, user.monthYear)">
              <td>{{user.plan}}</td>
              <td>{{user.status}}</td>
              <td>{{user.count}}</td>
              <td>{{user.predictedAmount}}</td>
              <td>{{user.actualAmount}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</ng-container>
