<ng-container *ngIf="!selected">
  <table class="table table-hover" sortable-table (sorted)="onSorted($event)" id="excel-table">
    <thead>
    <tr>
      <th colspan="4">
        <button class="btn btn-success" (click)="editItem()">Add New</button>
      </th>
      <th colspan="6">
        <h2>Features</h2>
      </th>
    </tr>
    <tr>
      <th scope="col" sortable-column="Id" class="pointer">Id</th>
      <th scope="col" sortable-column="Name" class="pointer">
        Name
      </th>
      <th scope="col" sortable-column="Description" class="pointer">
        Description
      </th>
      <th scope="col" sortable-column="Price" class="pointer">
        Price
      </th>
      <th scope="col" sortable-column="IsDeleted" class="pointer">
        IsDeleted
      </th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let item of currentFeatures?.result">
      <tr (click)="editItem(item)" [ngClass]="{'bg-danger':item.isDeleted}">
        <td class="width-60">{{item.id}}</td>
        <td>{{item.name}}</td>
        <td>{{item.description}}</td>
        <td>{{item.price}}</td>
        <td>{{item.isDeleted}}</td>
      </tr>
    </ng-container>
    </tbody>
  </table>
  <ng-container *ngIf="currentFeatures">
    <ngb-pagination [pageSize]="pageSize" [collectionSize]="currentFeatures.totalItems"
                    class="d-flex justify-content-center"
                    (pageChange)="pageChanged($event)" [rotate]="true" [(page)]="currentPage" [maxSize]="30"
                    [boundaryLinks]="true">
    </ngb-pagination>
  </ng-container>
</ng-container>
<ng-container *ngIf="selected">
  <app-feature-edit [editFeature]="selected" (editFinished)="editItem(null)"></app-feature-edit>
</ng-container>
