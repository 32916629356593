import {
  AfterContentChecked,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  QueryList,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ModalContentComponent} from './modal-content.component';
import {Observable} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {TakeUntilDestroy} from '../services/take-until-destroy.decorator';

@TakeUntilDestroy
@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalComponent implements AfterContentChecked, OnInit {
  @ViewChildren('div') divs: QueryList<any>;
  @Input() button: string;
  @Input() open$: Observable<void>;
  link = '';
  @Input()
  openEmit: EventEmitter<void>;
  componentDestroy: () => Observable<boolean>;

  constructor(private modalService: NgbModal, public elm: ElementRef) {
    this.link = this.elm.nativeElement.getAttribute('link') ? this.elm.nativeElement.getAttribute('link') : '';
  }

  ngAfterContentChecked(): void {
    this.link = this.elm.nativeElement.getAttribute('link') ? this.elm.nativeElement.getAttribute('link') : '';
  }

  open(): void {
    const modalRef = this.modalService.open(ModalContentComponent,
      {
        backdropClass: 'dark-backdrop',
        windowClass: 'marginTop12',
        size: 'lg'
      });
    modalRef.componentInstance.body = (this.divs.first as ElementRef).nativeElement.innerHTML;
  }

  ngOnInit(): void {
    if (this.openEmit) {
      this.openEmit.pipe(takeUntil(this.componentDestroy())).subscribe(() => {
        this.open();
      });
    }
  }
}
