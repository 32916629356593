import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-content',
  template: `
    <div class="modal-body">
      <div [innerHTML]="body | safe: 'html'"> </div>
      <div class="text-center pt-3">
        <span  (click)="activeModal.close('Close click')">Close</span>
      </div>
    </div>
  `,
  styleUrls: ['./modal-content.component.scss']
})
export class ModalContentComponent {
  @Input() body;
  constructor(public activeModal: NgbActiveModal) {
  }
}
