<section class="login">
  <div class="container">
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="offset-sm-2 col-sm-10 offset-lg-3 col-lg-9">
          <img src="/assets/login/main-logo.png" class="img-login" alt="equinedge"></div>
      </div>
      <div class="form-group row">
        <label for="inputEmail" class="col-sm-4 col-form-label">Email</label>
        <div class="col-lg-3 col-md-4 col-sm-6 show-validation">
          <input type="email" formControlName="email" class="form-control" id="inputEmail" placeholder="Email">
          <span></span>
        </div>
      </div>
      <div class="form-group row">
        <label for="inputPassword" class="col-xs-12 col-sm-4 col-form-label">Password</label>
        <div class="col-lg-3 col-md-4  col-sm-6 show-validation">
          <input type="password" formControlName="password" class="form-control" id="inputPassword"
                 placeholder="Password">
          <span></span>
        </div>
      </div>
      <div class="form-group row" *ngIf="authService.loginError">
        <div class="offset-sm-4  col-lg-3 col-md-4  col-sm-6">
          <p class="text-danger">{{authService.loginError}}</p>
        </div>
      </div>
      <div class="form-group row">
        <div class="text-center offset-sm-4  col-lg-3 col-md-4  col-sm-6">
          <button type="submit" [disabled]="loginForm.invalid" class="btn  btn-primary btn-login">LOG IN</button>
        </div>
      </div>
      <div class="form-group row">
        <div class="text-center offset-sm-4  col-lg-3  col-md-4   col-sm-6 mb-4">
          <span class="forgot-email" (click)="open(content)">Forgot your email?</span>
        </div>
      </div>
      <!--<div class="form-group row">-->
      <!--<div class="text-left offset-sm-4  col-lg-3 col-md-4  col-sm-6">-->
      <!--<span (click)="facebook()" class="btn btn-primary btn-facebook">Login with Facebook</span>-->
      <!--</div>-->
      <!--</div>-->
      <!--<div class="form-group row">-->
      <!--<div class="text-left offset-sm-4  col-lg-3  col-md-4  col-sm-6">-->
      <!--<span (click)="google()" class="btn btn-danger btn-google">Login with Google+</span>-->
      <!--</div>-->
      <!--</div>-->
      <!--<div class="form-group row">-->
      <!--<div class="text-left offset-sm-4  col-lg-3  col-md-4  col-sm-6">-->
      <!--<span (click)="twitter()" class="btn btn-info btn-twitter">Login with Twitter</span>-->
      <!--</div>-->
      <!--</div>-->
    </form>
  </div>
</section>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Enter Your Email</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <input type="email" [(ngModel)]="resetEmail" #reset="ngModel" class="form-control" placeholder="Email" email>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-success" (click)="modal.close(reset)" [disabled]="reset.invalid||!reset.dirty">
      Send Reset Password
    </button>
  </div>
</ng-template>
