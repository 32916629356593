import {Component} from '@angular/core';
import {PublicToteEvent, ToteEventsService, ToteTrackChoice} from '../swagger-codegen';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {TakeUntilDestroy} from '../services/take-until-destroy.decorator';
import {Observable} from 'rxjs';
import {finalize, takeUntil} from 'rxjs/operators';

@TakeUntilDestroy
@Component({
  selector: 'app-tote-tracks',
  templateUrl: './tote-tracks.component.html',
  styleUrls: ['./tote-tracks.component.scss']
})
export class ToteTracksComponent {
  @BlockUI()
  blockUI: NgBlockUI;
  public events: PublicToteEvent[];
  public tracks: ToteTrackChoice[];
  componentDestroy: () => Observable<boolean>;

  constructor(public toteEventsService: ToteEventsService) {
    this.refresh();
  }

  private refresh(): void {
    this.blockUI.start();
    this.toteEventsService
      .apiAdminToteEventsTodayGet()
      .pipe(
        finalize(() => {
          this.blockUI.stop();
        }),
        takeUntil(this.componentDestroy()))
      .subscribe(d => {
        this.events = d.toteEvents;
        this.tracks = d.equinEdgeTracks;
        this.updateMappedEvents();
      });
  }

  public updateMappedEvents(): void {
    for (const track of this.tracks) {
      track.mappedEvents = this.events
        .filter(e => e.equinEdgeTrackId === track.trackId)
        .map(e => e.eventCode);
    }
  }

  public save(): void {
    this.blockUI.start('saving');
    this.toteEventsService.apiAdminToteEventsMapAllPost(this.events)
      .pipe(
        finalize(() => {
          this.blockUI.stop();
        }),
        takeUntil(this.componentDestroy()))
      .subscribe(_ => this.refresh());
  }
}
