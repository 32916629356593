import {BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig} from '@angular/platform-browser';
import {ErrorHandler, Injectable, NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppComponent} from './app.component';
import {HomeComponent} from './home/home.component';
import {AppRoutingModule} from './app-routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BlockUIModule} from 'ng-block-ui';
import {WagerTypeTextsComponent} from './wager-type-texts/wager-type-texts.component';
import {ApiModule, Configuration} from './swagger-codegen';
import {environment} from 'src/environments/environment';
import {JtHttp} from './JtHttp';
import {LoginService} from './services/login.service';
import {ToastService} from './services/toast.service';
import * as Hammer from 'hammerjs';
import {LoginComponent} from './login/login.component';
import {HttpClientModule} from '@angular/common/http';
import {ServiceWorkerModule} from '@angular/service-worker';
import {NgbActiveModal, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {TracksComponent} from './tracks/tracks.component';
import {SortService} from './sortable-column/sort-service';
import {SortableTableDirective} from './sortable-column/sortable-table';
import {SortableColumnComponent} from './sortable-column/sortable-column.component';
import {TrackDiagramsComponent} from './track-diagrams/track-diagrams.component';
import {SafePipe} from './safe.pipe';
import {DataLeftService} from './services/data-left.service';
import {RollbarErrorHandler, rollbarFactory, RollbarService} from './services/rollbar.service';
import {ToteTracksComponent} from './tote-tracks/tote-tracks.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {UsersComponent} from './users/users.component';
import {FreeTrackComponent} from './tracks/free-track/free-track.component';
import {ActiveUsersComponent} from './reporting/users/active-users/active-users.component';
import {ActiveUsersByPlanComponent} from './reporting/users/active-users-by-plan/active-users-by-plan.component';
import {UserDetailsComponent} from './users/user-details/user-details.component';
import {UserSubscriptionComponent} from './users/user-subscription/user-subscription.component';
import {ToastrModule} from 'ngx-toastr';
import {WagerTypeTextMappingsComponent} from './wager-type-text-mappings/wager-type-text-mappings.component';
import {LogsComponent} from './logs/logs.component';
import {LogComponent} from './logs/log/log.component';
import {TgLogComponent} from './logs/tg-log/tg-log.component';
import {HttpLogComponent} from './logs/http-log/http-log.component';
import {ModalComponent} from './modal/modal.component';
import {ModalContentComponent} from './modal/modal-content.component';
import {OverflowTextComponent} from './overflow-text/overflow-text.component';
import {RaceWagerTypeComponent} from './race-wager-type/race-wager-type.component';
import {RaceWagerTypeEditComponent} from './race-wager-type/race-wager-type-edit/race-wager-type-edit.component';
import {AffiliateComponent} from './affiliate/affiliate.component';
import {AffiliateEditComponent} from './affiliate/affiliate-edit/affiliate-edit.component';
import {MeetComponent} from './meet/meet.component';
import {MeetEditComponent} from './meet/meet-edit/meet-edit.component';
import {PromoCodesComponent} from './promo-codes/promo-codes.component';
import {PromoCodeEditComponent} from './promo-codes/promo-code-edit/promo-code-edit.component';
import {MarketingChannelsComponent} from './marketing-channels/marketing-channels.component';
import {MarketingChannelEditComponent} from './marketing-channels/marketing-channel-edit/marketing-channel-edit.component';
import {PromoCodeRedemptionsComponent} from './promo-code-redemptions/promo-code-redemptions.component';
import {UserCurrentMonthlyComponent} from './user-current-monthly/user-current-monthly.component';
import {CourseDiagramsComponent} from './course-diagrams/course-diagrams.component';
import {CourseDiagramEditComponent} from './course-diagrams/course-diagram-edit/course-diagram-edit.component';
import {FeatureComponent} from './feature/feature.component';
import {FeatureEditComponent} from './feature/feature-edit/feature-edit.component';
import {PlanComponent} from './plan/plan.component';
import {PlanEditComponent} from './plan/plan-edit/plan-edit.component';
import { SettledTransactionsComponent } from './reporting/finance/settled-transactions/settled-transactions.component';

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = {
    // override hammerjs default configuration
    swipe: {direction: Hammer.DIRECTION_ALL}
  } as any;
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    WagerTypeTextsComponent,
    TracksComponent,
    SortableColumnComponent,
    SortableTableDirective,
    TrackDiagramsComponent,
    SafePipe,
    ToteTracksComponent,
    UsersComponent,
    FreeTrackComponent,
    ActiveUsersComponent,
    ActiveUsersByPlanComponent,
    UserDetailsComponent,
    UserSubscriptionComponent,
    WagerTypeTextMappingsComponent,
    LogsComponent,
    LogComponent,
    TgLogComponent,
    HttpLogComponent,
    ModalComponent,
    ModalContentComponent,
    OverflowTextComponent,
    RaceWagerTypeComponent,
    RaceWagerTypeEditComponent,
    AffiliateComponent,
    AffiliateEditComponent,
    MeetComponent,
    MeetEditComponent,
    PromoCodesComponent,
    PromoCodeEditComponent,
    MarketingChannelsComponent,
    MarketingChannelEditComponent,
    PromoCodeRedemptionsComponent,
    UserCurrentMonthlyComponent,
    CourseDiagramsComponent,
    CourseDiagramEditComponent,
    FeatureComponent,
    FeatureEditComponent,
    PlanComponent,
    PlanEditComponent,
    SettledTransactionsComponent
  ],
  imports: [
    ApiModule.forRoot(apiConfig),
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BlockUIModule.forRoot(),
    HttpClientModule,
    NgbModule,
    NgSelectModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.enable.enableServiceWorker
    }),
    ToastrModule.forRoot()
  ],
  providers: [
    SortService,
    LoginService,
    ToastService,
    DataLeftService,
    NgbActiveModal,
    JtHttp,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
    },
    {provide: ErrorHandler, useClass: RollbarErrorHandler},
    {provide: RollbarService, useFactory: rollbarFactory}
  ],

  bootstrap: [AppComponent]
})
export class AppModule {
}

export function apiConfig(): Configuration {
  return new Configuration({
    basePath: environment.ApiBaseUrl,
    apiKeys: {['Authorization']: 'Bearer ' + jwtTokenGetter()}
  });
}

export function jwtTokenGetter(): string {
  return localStorage.getItem('access_token');
}
