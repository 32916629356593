import Rollbar from 'rollbar';
import {Injectable, InjectionToken, ErrorHandler, Inject} from '@angular/core';
import {environment} from '../../environments/environment';

const rollbarConfig = {
  accessToken: 'f256067ac5b64ac2a12eef1b71786efd',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: (environment.production ? 'prod' : 'staging'),
    person: {
      email: localStorage.getItem('email'),

    },
    client: {
      javascript: {
        source_map_enabled: true, // this is will be true by default if you have enabled this in your settings
        // Optionally have Rollbar guess which frames the error was thrown from
        // when the browser does not provide line and column numbers.
        guess_uncaught_frames: true
      }
    }
  }
};
export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {
  }

  handleError(err: any): void {
    console.error(err.originalError || err);
    if (environment.enable.enableRollbar) {
      this.rollbar.error(err.originalError || err);
    }
  }
}

export function rollbarFactory(): Rollbar {
  return new Rollbar(rollbarConfig);
}


