import {HomeComponent} from './home/home.component';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {WagerTypeTextsComponent} from './wager-type-texts/wager-type-texts.component';
import {LoginComponent} from './login/login.component';
import {TracksComponent} from './tracks/tracks.component';
import {TrackDiagramsComponent} from './track-diagrams/track-diagrams.component';
import {ToteTracksComponent} from './tote-tracks/tote-tracks.component';
import {UsersComponent} from './users/users.component';
import {ActiveUsersComponent} from './reporting/users/active-users/active-users.component';
import {ActiveUsersByPlanComponent} from './reporting/users/active-users-by-plan/active-users-by-plan.component';
import {UserDetailsComponent} from './users/user-details/user-details.component';
import {WagerTypeTextMappingsComponent} from './wager-type-text-mappings/wager-type-text-mappings.component';
import {LogsComponent} from './logs/logs.component';
import {RaceWagerTypeComponent} from './race-wager-type/race-wager-type.component';
import {AffiliateComponent} from './affiliate/affiliate.component';
import {MeetComponent} from './meet/meet.component';
import {PromoCodesComponent} from './promo-codes/promo-codes.component';
import {MarketingChannelsComponent} from './marketing-channels/marketing-channels.component';
import {PromoCodeRedemptionsComponent} from './promo-code-redemptions/promo-code-redemptions.component';
import {UserCurrentMonthlyComponent} from './user-current-monthly/user-current-monthly.component';
import {CourseDiagramsComponent} from './course-diagrams/course-diagrams.component';
import {FeatureComponent} from './feature/feature.component';
import {PlanComponent} from './plan/plan.component';
import {SettledTransactionsComponent} from "./reporting/finance/settled-transactions/settled-transactions.component";

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'affiliate', component: AffiliateComponent},
  {path: 'meets', component: MeetComponent},
  {path: 'promo-codes', component: PromoCodesComponent},
  {path: 'login', component: LoginComponent},
  {path: 'logs', component: LogsComponent},
  {path: 'race-wager-type', component: RaceWagerTypeComponent},
  {path: 'wager-type-text-mappings', component: WagerTypeTextMappingsComponent},
  {path: 'wager-type-texts', component: WagerTypeTextsComponent},
  {path: 'tracks', component: TracksComponent},
  {path: 'track-diagrams', component: TrackDiagramsComponent},
  {path: 'tote-tracks', component: ToteTracksComponent},
  {path: 'tote-tracks/:id', component: ToteTracksComponent},
  {path: 'users', component: UsersComponent},
  {path: 'users/:id', component: UserDetailsComponent},
  {path: 'plan', component: PlanComponent},
  {path: 'features', component: FeatureComponent},
  {path: 'reporting/users/active-users', component: ActiveUsersComponent},
  {path: 'reporting/users/active-users-by-plan', component: ActiveUsersByPlanComponent},
  {path: 'reporting/finance/settled-transactions', component: SettledTransactionsComponent},
  {path: 'marketing/channels', component: MarketingChannelsComponent},
  {path: 'promo-code-redemption', component: PromoCodeRedemptionsComponent},
  {path: 'user-current-monthly', component: UserCurrentMonthlyComponent},
  {path: 'course-diagrams', component: CourseDiagramsComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
