<ng-container *ngIf="!selected">
  <table class="table table-hover" sortable-table (sorted)="onSorted($event)" id="excel-table">
    <thead>
    <tr>
      <th colspan="4">
        <button class="btn btn-success" (click)="editItem({})">Add New</button>
      </th>
    </tr>
    <tr>
      <th scope="col" sortable-column="Id" class="pointer">Id</th>
      <th scope="col" sortable-column="IsDeleted" class="pointer">
        isDeleted
      </th>
      <th scope="col" sortable-column="Code" class="pointer">
        code
      </th>
      <th scope="col" sortable-column="CompanyName" class="pointer">
        companyName
      </th>
      <th scope="col" sortable-column="Channel" class="pointer">
        channel
      </th>
      <th scope="col">subscriptions</th>
      <th scope="col">feeSchedules</th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let item of currentAffiliate?.result">
      <tr (click)="editItem(item)">
        <td class="width-60">{{item.id}}</td>
        <td>{{item.isDeleted}}</td>
        <td>{{item.code}}</td>
        <td>{{item.companyName}}</td>
        <td>{{item.channel}}</td>
        <td>
          <ng-container *ngFor="let s of item.subscriptions">
            <span class="badge bg-warning mr-2">{{s?.user?.userName}} Plan:{{s?.subscription?.plan?.code}}</span>
          </ng-container>
        </td>
        <td>
          <ng-container *ngFor="let w of item.feeSchedules">
            <span class="badge  bg-info mr-2">{{w.plan?.code}} Fee:{{w?.fee}}</span>
          </ng-container>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
  <ng-container *ngIf="currentAffiliate">
    <ngb-pagination [pageSize]="pageSize" [collectionSize]="currentAffiliate.totalItems"
                    class="d-flex justify-content-center"
                    (pageChange)="pageChanged($event)" [rotate]="true" [(page)]="currentPage" [maxSize]="6"
                    [boundaryLinks]="true">
    </ngb-pagination>
  </ng-container>
</ng-container>
<ng-container *ngIf="selected">
  <app-affiliate-edit [editAffiliate]="selected" (editFinished)="editItem(null)"></app-affiliate-edit>
</ng-container>
